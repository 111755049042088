import React from 'react';

import { SubtitleHeader } from './styles';

export default function Title(props) {
	function translatePlace(place) {
		switch (place) {
			case 'supermarket':
				return 'Supermercado';
			case 'drug_store':
				return 'Farmácia';
			case 'hotel':
				return 'Hotel';
			case 'bus_stop':
				return 'Parada de ônibus';
			case 'academy':
				return 'Academia';
			case 'school':
				return 'Escola';
			case 'nursery':
				return 'Creche';
			case 'hospital':
				return 'Hospital';
			case 'beach':
				return 'Praia';
			case 'airport':
				return 'Aeroporto';
			case 'sports_academy':
				return 'Academia esportiva';
			case 'Pub':
				return 'Bar';
			case 'Bazzar':
				return 'Bazar';
			case 'nightclub':
				return 'Casa nortura';
			case 'municipal_market':
				return 'Mercado Municipal';
			case 'bakeries':
				return 'Padaria';
			default:
				return place;
		}
	}

	return (
		<>
			<SubtitleHeader
				fontSize={props.fontSize}
				color={props.color}
				letter={props.letter}
				weight={props.weight}
				padding={props.padding}
				align={props.align}
			>
				{props.children}
				{translatePlace(props.txt)}
			</SubtitleHeader>
		</>
	);
}
