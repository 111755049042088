import styled from 'styled-components';

import device from '../../utils/devices';

export const Container = styled.div``;

export const Apartments = styled.div`
	@media ${device.mobileS} {
		display: grid;
		background: transparent;
		justify-content: center;
		align-items: center;
		grid-template-columns: repeat(1, 340px);
		grid-template-rows: repeat(3, 1fr);
		row-gap: 30px;
	}

	@media ${device.tablet} {
		display: grid;
		background: transparent;
		justify-content: center;
		align-items: center;
		grid-template-columns: repeat(1, 450px);
		grid-template-rows: repeat(3, 1frpx);
		column-gap: 30px;
		row-gap: 30px;
	}

	@media ${device.laptop} {
		display: grid;
		background: transparent;
		justify-content: center;
		align-items: center;
		grid-template-columns: repeat(3, 340px);
		grid-template-rows: repeat(1, 400px);
		column-gap: 35px;
	}

	@media ${device.laptopL} {
		display: grid;
		background: transparent;
		justify-content: center;
		align-items: center;
		grid-template-columns: repeat(3, 385px);
		grid-template-rows: repeat(1, 420px);
		column-gap: 30px;
	}
`;

export const Box = styled.div`
	position: absolute;
	background: #ddd5;
	margin-top: 200px;
	height: 300px;
	width: 100%;
`;
