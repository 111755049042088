import styled from 'styled-components';
import Devices from '../../../utils/devices';

export const Container = styled.div`
	@media ${Devices.mobileM} {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: auto;
		width: 100%;
	}

	@media ${Devices.laptop} {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: auto;
		width: 100%;
	}
`;

export const Line = styled.div`
	height: 1px;
	width: 146px;
	background-color: #ffffff;
	align-self: center;
`;

export const Description = styled.div`
	text-align: center;
	color: #ffffff;
	font-size: 1em;
	line-height: 1.5rem;
	letter-spacing: 0.1rem;
`;

export const MedicImage = styled.img`
	@media ${Devices.mobileS} {
		width: 100%;
		padding: 10px;
		height: auto;
	}

	@media ${Devices.tablet} {
		width: 100%;
		padding: 10px;
		height: auto;
	}

	@media ${Devices.laptop} {
		height: auto;
		width: 700px;
		z-index: 1;

		&:hover {
			opacity: 0.8;
		}
	}

	@media ${Devices.laptopL} {
		height: auto;
		width: 800px;
		z-index: 1;

		&:hover {
			opacity: 0.8;
		}
	}

	@media ${Devices.desktop} {
		height: auto;
		width: 1200px;
		z-index: 1;

		&:hover {
			opacity: 0.8;
		}
	}
`;

export const CoworkingImage = styled.img`
	@media ${Devices.mobileS} {
		width: 100%;
		height: auto;
		padding: 10px;
	}

	@media ${Devices.tablet} {
		width: 100%;
		height: auto;
		padding: 10px;
		text-align: center;
	}

	@media ${Devices.laptop} {
		position: relative;
		height: auto;
		width: 700px;
		left: 120px;
		top: -40px;

		&:hover {
			opacity: 0.8;
		}
	}

	@media ${Devices.laptopL} {
		position: relative;
		height: auto;
		width: 800px;
		left: 120px;
		top: -40px;

		&:hover {
			opacity: 0.8;
		}
	}

	@media ${Devices.desktop} {
		height: auto;
		width: 1000px;
		z-index: 1;

		&:hover {
			opacity: 0.8;
		}
	}
`;

export const CoworkingBox = styled.div`
padding: 4rem;
background-color: #7D0004;
position: relative;
z-index: 1;

 &:hover{
 background-color: #a74c4e;
 }
	a {
		text-decoration: none;
		color: white;
	}

	 @media ${Devices.mobileS} {
		margin: 0 auto;
		left: 0;
	 }

	 @media ${Devices.tablet} {
		margin: 0 auto;
		left: 0;
	 }

	 @media ${Devices.laptop} {
		margin: 0 0 0 auto;
		left: 16%;
	}

	//
	// @media ${Devices.laptopL} {
	// 	display: flex;
	// 	flex-direction: column;
	// 	justify-content: center;
	// 	align-content: center;
	// 	position: relative;
	// 	padding: 15px;
	// 	left: 100px;
	// 	width: 500px;
	// 	height: 350px;
	// 	z-index: 2;
	// 	background-color: #75282a;
	//
	// 	a {
	// 		text-decoration: none;
	// 		color: white;
	// 	}
	//
	// 	&:hover {
	// 		background-color: #a74c4e;
	// 		cursor: pointer;
	// 	}
	// }
`;

export const MedicBox = styled.div`
	a {
		text-decoration: none;
		color: white;
	}

	@media ${Devices.mobileS} {
		background-color: #bba57d;
		padding: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		width: 90%;
		height: auto;
	}

	@media ${Devices.tablet} {
		background-color: #bba57d;
		padding: 15px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		width: 600px;
		height: 400px;
	}

	@media ${Devices.laptop} {
		background-color: #bba57d;
		padding: 15px;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		left: 45px;
		width: 500px;
		height: 400px;
		z-index: 4;

		a {
			text-decoration: none;
			color: white;
		}

		&:hover {
			background-color: #a0895f;
			cursor: pointer;
		}
	}

	@media ${Devices.laptopL} {
		background-color: #bba57d;
		padding: 15px;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		left: 40px;
		width: 500px;
		height: 400px;
		z-index: 4;

		a {
			text-decoration: none;
			color: white;
		}

		&:hover {
			background-color: #a0895f;
			cursor: pointer;
		}
	}

	@media ${Devices.desktop} {
		background-color: #bba57d;
		padding: 15px;
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-content: center;
		left: 45px;
		width: 600px;
		height: 600px;
		z-index: 4;

		&:hover {
			background-color: #a0895f;
			cursor: pointer;
		}
	}
`;

export const Medic = styled.div`
	@media ${Devices.mobileM} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media ${Devices.laptop} {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
`;

export const Rooms = styled.div`
	@media ${Devices.mobileM} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media ${Devices.laptop} {
		display: flex;
		flex-direction: row;
		align-items: center;
	}
`;
