import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { Image, Row, Col } from "react-bootstrap";
import { IconContext } from "react-icons";
import {
	FaInstagram,
	FaWhatsapp,
	FaYoutube,
	FaFacebookF,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import ReactHtmlParser from "react-html-parser";

import {
	ContactHeader,
	ContactSubtitle,
	IconWrapperColor,
	StyledIcons,
} from "./styles";
import Svg from "../../../utils/svg";
import format from "../../../helpers/format";
import { getVariableById } from "../../../helpers/variable";
import FooterLogo from "../../../assets/images/footer-logo/logo_mmestre_gray.png";
import constants from "../../../utils/constants";

export default function Footer() {
	const [footerAttendingTitle, setFooterAttendingTitle] = useState("");
	const [footerAttendingNumber, setFooterAttendingNumber] = useState("");
	const [footerWhatsAppTitle, setFooterWhatsAppTitle] = useState("");
	const [footerWhatsAppNumber, setFooterWhatsAppNumber] = useState("");
	const [footerInstagramLink, setFooterInstagramLink] = useState("");
	const [footerYouTubeLink, setFooterYouTubeLink] = useState("");
	const [footerFacebookLink, setFooterFacebookLink] = useState("");

	const { variables } = useSelector((state) => state.variables);

	useEffect(() => {
		setFooterWhatsAppTitle(getVariableById(variables, 16));
		setFooterWhatsAppNumber(getVariableById(variables, 11));
		setFooterAttendingTitle(getVariableById(variables, 18));
		setFooterAttendingNumber(getVariableById(variables, 10));
		setFooterInstagramLink(getVariableById(variables, 14));
		setFooterYouTubeLink(getVariableById(variables, 12));
		setFooterFacebookLink(getVariableById(variables, 13));
	}, [variables]);

	return (
		<footer className="internal-footer">
			<section className="container-fluid">
				<Row className="py-5 p-lg-5 p-sm-5 p-md-5">
					<Col
						className="d-flex justify-content-center align-items-center pb-5 pb-lg-0 pb-sm-0 pb-md-5"
						lg={2}
						md={12}
						sm={12}
					>
						<Link to="/">
							<img src={FooterLogo} alt="M. Mestre Empreendimentos" />
						</Link>
					</Col>
					<Col lg={4} md={6} sm={12}>
						{/*Início: Bloco renderizado em telas desktop*/}
						<div className="justify-content-center align-items-center d-none d-sm-none d-md-none d-lg-none d-xl-flex">
							<div>
								<IconWrapperColor bg="#4FBF3E">
									<Image
										src={Svg.whatsapp}
										fluid
										style={{ verticalAlign: "middle", paddingTop: 10 }}
									/>
								</IconWrapperColor>
							</div>
							<div>
								<ContactHeader>
									{ReactHtmlParser(footerWhatsAppTitle)}
								</ContactHeader>
								<ContactSubtitle>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={constants.urls.whatsAppApiLink}
										className="adjust-footer-tel-link"
									>
										{ReactHtmlParser(format.phone(footerWhatsAppNumber))}
									</a>
								</ContactSubtitle>
							</div>
						</div>
						{/*Fim: Bloco renderizado em telas desktop*/}
						{/*Início: Bloco renderizado na responsividade*/}
						<div className="text-gray-m-mestre w-100 text-uppercase text-center pb-5 pb-sm-5 pb-md-5 pb-lg-0 d-block d-sm-block d-md-block d-lg-block d-xl-none">
							<p className="m-0">{ReactHtmlParser(footerWhatsAppTitle)}</p>
							<hr className="border-gray-m-mestre" />
							<p className="m-0">
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={`${constants.urls.whatsAppApiLink}`}
									className="adjust-footer-tel-link"
								>
									{ReactHtmlParser(format.phone(footerWhatsAppNumber))}
								</a>
							</p>
						</div>
						{/*Fim: Bloco renderizado na responsividade*/}
					</Col>
					<Col lg={3} md={6} sm={12}>
						{/*Início: Bloco renderizado em telas desktop*/}
						<div className="justify-content-center align-items-center d-none d-sm-none d-md-none d-lg-none d-xl-flex">
							<div>
								<IconWrapperColor bg="#D68400">
									<Image
										src={Svg.phoneWhite}
										fluid
										style={{ verticalAlign: "middle", paddingTop: 10 }}
									/>
								</IconWrapperColor>
							</div>
							<div>
								<ContactHeader>
									{ReactHtmlParser(footerAttendingTitle)}
								</ContactHeader>
								<ContactSubtitle>
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={`tel:${footerAttendingNumber}`}
										className="adjust-footer-tel-link"
									>
										{ReactHtmlParser(format.phone(footerAttendingNumber))}
									</a>
								</ContactSubtitle>
							</div>
						</div>
						{/*Fim: Bloco renderizado em telas desktop*/}
						{/*Início: Bloco renderizado na responsividade*/}
						<div className="text-gray-m-mestre w-100 text-uppercase text-center pb-5 pb-sm-5 pb-md-5 pb-lg-0 d-block d-sm-block d-md-block d-lg-block d-xl-none">
							<p className="m-0">{ReactHtmlParser(footerAttendingTitle)}</p>
							<hr className="border-gray-m-mestre" />
							<p className="m-0">
								<a
									target="_blank"
									rel="noopener noreferrer"
									href={`tel:${footerAttendingNumber}`}
									className="adjust-footer-tel-link"
								>
									{ReactHtmlParser(format.phone(footerAttendingNumber))}
								</a>
							</p>
						</div>
						{/*Fim: Bloco renderizado na responsividade*/}
					</Col>
					<Col
						className="d-flex justify-content-center align-items-center pb-5 pb-sm-0 pb-md-5 pb-lg-0"
						lg={3}
						md={12}
						sm={12}
					>
						<IconContext.Provider
							value={{
								color: "#AFAFAF",
								size: 30,
							}}
						>
							<StyledIcons className="w-100 d-flex align-items-center justify-content-around">
								<a
									href={footerInstagramLink}
									target="_blank"
									rel="noopener noreferrer"
									className="adjust-social-link color-footer-home"
								>
									<FaInstagram />
								</a>
								<a
									href={footerFacebookLink}
									target="_blank"
									rel="noopener noreferrer"
									className="adjust-social-link color-footer-home"
								>
									<FaFacebookF />
								</a>
								<a
									href={constants.urls.whatsAppApiLink}
									target="_blank"
									rel="noopener noreferrer"
									className="adjust-social-link color-footer-home"
								>
									<FaWhatsapp />
								</a>
								<a
									href={footerYouTubeLink}
									target="_blank"
									rel="noopener noreferrer"
									className="adjust-social-link color-footer-home"
								>
									<FaYoutube />
								</a>
							</StyledIcons>
						</IconContext.Provider>
					</Col>
				</Row>
			</section>
		</footer>
	);
}
