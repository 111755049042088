import styled from 'styled-components';

export const Wrapper = styled.div`
	background-color: #fff;
	position: relative;
	z-index: 1;
	width: 90%;
	height: auto;
	margin: 0 auto;
	bottom: 30px;
	border: 1px solid #000;
`;

export const UnorderedList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;

export const OptionList = styled.li`
	padding-bottom: 3px;
	padding-top: 3px;
`;
