import React from 'react';

import { TitleHeader } from './styles';

export default function Title(props) {
	return (
		<>
			<TitleHeader
				fontSize={props.fontSize}
				color={props.color}
				letter={props.letter}
				weight={props.weight}
				padding={props.padding}
				margin={props.margin}
				transform={props.transform}
				align={props.align}
				family={props.family}
			>
				{props.txt}
			</TitleHeader>
		</>
	);
}
