import styled from 'styled-components';
import Devices from '../../../utils/devices';

export const Container = styled.div`
	width: 100%;
	height: 6rem;
	background: #f8f8f8;
	padding: 0 3rem 0 3rem;
`;

export const SelectGroup = styled.select`
	border: 0;
	padding: 0.25rem;
	margin: 0.15rem;
	background: transparent;
	border-left: 1px solid #e1e1e1;
	color: #585858;

	@media (min-width: 1024px) and (max-width: 1440px) {
		min-width: ${props => props.width || '120px'};
		font-size: 0.8em;
	}
`;

export const SelectOption = styled.option`
	color: #585858;
	font-weight: bold;
	position: relative;
	font-size: 0.8em;
`;
