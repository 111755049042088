import { SET_VARIABLES } from '../actions/variables';

const INITIAL_STATE = {
	variables: [
		{
			id: 14,
			title: 'Link para o Instagram',
			slug: 'link-para-o-instagram',
			value: 'https://www.instagram.com',
			created_at: '2020-03-16 11:51:42',
			updated_at: '2020-03-16 15:04:12',
			deleted_at: null,
		},
		{
			id: 13,
			title: 'Link para o Facebook',
			slug: 'link-para-o-facebook',
			value: 'https://www.facebook.com',
			created_at: '2020-03-16 11:50:26',
			updated_at: '2020-03-16 15:04:20',
			deleted_at: null,
		},
		{
			id: 12,
			title: 'Link para o YouTube',
			slug: 'link-para-o-youtube',
			value: 'https://www.youtube.com',
			created_at: '2020-03-16 11:50:09',
			updated_at: '2020-03-16 15:04:41',
			deleted_at: null,
		},
		{
			id: 17,
			title: 'Título referente ao "Consultor online"',
			slug: 'titulo-referente-ao-consultor-online',
			value: 'Consultor online',
			created_at: '2020-03-16 15:34:55',
			updated_at: '2020-03-16 15:34:55',
			deleted_at: null,
		},
		{
			id: 16,
			title: 'Título referente ao "WhatsApp"',
			slug: 'titulo-referente-ao-whatsapp',
			value: 'WhatsApp',
			created_at: '2020-03-16 15:34:20',
			updated_at: '2020-03-16 15:35:04',
			deleted_at: null,
		},
		{
			id: 7,
			title: 'Subtítulo referente ao "Atendimento"',
			slug: 'subtitulo-referente-ao-atendimento',
			value: 'Segunda a Sexta - 8h às 18h<br>Sábado - 9h às 17h',
			created_at: '2020-03-06 14:02:52',
			updated_at: '2020-03-16 15:37:53',
			deleted_at: null,
		},
		{
			id: 11,
			title: 'Telefone para o WhatsApp',
			slug: 'telefone-para-o-whatsapp',
			value: '+5581999999999',
			created_at: '2020-03-16 11:48:22',
			updated_at: '2020-03-26 16:49:33',
			deleted_at: null,
		},
		{
			id: 10,
			title: 'Telefone para o atendimento',
			slug: 'telefone-para-o-atendimento',
			value: '+558140310500',
			created_at: '2020-03-16 11:18:51',
			updated_at: '2020-03-26 17:31:05',
			deleted_at: null,
		},
		{
			id: 23,
			title:
				'Texto para início do contato via WhatsApp através dos dados de algum imóvel',
			slug:
				'texto-para-inicio-do-contato-via-whatsapp-atraves-dos-dados-de-algum-imovel',
			value:
				'Olá equipe M. Mestre Empreendimentos, estou interessado no imóvel',
			created_at: '2020-03-30 09:03:00',
			updated_at: '2020-03-30 09:03:00',
			deleted_at: null,
		},
		{
			id: 21,
			title: 'E-mail que receberá os formulários de contato do site',
			slug: 'e-mail-que-recebera-os-formularios-de-contato-do-site',
			value: 'contato@mmestreempreendimentos.com.br',
			created_at: '2020-03-16 17:21:51',
			updated_at: '2020-04-02 14:38:14',
			deleted_at: null,
		},
		{
			id: 19,
			title: 'Subtítulo referente ao "WhatsApp"',
			slug: 'subtitulo-referente-ao-whatsapp',
			value: 'Prontos para te atender<br>mais rápido',
			created_at: '2020-03-16 15:35:58',
			updated_at: '2020-04-02 16:55:58',
			deleted_at: null,
		},
		{
			id: 20,
			title: 'Subtítulo referente ao "Consultor online"',
			slug: 'subtitulo-referente-ao-consultor-online',
			value: 'Prontos para te atender<br>mais rápido',
			created_at: '2020-03-16 15:36:20',
			updated_at: '2020-04-02 17:04:36',
			deleted_at: null,
		},
		{
			id: 18,
			title: 'Título referente ao "Atendimento"',
			slug: 'titulo-referente-ao-atendimento',
			value: 'Atendimento',
			created_at: '2020-03-16 15:35:22',
			updated_at: '2020-04-02 17:08:00',
			deleted_at: null,
		},
	],
};

export default function variables(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_VARIABLES:
			return {
				...state,
				variables: action.payload,
			};
		default:
			return state;
	}
}
