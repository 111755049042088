export const SET_HOUSES = 'SET_HOUSES';
export const SET_APARTMENTS = 'SET_APARTMENTS';
export const SET_FLATS = 'SET_FLATS';
export const SET_STORES = 'SET_STORES';
export const SET_HANGARS = 'SET_HANGARS';
export const SET_IMMOBILES_RESEARCH = 'SET_IMMOBILES_RESEARCH';
export const SET_IMMOBILE_SHOW_DETAILS = 'SET_IMMOBILE_SHOW_DETAILS';

export const setHouse = houses => ({
	type: SET_HOUSES,
	payload: { houses },
});

export const setApartments = apartments => ({
	type: SET_APARTMENTS,
	payload: { apartments },
});

export const setFlats = flats => ({
	type: SET_FLATS,
	payload: { flats },
});

export const setStores = stores => ({
	type: SET_STORES,
	payload: { stores },
});

export const setHangars = hangars => ({
	type: SET_HANGARS,
	payload: { hangars },
});

export const setImmobilesResearch = immobilesResearch => ({
	type: SET_IMMOBILES_RESEARCH,
	payload: immobilesResearch,
});

export const setImmobileShowDetails = immobileShowDetails => ({
	type: SET_IMMOBILE_SHOW_DETAILS,
	payload: immobileShowDetails,
});
