import { createGlobalStyle } from "styled-components";
import Devices from "../utils/devices";
import Fonts from "../utils/fonts";

export default createGlobalStyle`
    @import url('../assets/fonts/montserrat/fonts/webfonts/Montserrat.css');
		@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');
		@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap');
		@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
		@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;600;700&display=swap');
		@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@300;400;500;600;700&display=swap');

    * {
        font-family: ${Fonts}, sans-serif !important;
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }
    body {
        font-family: ${Fonts}, sans-serif !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: #ffffff;
        width: 100%;
        overflow-x: hidden;
    }
    button {
        cursor: pointer;
        outline: none;
        border: 0;
    }

		.border-wd2 {
			border-width: 2px !important;
		}

		.border-transparent {
			border-width: 0px !important;
			border-color: transparent !important;
			border: 0px solid transparent !important;
		}

		.new-border-color {
			border-top: 2px solid #CBCBCB !important;
			border-bottom: 2px solid #CBCBCB !important;
		}

		.arial-font-size {
			font-family: Arial, Helvetica, sans-serif !important;
		}

		.font-size-element {
			font-size: min(14px, 10vw) !important;
		}

		.font_size_sample_calc {
			font-size: min(24px, 5vw);
		}

		.middle-size-pad-0 {
			vertical-align: middle !important;
			padding: 0px !important;
		}

		.custom_border-secondary {
			border-color: #CBCBCB !important;
		}


	.flex-icons-basis {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.comodities-flex {
		display: flex;
		align-items: center;
	}

	.flex-centering-text {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		text-align: center;
	}
		.wrapper {
			display: grid;
			grid-template-columns: repeat(6, 1fr);
			gap: 16px;
			grid-auto-flow: dense;
		}
		.wrapper-three-columns{
			gap: 2px;
		}

		.wrapper-top-4-image-size {
			@media (max-width: 768px) {
				width: 150px;
			}

			@media (min-width: 769px) and (max-width: 1023px) {
				width: 200px;
			}

		}

		.wrapper-top-4__four {
			display: grid;
			grid-template-columns: repeat(4, 250px);
			grid-template-rows: repeat(1, auto);
			gap: 50px;
			grid-auto-flow: dense;
			text-align: center;

			@media (max-width: 768px) {
				gap:0;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, auto);
			}

			@media (min-width: 769px) and (max-width: 1023px) {
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, auto);
			}
		}

		.wrapper-top-4__three {
			display: grid;
			grid-template-columns: repeat(3, 250px);
			grid-template-rows: repeat(3, auto);
			gap: 50px;
			grid-auto-flow: dense;
			text-align: center;

			@media (max-width: 768px) {
				gap:0;
				grid-template-columns: repeat(3, 1fr);
				grid-template-rows: repeat(3, auto);
			}

			@media (min-width: 769px) and (max-width: 1023px) {
				grid-template-columns: repeat(3, 1fr);
				grid-template-rows: repeat(3 auto);
			}
		}

		.wrapper-top-4__two {
			display: grid;
			grid-template-columns: repeat(2, 250px);
			grid-template-rows: repeat(2, auto);
			gap: 50px;
			grid-auto-flow: dense;
			text-align: center;

			@media (max-width: 768px) {
				gap:0;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, auto);
			}

			@media (min-width: 769px) and (max-width: 1023px) {
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2 auto);
			}
		}

		.wrapper-top-4__one {
			display: grid;
			grid-template-columns: repeat(1, 250px);
			grid-template-rows: repeat(1, auto);
			gap: 50px;
			grid-auto-flow: dense;
			text-align: center;

			@media (max-width: 768px) {
				gap:0;
				grid-template-columns: repeat(1, 1fr);
				grid-template-rows: repeat(1, auto);
			}

			@media (min-width: 769px) and (max-width: 1023px) {
				grid-template-columns: repeat(1, 1fr);
				grid-template-rows: repeat(1, auto);
			}
		}

		.width-cost_comparison {
			width: 350px;

			@media (max-width: 768px) {
				width: 100%;
			}

			@media (min-width: 769px) and (max-width: 1023px) {
				width: 100%;
			}
		}

		.top-4__bg_container {
			background-color: #CCB28B;
		}

		.gallery-item {
			width: 100%;
			height: 100%;
			position: relative;
		}

		.gallery-item .image {
			width: 100%;
			height: 100%;
			overflow: hidden;
		}

		.gallery-item .image img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: 50% 50%;
			cursor: pointer;
			transition: 0.5s ease-in-out;
		}

		.gallery-item:hover .image img {
			transform: scale(1.5);
		}

		.w-1 {
			grid-column: span 1;
		}

		.w-2 {
			grid-column: span 2;
		}

		.w-3 {
			grid-column: span 3;
		}

		.w-4 {
			grid-column: span 4;
		}

		.w-5 {
			grid-column: span 5;
		}

		.w-6 {
			grid-column: span 6;
		}

		.h-1 {
			grid-column: span 1;
		}

		.h-2 {
			grid-column: span 2;
		}

		.h-3 {
			grid-column: span 3;
		}

		.h-4 {
			grid-column: span 4;
		}

		.h-5 {
			grid-column: span 5;
		}

		.h-6 {
			grid-column: span 6;
		}

		@media screen and (max-width: 500px) {
			.wrapper {
				grid-template-columns: repeat(3, 1fr);
			}
			.w-1,
			 .w-2,
			  .w-3,
				 .w-4,
				  .w-5,
					 .w-6 {
				grid-column: span 1;
			}
		}

		@keyframes move-down {
			0% {
				top: 10%;
			}
			50% {
				top: 35%;
			}
			100% {
				top: 50%;
			}
		}

    .adjust-institutional-title {
    padding-top: 4rem;
    padding-bottom: 4rem;
    }
    .adjust-institutional-images {
    margin-bottom: 7rem;
    }
    .adjust-institutional-metrics {
    padding-top: 7rem;
    padding-bottom: 7rem;
    }
    .adjust-social-link {
    margin-left: 0 !important;
    }
    .adjust-footer-tel-link {
    color: #afafaf;
    }
    .adjust-footer-tel-link:hover {
    color: #afafaf;
    text-decoration: none;
    }
    .adjust-search-bar-button button {
				color: white;
				height: 6rem;
				width: 12rem;
				background-color: #b88e4b;
				transition: 0.3s;
				font-size: 1.25rem;
    }
		@media (max-width: 768px) {
			.adjust-search-bar-button button {
				width: 100%;
				height: 6rem;
			}
		}
		@media (max-width: 540px) {
			.adjust-search-bar-button button {
				width: 100%;
				height: 3rem;
			}
		}

    .adjust-search-bar-button button:hover {
    color: white;
    font-weight: normal;
    background-color: #e8b25a;
    }
    .adjust-search-bar-input input {
    height: 6rem;
		font-size: 1.25rem;
    }
    .adjust-search-bar-input input::placeholder {
    text-transform: none;
    }
    .adjust-search-bar-last-item {
    border-right: 1px solid #E1E1E1;
    }
    .pagination .page-item.disabled .page-link{
    border-color: transparent;
    }
    .pagination {
    margin-bottom: 0;
    }
    .adjust-paginate-section {
    margin-bottom: 3rem;
    }
    .adjust-paginate-section p{
    font-size: 1.375rem;
    }
    .adjust-building-card{
    min-height: 250px;
    padding: 1rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    }
    .adjust-building-card .adjust-building-reference {
    font-style: italic;
    font-size: 0.75rem;
    }
    .adjust-building-card .adjust-building-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    }
	.adjust-building-card .adjust-building-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
    }
    .adjust-building-card > div {
    color: white;
    }
    .adjust-building-card > div:hover {
    color: white;
    }
    .adjust-building-address{
			background-color: #e1e1e1;
			padding: 0.75rem 1rem;
			color: #727272;
			line-height: 1.4rem;
			flex: 1;
			width: 10%;
    }
    .adjust-building-address p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
    .adjust-building-whatsapp{
    background-color: #7ad06d;
    }
    .adjust-building-whatsapp a{
  	color: white !important;
    font-size: 2rem;
    }
    .adjust-building-attending a {
    color: white !important;
    font-size: 2rem;
    }
    .adjust-building-whatsapp:hover{
    background-color: #5bad4e;
    }
	.adjust-building-instagram{
		background: #f09433;
		background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
		background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
		background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    }
    .adjust-building-instagram a{
  	color: white !important;
    font-size: 2rem;
    }
	.adjust-building-instagram:hover{
    background-color: #5bad4e;
    }
    .adjust-building-attending {
    background-color: #b88e4b;

    }
    .adjust-building-attending:hover{
    background-color: #d09f51;
    }
    .adjust-building-list-container {
    margin: 7rem 0;
    }
    .adjust-building-contact-tag-a {
    padding: 0.75rem 1rem;
    }
    .adjust-consultant-section {
    background: linear-gradient(180deg, #7D0004 60%, #BBA57D 60%);
    height: 25rem;
    }
    .adjust-consultant-section .adjust-subtitle-consultant-section {
    font-size: 18px;
    letter-spacing: 1.8px;
    }
    .adjust-consultant-section .adjust-icons {
			width: auto;

			@media ${Devices.mobileS} {
				height: 57px;
			}

			@media ${Devices.tablet} {
				height: 57px;
			}

			@media ${Devices.laptop} {
				height: 40px;
			}

			@media ${Devices.laptopM} {
				height: 40px;
			}

			@media ${Devices.laptopL} {
				height: 57px;
			}
    }

		.contact-icons {
			width: auto;

			@media ${Devices.mobileS} {
				height: 15px;
			}

			@media ${Devices.tablet} {
				height: 30px;
			}

			@media ${Devices.laptop} {
				height: 40px;
			}

			@media ${Devices.laptopM} {
				height: 40px;
			}

			@media ${Devices.laptopL} {
				height: 57px;
			}
		}

    .adjust-buildings-section {
    background: linear-gradient(180deg, #FFF 50%, #F6F6F6 50%);
    }
    .adjust-see-all-button{
			color: #BBA57D;
			border-color: #BBA57D;
    }
    .adjust-see-all-button:hover{
    border-color: #BBA57D;
    background-color: #BBA57D;
    color: #fff !important;
    }
     .adjust-see-all-button:active{
    border-color: #BBA57D !important;
    background-color: #BBA57D !important;
    color: #fff;
    }
    .adjust-title-block h1{
    font-size: 1.5rem;
    }
    .adjust-link-in-block-section a{
    text-decoration: none;
    }
    .adjust-link-in-block-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    }

    .adjust-force-to-left-block-section{
			background-color: #BBA57D;

			@media ${Devices.laptop} {
				left: -16%;
				margin: 0 auto 0 0;
			}

			@media ${Devices.laptopM} {
				left: -16%;
				margin: 0 auto 0 0;
			}

			@media ${Devices.laptopL} {
				left: -16%;
				margin: 0 auto 0 0;
			}

    }
    .adjust-force-to-left-block-section:hover{
   background-color: #a0895f;
    }
    .contact-form {
    background-size: cover;
    background-position: center center;
    height: 65rem !important;
    margin-bottom: 30rem !important;
    }
    .adjust-button-contact-submit {
    border-radius: inherit !important;
    background-color: #B88E4B !important;
    border-color: #B88E4B !important;
    }
    .adjust-button-contact-submit:active {
    background-color: #BBA57D !important;
    border-color: #BBA57D !important;
    }
    .adjust-button-contact-submit:hover {
    background-color: #BBA57D !important;
    border-color: #BBA57D !important;
    }
    .contact-form .adjust-woman-image {
			position: relative;
			left: 2.5rem;
			bottom: 4rem;
    }
    .contact-form {
    position: relative;
    top: 10rem;
    }

    footer .adjust-first-piece-footer{
    background-color: #7D0004;

		@media ${Devices.mobileS} and (max-width: 767px){
				margin-top: 275px;
			}
    }
    footer .adjust-first-piece-footer a{
    text-decoration: none;
    }
    footer .adjust-first-piece-footer input{
    border: 1px solid #fff;
    }
    footer .adjust-first-piece-footer input::placeholder{
    color: #fff;
    }
    footer .adjust-first-piece-footer .adjust-newsletter-button {
    border-radius: inherit;
    background-color: #B88E4B;
    border-color: #B88E4B;
    }
    footer .adjust-first-piece-footer .adjust-newsletter-button:active {
    background-color: #BBA57D !important;
    border-color: #BBA57D !important;
    }
    footer .adjust-first-piece-footer .adjust-newsletter-button:hover {
    background-color: #BBA57D;
    border-color: #BBA57D;
    }
    footer .adjust-second-piece-footer{
    background-color: #610306;
    }
    footer .adjust-second-piece-footer .adjust-copyright {
    color: #893E40;
    letter-spacing: 2.7px;
    }
    footer .adjust-second-piece-footer .adjust-copyright a{
    color: #893E40;
    }
    footer .adjust-second-piece-footer .adjust-copyright a:hover{
    text-decoration: none;
    }
    footer .adjust-second-piece-footer .adjust-social-link.color-footer-home svg {
    color: #893E40 !important;
    }
    #advanced-search-bar {
    background-color: #f8f8f8;
    }
    .adjust-banner-construction-detail {
    display: grid;
		grid-template-columns: auto auto auto auto auto auto;
		grid-gap: 0.5rem;
		grid-auto-flow: dense;
  	background-color: #fff;
    }
    .adjust-banner-construction-detail div img {
    display: block;
   	object-fit: cover;
   	width: 100%;
   	height: 100%;
    }
    .adjust-banner-construction-detail div iframe {
    display: block;
   	object-fit: cover;
   	width: 100%;
   	height: 100%;
    }
    .adjust-banner-construction-detail div.panorama {
    grid-column-end: span 3;
    grid-row-end: span 2;
    }
    #construction_company{
    right: 0;
    top: -3rem;
    }
    #construction_company img{
    border-radius: 50%;
    margin-bottom: 1rem;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    }
    #details{
    background: #FCFCFC;
    }
    #details #details-inner{
    background: #FFF;
    color: #585858;
    }
    #details #details-inner.with-images{
        transform: translateY(-1.5rem);
    }
    #details #details-inner .construction-title {
    color: #585858;
		display: flex;
		align-items: center;
    }
    #details #details-inner .construction-address {
    color: #BBA57D;
		display: flex;
		align-items: center;
    }
    #details #details-inner .construction-address .adjust-reference {
    font-size: 1.25rem;
        border: 1px solid #A5A5A5;
        background: transparent;
        color: #A5A5A5;
    }
		#details #details-inner .construction-title .adjust-disponibility {
    font-size: 1.25rem;
        border: 1px solid #585858;
        background: transparent;
        color: #585858;
    }
    #details #details-inner .construction-data-section {
    color: #585858;
    padding-top: 6rem;
    }
    #details #details-inner .construction-price {
    color: #585858;
    }
    #details #details-inner .construction-data-primary {
    color: #818181;
    padding-bottom: 4rem;
    }
    #details #details-inner .construction-data-description{
    color: #585858;
    font-size: 1rem;
    }
    #details #details-inner .action-buttons .whatsapp-action .bg-whatsapp-button-icon{
    background-color: #4B9E3E;
    }
    #details #details-inner .action-buttons .whatsapp-action .bg-whatsapp-button-text{
    background-color: #69BC5C;
    color: white;
    letter-spacing: 0.16px;
    }
    #details #details-inner .feature-block{
    background-color: #BBA57D;
    max-width: 474px;
    min-width: 474px;
    }
    #details #details-inner .block-title{
    font-size: 1.75rem;
    }
    #details #details-inner .feature-row{
    transform: translateY(-7.5rem);
    }
    #details #details-inner .form-block{
    background-color: #7D0004;
    max-width: 474px;
    min-width: 474px;
    }
    .adjust-header{
    transition: top 0.5s ease, background-color 0.5s ease;
    z-index: 2;
    }
    .adjust-header ul {
    list-style-type: none;
    }
    .adjust-header ul li {
    font-size: 1.25rem;
    font-weight: bold;
    letter-spacing: 4px;
    }
    .adjust-header ul li a{
    color: white;
    }
    .adjust-header ul.internal-page li a{
    color: #A5A5A5;
    }
    .adjust-header ul li a.active{
    color: #A8050A;
    }
    .adjust-header ul li a:hover{
    color: #A8050A;
    text-decoration: none;
    }
    .adjust-header ul li a.login-button{
    background-color: #A8050A;
    border-radius: 50%;
    }
.btn-primary.focus, .btn-primary:focus {
  box-shadow: none !important;
}
.adjust-button-contact-submit.normalize-border-radius {
border-radius: .25rem !important;
}
.adjust-whatsapp-button-in-property-details{
border-radius: inherit !important;
background: linear-gradient(90deg, #4B9E3E 25%, #69BC5C 25%);
border-image: linear-gradient(90deg, #4B9E3E 25%, #69BC5C 25%);
}
.adjust-whatsapp-button-in-property-details:hover{
	background: linear-gradient(90deg, #69BC5C 25%, #4B9E3E 25%);
  border-image: linear-gradient(90deg, #69BC5C 25%, #4B9E3E 25%);
}

.adjust-select-type-construction{

	@media (max-width: 768px) {
		min-width: 100px;
	}

	@media (min-width: 769px) {
		min-width: 240px;
	}
}

.w-100-mobile-list-screens {
	@media (max-width: 768px) {
		width: 100%;
	}
}

.w-100-mobile-specific {
	@media (min-width: 769px) {
		width: 100%;
	}
}

.adjust-select-type-residencials{

	@media (max-width: 768px) {
		min-width: 100px;
	}

	@media (min-width: 769px) {
		min-width: 110px;
	}
}

.adjust-select-city{

	@media (max-width: 768px) {
	}

	@media (min-width: 769px) {
		min-width: 271px;
	}
}
.internal-footer{
background-color: #ECECEC;
}
.text-gray-m-mestre{
color: #afafaf;
}
.border-gray-m-mestre{
border-color: #afafaf;
}
#menu-mobile{
	position: fixed;
  z-index: 10000000000000000000;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
}
#menu-mobile .close{
position: absolute;
top: 1.25rem;
right: 1.25rem;
}
#menu-mobile ul{
list-style: none;
}
#menu-mobile ul li a{
font-size: 1.25rem;
font-weight: bold;
letter-spacing: 4px;
color: #A5A5A5;
}
#menu-mobile ul li a.active{
color: #A8050A;
}
#menu-mobile ul li a:hover{
color: #A5A5A5;
text-decoration: none;
}
#home-search {
	background-size: cover;
	background-position: center;
}
#home-search .title {
	color: #BE1116;
	font-size: 2.6rem;
	font-weight: 600;
}
#home-search .btn-business-event {
color: #A8050A;
border: 1px solid #A8050A;
border-radius: inherit;
}
#home-search .btn-business-event.active {
color: #FFF;
background-color: #A8050A !important;
}
#home-search .btn-search-business-event {
background-color: #B88E4B;
color: #fff;
border: 1px solid #B88E4B;
border-radius: inherit;
}
#home-search .select-business-event {
color: #A8050A;
border: 1px solid #A8050A;
border-radius: inherit;
/* background-image:
    linear-gradient(45deg, transparent 50%, #A8050A 50%),
    linear-gradient(135deg, #A8050A 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat; */
}

#home-search .input-business-event {
color: #A8050A;
border: 1px solid #A8050A;
border-radius: inherit;
}

#home-search .input-business-event::placeholder {
  color: #A8050A;
  opacity: 1;

  @media ${Devices.mobileL} {
    font-size: 12px;
	}

	@media ${Devices.tablet} {
    font-size: 16px;
	}

	@media ${Devices.laptopM} {
    font-size: 18px;
	}

	@media ${Devices.laptopL} {
    font-size: 18px;
	}
}

.container__background_pad_top-4 {
	background-color: #CCB28B;
}

.contact-form .input-contact-form {
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: inherit;
    background-color: #7D0004;
}
.contact-form .textarea-contact-form {
    color: #FFFFFF;
    padding: .5rem 1rem;
    border: 1px solid #FFFFFF;
    border-radius: inherit;
    background-color: #7D0004;
    resize: none;
}
.contact-form .textarea-contact-form::placeholder {
color: #FFFFFF;
opacity: 1;
}
.contact-form .input-contact-form::placeholder {
color: #FFFFFF;
opacity: 1;
}
.contact-form .checkbox-contact-form{
-ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
}
.contact-form .adjust-form-group {
padding-left: 2.75rem;
}
.contact-form .checkbox-contact-form .custom-control-label::before{
border-radius: inherit;
border-color: #ffffff;
background-color: #7D0004;
}
.contact-form .custom-control-input:checked~.custom-control-label::before{
border-color: #ffffff;
background-color: #7D0004;
}
.input-newsletter-form{
	color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  background-color: #7D0004;
}
.input-newsletter-form:focus{
	color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  background-color: #7D0004;
}
.input-contact-building-form{
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  background-color: #7D0004;
}

.input-error {
	border: 4px solid red;
}

.input-success {
	border: 4px solid green;
}

.input-contact-building-form-white{
  color: #000;
  border-radius: inherit;
  background-color: #fff;
}
.input-contact-building-form-white::placeholder{
	color: #495057;
	font-weight: 400;
}
.input-contact-building-form:focus{
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  border-radius: inherit;
  background-color: #7D0004;
}
.input-contact-building-form::placeholder{
	color: #FFFFFF;
	opacity: 1;
}

.contact-form  .adjust-form {
	width: 100%;

	@media ${Devices.mobileS} and (max-width: 767px) {
    padding-left: 15px;
		padding-right: 15px;
		margin-top: 50px;
	}

	@media ${Devices.tablet} and (max-width: 1023px) {
    padding-left: 60px;
		padding-right: 60px;
	}

	@media ${Devices.laptop} and (max-width: 1919px) {
    padding: 80px;
	}

	@media ${Devices.desktop} {
		padding: 100px;
}
}

.section_about__image {
		@media ${Devices.tablet} and (max-width: 1023px) {
			height: 500px
	}
}

.contact-form .position-specific {
	margin: 0 auto;

	@media ${Devices.desktop} {
		right: 150px;
		top: 195px;
	}
}

.contact-form .image-container-position {
			@media ${Devices.laptop} {
				left: 100px;
				bottom: 102px;
			}

			@media ${Devices.desktop} {
				left: -100px;
				bottom: 100px;
			}
		}

.contact-form 	.image-container-realtor {
	@media ${Devices.laptop} {
				left: 100px;
				bottom: 0px;
			}

			@media ${Devices.desktop} {
				left: -100px;
				bottom: 0px;
			}
}


@media (max-width: 575px) {
#details-inner{
padding-left: 0 !important;
padding-right: 0 !important;
}
.adjust-banner-construction-detail {
grid-template-columns: auto auto auto !important;
}
.contact-form {
margin-bottom: 3rem !important;
}
.contact-form  {
position: inherit;
top: inherit;
}

.adjust-link-in-block-section {
background-color: #7D0004;
}
.adjust-container-contact {
padding: 0;
}
.adjust-link-in-block-section.block-gold {
background-color: #BBA57D;
}
.adjust-link-in-block-section .adjust-coworking-box-for-responsive{
width: 100% !important;
position:inherit;
left: inherit;
}
.adjust-consultant-section {
height: auto;
background: #7D0004;
}
#home-search .adjust-row-business-event {
padding-left: 15px;
padding-right: 15px;
}
	#institutional-content {
		border: none;
		position: inherit;
	}
	#details #details-inner.with-images{
		transform: inherit;
	}
	#details #details-inner .construction-data-section{
		padding-top: inherit;
	}
	#details #details-inner .construction-data-description{
		width: 100% !important;
	}
	.adjust-google-map-in-property-details {
		height: 400px;
	}
	.adjust-google-map-in-property-details > div{
		position: inherit;
	}
	#details #details-inner .feature-row{
	transform: inherit;
	}
	#details #details-inner .feature-block {
	max-width: 100%;
	min-width: 100%;
	width: 100%;
	}
	#details #details-inner .form-block {
	max-width: 100%;
	min-width: 100%;
	width: 100%;
	}
}

@media (min-width: 576px) {
.adjust-consultant-section {
height: auto;
background: #7D0004;
}
#home-search .adjust-row-business-event {
padding-left: 15px;
padding-right: 15px;
}
}

@media (min-width: 768px) {
#details-inner{
padding-left: 0 !important;
padding-right: 0 !important;
}
.adjust-banner-construction-detail {
grid-template-columns: auto auto auto auto !important;
}
.adjust-link-in-block-section {
background-color: #7D0004;
}
.contact-form {
margin-bottom: 20rem !important;
}
.contact-form .adjust-form {
width: 100% !important;
}
.adjust-link-in-block-section.block-gold {
background-color: #BBA57D;
}
.adjust-link-in-block-section .adjust-coworking-box-for-responsive{
width: 100% !important;
position:inherit;
left: inherit;
}
.adjust-consultant-section {
height: auto;
background: #7D0004;
}
#home-search .adjust-row-business-event {
padding-left: 15px;
padding-right: 15px;
}
	#details #details-inner.with-images{
		transform: inherit;
	}
	#institutional-content {
		border: none;
		position: inherit;
	}
	#details #details-inner .construction-data-section{
		padding-top: inherit;
	}
	#details #details-inner .construction-data-description{
		width: 100% !important;
	}
	.adjust-google-map-in-property-details {
		height: 400px;
	}
	#details #details-inner .feature-row{
	transform: inherit;
	}
}

@media (min-width: 992px) {
.adjust-banner-construction-detail {
grid-template-columns: auto auto auto auto auto !important;
}
.contact-form {
margin-bottom: 20rem !important;
}
.contact-form {
display: flex;
justify-content: center;
align-items: center;
}
.adjust-column-for-coworking-image-responsive {
padding: 0 15px !important;
}
.adjust-link-in-block-section {
background-color: inherit;
}
.adjust-link-in-block-section.block-gold {
background-color: inherit;
}
.adjust-to-left-coworking-image-for-responsive {
width: inherit;
position: relative;
left: 16%;
}
.adjust-link-in-block-section .adjust-coworking-box-for-responsive.to-right{
width: inherit;
position: relative;
left: -16% !important;
}
.adjust-link-in-block-section .adjust-coworking-box-for-responsive{
width: inherit;
position: relative;
left: 16%;
}
.adjust-coworking-image-for-responsive{
width: inherit;
position: relative;
right: 16%;
}
.adjust-consultant-section {
height: 25rem;
background: linear-gradient(180deg, #7D0004 60%, #BBA57D 60%);
}
}

@media (min-width: 1200px) {
#details #details-inner .feature-row {
    transform: translateY(-4.5rem) !important;
}
#details #details-inner.with-images {
    transform: translateY(-4.5rem) !important;
}
.adjust-banner-construction-detail {
display: grid !important;
grid-template-columns: auto auto auto auto auto auto !important;
}
.contact-form {
margin-bottom: 30rem !important;
}
.contact-form .adjust-form {
width: 75%;
}
.adjust-link-in-block-section .adjust-coworking-box-for-responsive{
width: 75% !important;
}
	#institutional-content {
		border: 1px solid #000;
		position: relative;
	}
}

@media (min-width: 1440px) and (max-width: 1440px) {
	.contact-form .adjust-form {
		width: 100% !important;
		position: inherit !important;
		top: 194px;
		right: 0px;
	}
}

@media (min-width: 1366px) and (max-width: 1368px) {
.contact-form .adjust-woman-image{
	position: inherit !important;
	bottom: 0;
	left: 1.2rem;
}
.contact-form .adjust-form {
	width: 100% !important;
	position: inherit !important;
	top: 170px;
	right: 0px;
}

.contact-form {
margin-bottom: 20rem !important;
}
#details #details-inner .feature-block {
min-width: 100% !important;
max-width: 100% !important;
}
#details #details-inner .form-block {
min-width: 100% !important;
max-width: 100% !important;
}
}

@media (min-width: 1280px) and (max-width: 1280px) {
.contact-form .adjust-woman-image{
	position: inherit !important;
	bottom: 0;
	left: 1.2rem;
}
	.contact-form .adjust-form {
	width: 100% !important;
	position: inherit !important;
	top: 169px;
	right: 0px;
	}
.contact-form {
margin-bottom: 20rem !important;
}
#details #details-inner .feature-block {
min-width: 100% !important;
max-width: 100% !important;
}
#details #details-inner .form-block {
min-width: 100% !important;
max-width: 100% !important;
}
}

.slide-wrapper{
	display: flex;
	width: 100%;
}

.slide-wrapper.full-height{
	height: 100vh;
}

.slide-wrapper .banner-contacts a:hover{
	text-decoration: none;
}

.slide-wrapper .slide-item{
	position: relative;
	background: #A8050A;
	height: 100%;
	flex: 1;
	border-right: 2px solid #fff;
	overflow: hidden;
	transition: 0.5s ease-in-out;
}

.slide-wrapper .slide-item .go-to-item{
	position: absolute;
	bottom: 0;
	right: 0;
	background: #A8050A;
	padding: 10px 10px 10px 50px;
}

.slide-wrapper .slide-item:last-child{
	border-right: none;
}

.slide-wrapper .slide-item:hover{
	flex-grow: 3;
	text-decoration: none;
}

.slide-wrapper .slide-item{
	background-position: center center !important;
	background-size: cover !important;
	display: flex;
	height: 100vh;
	align-items: center;
	position: relative;
	z-index: 1;
	justify-content: center;
}

.slide-wrapper .slide-item .slide-content{
	padding: 25px 30px 10px;
	width: 50%;
	margin-top: 10%;
	margin-right: 10%;
	background: rgba(255,255,255,0.2);
	backdrop-filter: blur(4px);
	color: #fff;
	visibility: hidden;
	opacity: 0;
	transition: 0s;
	transform: translateY(100px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.slide-wrapper .slide-item .banner-contacts{
	bottom: 0;
	left: 0;
	z-index: 2;
	margin: auto;
	display: flex;
	visibility: hidden;
	opacity: 0;
	transition: 0s;
	transform: translateY(100px);
}

.slide-wrapper .slide-item .banner-contacts a:nth-child(1){
	margin-right: 30px;
}

.fix-grid-three-columns{
	grid-column: span 2;
}

.slide-wrapper .slide-item .banner-contacts a img {
	width: 30px;
	height: 40px;
	margin-right: 10px;

	@media (min-width: 320px) and (max-width:767) {
		height: 30px;
	}
	@media (min-width: 768px) and (max-width: 1024px) {
		height: 57px;
	}
}

.slide-wrapper .slide-item .banner-contacts a{
	color: #fff;
	font-size: 16px;
	font-weight: 500;

	@media (min-width: 320px) and (max-width:767px) {
		font-size: 16px;
	}

	@media (min-width: 768px) and (max-width: 1024px) {
		font-size: 22px;
	}
}

.slide-wrapper .slide-item .slide-content h2:after {
	content: "";
  display: block;
  margin: 0;
  width: 10%;
  padding-top: 20px;
  border-bottom: 2px solid #fff;
}

.slide-wrapper .slide-item .slide-content h2{
	letter-spacing: 6px;
	text-transform: uppercase;
	font-size: 30px;
	font-weight: bold;
	margin-bottom: 60px;
}

.slide-wrapper .slide-item .slide-content h1{
	position: relative;
	// top: 25px;
	font-weight: bold;
	font-size: 40px;
	margin: 0 10px;
}

.slide-wrapper .slide-item .slide-content p{
	background: #A8050A;
	font-size: 30px;
	padding: 30px 10px 10px 10px;
	line-height: 40px;
}

.slide-wrapper .slide-item:hover .slide-content, .slide-wrapper .slide-item:hover .banner-contacts{
	visibility: visible;
	opacity: 1;
	transition: 0.5s;
	transition-delay: 0.5s;
	transform: translateY(0);
}

@media (max-width: 992px) {
	.slide-wrapper .fix-slide-item-mobile {
		width: 100vw;
		height: 100vh;
	}
	.slide-wrapper .slide-item .slide-content, .slide-wrapper .slide-item .banner-contacts{
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
	position: absolute;
}

	.slide-wrapper .slide-item .slide-content {
		margin-top: 0;
		margin-right: 0;
		width: 70%;
	}

	.slide-wrapper .slide-item .banner-contacts {
		margin: 20px;
		flex-direction: column;
	}

	.slide-wrapper .slide-item .slide-content h2 {
		font-size: 20px;
		margin-bottom: inherit;
	}

	.slide-wrapper .slide-item .slide-content h1 {
		font-size: 30px;
	}

	.slide-wrapper .slide-item .slide-content p {
		font-size: 18px;
	}

	.slide-wrapper .slide-item .banner-contacts a:nth-child(1){
		margin-right: 0;
		margin-bottom: 10px;
	}
}

@media (max-width: 640px) {
	.slide-wrapper .slide-item .slide-content {
		width: 90%;
		padding: 10px 25px;
	}
}

@media (min-width: 1920px) {
	.contact-form {
		margin-bottom: 18rem !important;
}
}

@media (max-width:768px) {
		#Smallchat iframe {
			display: none;
		}
	}

	.property-image-overlay-container {
		position: relative;
		}
	.property-image-overlay-container .property-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    color: #FFF;
		}
		.property-image-overlay-container .property-overlay {
			display: block;
			background: rgba(164,14,14, 0.45);
		}
		@media (max-width: 1200px) {
			.top-3 {
				display: grid!important;
    			justify-content: center!important;
			}
			.top-3 div {
				margin-left: 0rem!important;
			}
		}
		@media (max-width: 1024px) {
			.top-4 div{
				margin: 5px!important;
			}
			.wrapper-top-4__four{
				gap: 0px!important;
			}
			.sec-top-4 {
				margin-left: 6px!important;
			}
			.flex-lg-nowrap {
				flex-wrap: wrap!important;
			}
		}
		@media (max-width: 540px) {
			.top-4 {
				display: flex!important;
				flex-direction: column!important;
			}
			.top-4 div{
				margin-bottom: 5px;
			}
		}
		@media (width: 768px) {
			.top-4{
				display: grid!important;
			}
			.top-3 h4 {
				margin-left: 1.5rem;
			}
			.top-3 p {
				margin-left: 1.0rem!important;
			}
			.table-responsive {
				margin-left: 0rem!important;
			}
		}
		@media (max-width: 767px) {
			.top-3 {
				display: grid;
    			justify-content: center;
			}
			.top-3 div {
				margin-left: 0rem!important;
			}
			.top-3 h4 {
				margin-left: 0rem;
			}
			.top-3 p {
				margin-left: 0rem!important;
			}
		}
		@media (min-width: 320px){
				.table-responsive {
				margin-left: 0rem!important;
			}
		}
		@media (max-width: 1150px){
			.wrapper-top-4__four{
				gap: 1px!important;
			}
		}
	}
`;
