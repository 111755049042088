import React, { useState, useEffect, useCallback } from 'react';

import { Row, Col, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import ContentLoader from 'react-content-loader';

import {
	Title,
	Description,
	LineField,
	Wrapper,
	LabelWrapper,
	SpanTitle,
	CheckboxContainer,
	HiddenCheckbox,
	Icon,
	StyledCheckbox,
} from './styles';
import womanSecretary from '../../../assets/images/secretary-responsive/woman_secretary_w_850.png';
import city from '../../../assets/images/business-woman-responsive/business_woman_w_436.png';
import getFieldValue from '../../../helpers/fields';
import { toastService, apiService, maskService } from '../../../services';
import { baseUrlOfGCS } from '../../../constants/urls';
import { constantUtil } from '../../../utils';
import MaskedInput from 'react-text-mask';

export default function ContactForm() {
	const page = useSelector(state => state.page.home.informations);
	const [backgroundImage, setBackgroundImage] = useState('');
	const [formTitle, setFormTitle] = useState('');
	const [formDescription, setFormDescription] = useState('');
	const [whatsAppChecked, setWhatsAppChecked] = useState(false);
	const [phoneChecked, setPhoneChecked] = useState(false);
	const [emailChecked, setEmailChecked] = useState(false);
	const [loading, setLoading] = useState(false);
	const [contentLoading, setContentLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState('');
	const [name, setName] = useState('');

	const setInitialLoading = useCallback(bool => {
		setContentLoading(bool);
	}, []);

	const setNameValue = useCallback(value => {
		setName(value);
	}, []);

	const setPhoneValue = useCallback(value => {
		setPhone(value);
	}, []);

	const setEmailValue = useCallback(value => {
		setEmail(value);
	}, []);

	const onSubmitContactForm = useCallback(
		event => {
			if (event) {
				setLoading(true);
				event.preventDefault();
			}

			const formTag = document.querySelector('#contact-form');
			const formTagSiblings = formTag.elements;
			const data = {};

			for (let index = 0; index < formTagSiblings.length; index++) {
				const item = formTagSiblings.item(index);

				if (item.name && item.value) {
					data[item.name] = item.value;
				}
			}

			if (!data.message) {
				data.message = '';
			}

			apiService
				.post('api/manager/contato', {
					...data,
					whatsAppChecked,
					phoneChecked,
					emailChecked,
				})
				.then(response => {
					toastService.showToast(
						constantUtil.toasts.success,
						response.data.message,
					);
					setWhatsAppChecked(false);
					setPhoneChecked(false);
					setEmailChecked(false);
					setEmail('');
					setPhone('');
					setMessage('');
					setName('');
					setLoading(false);
				})
				.catch(error => {
					setLoading(false);
					toastService.showToast(
						constantUtil.toasts.error,
						error.response.data.message,
					);
				});
		},
		[emailChecked, phoneChecked, whatsAppChecked],
	);

	useEffect(() => {
		try {
			setInitialLoading(true);
			setBackgroundImage(getFieldValue(page.fields, 37).content);
			console.log(getFieldValue(page.fields, 37).content);
			setFormTitle(getFieldValue(page.fields, 13).content);
			setFormDescription(getFieldValue(page.fields, 14).content);
		} catch (error) {
		} finally {
			setTimeout(() => setInitialLoading(false), 1500);
		}
	}, [page, setInitialLoading]);

	return (
		<>
		<section
			className="contact-form"
			style={{
				backgroundImage: `linear-gradient(to bottom, #fff 0%, #F9F9F9B3 50%, #000000CC 100%), url(${
					backgroundImage &&
					(backgroundImage.indexOf('http') > -1
						? backgroundImage
						: baseUrlOfGCS + backgroundImage)
				})`,
			}}
		>
			<div className="container-fluid adjust-container-contact">
				<Row>
					<Col
						xl={6}
						lg={6}
						md={12}
						sm={12}
						className="h-100 justify-content-end d-none d-sm-none d-md-none d-lg-none d-xl-flex image-container-position"
					>
						<img src={womanSecretary} alt="mulher" />
					</Col>
					<Col
						xl={6}
						lg={9}
						md={12}
						sm={12}
						className="h-100 position-specific"
					>
						<Wrapper className="adjust-form py-5">
							{contentLoading ? (
								<>
									<ContentLoader
										speed={2}
										width={400}
										height={160}
										viewBox="0 0 400 160"
										backgroundColor="#f3f3f3"
										foregroundColor="#ecebeb"
									>
										<rect x="0" y="6" rx="3" ry="3" width="88" height="6" />
										<rect x="0" y="24" rx="3" ry="3" width="52" height="6" />
										<rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
										<rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
										<rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
									</ContentLoader>
								</>
							) : (
								<>
									<Title className="m-0 pb-5">
										{ReactHtmlParser(formTitle)}
									</Title>
									<Description className="pb-5 pb-xl-4">
										{ReactHtmlParser(formDescription)}
									</Description>
								</>
							)}

							<form onSubmit={onSubmitContactForm} id="contact-form">
								<Row>
									<Col className="pl-lg-0" xl={8} lg={8} md={12} sm={12}>
										<Form.Group>
											<Form.Control
												type="text"
												size="lg"
												disabled={loading}
												placeholder="NOME *"
												required={true}
												className="input-contact-form"
												name="name"
												value={name}
												onChange={e => setNameValue(e.target.value)}
											/>
										</Form.Group>
									</Col>
									<Col className="pr-lg-0" xl={4} lg={4} md={12} sm={12}>
										<Form.Group>
											<MaskedInput
												className="form-control form-control-lg input-contact-form"
												disabled={loading}
												required={true}
												type="tel"
												name="phone"
												guide={false}
												placeholder="TELEFONE *"
												mask={maskService.phoneMask}
												value={phone}
												onChange={e => setPhoneValue(e.target.value)}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row className="pb-2">
									<Col
										className="pl-lg-0 pr-lg-0"
										xl={12}
										lg={12}
										md={12}
										sm={12}
									>
										<Form.Group>
											<Form.Control
												type="email"
												size="lg"
												disabled={loading}
												placeholder="E-MAIL *"
												required={true}
												className="input-contact-form"
												name="email"
												value={email}
												onChange={e => setEmailValue(e.target.value)}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col
										className="pl-lg-0 pr-lg-0"
										xl={12}
										lg={12}
										md={12}
										sm={12}
									>
										<p
											style={{ letterSpacing: '0.1rem' }}
											className="pb-3 pb-xl-2 m-0 m-xl-2 text-white"
										>
											PREFIRO QUE ENTRE EM CONTATO VIA:
										</p>
										<LineField className="m-0 m-xl-2">
											<Form.Group>
												<LabelWrapper className="m-0">
													<CheckboxContainer>
														<HiddenCheckbox
															name="whatsAppChecked"
															checked={whatsAppChecked}
															disabled={loading}
															onChange={e =>
																setWhatsAppChecked(!whatsAppChecked)
															}
														/>
														<StyledCheckbox checked={whatsAppChecked}>
															<Icon viewBox="0 0 24 24">
																<polyline points="20 6 9 17 4 12" />
															</Icon>
														</StyledCheckbox>
													</CheckboxContainer>
													<SpanTitle>WhatsApp</SpanTitle>
												</LabelWrapper>
											</Form.Group>
											<Form.Group>
												<LabelWrapper className="m-0">
													<CheckboxContainer>
														<HiddenCheckbox
															name="phoneChecked"
															disabled={loading}
															checked={phoneChecked}
															onChange={e => setPhoneChecked(!phoneChecked)}
														/>
														<StyledCheckbox checked={phoneChecked}>
															<Icon viewBox="0 0 24 24">
																<polyline points="20 6 9 17 4 12" />
															</Icon>
														</StyledCheckbox>
													</CheckboxContainer>
													<SpanTitle>Ligação</SpanTitle>
												</LabelWrapper>
											</Form.Group>
											<Form.Group>
												<LabelWrapper className="m-0">
													<CheckboxContainer>
														<HiddenCheckbox
															name="emailChecked"
															disabled={loading}
															checked={emailChecked}
															onChange={e => setEmailChecked(!emailChecked)}
														/>
														<StyledCheckbox checked={emailChecked}>
															<Icon viewBox="0 0 24 24">
																<polyline points="20 6 9 17 4 12" />
															</Icon>
														</StyledCheckbox>
													</CheckboxContainer>
													<SpanTitle>E-mail</SpanTitle>
												</LabelWrapper>
											</Form.Group>
										</LineField>
									</Col>
								</Row>
								<Row>
									<Col className="pl-lg-2 pr-lg-2" lg={12} md={12} sm={12}>
										<Form.Group>
											<Form.Control
												as="textarea"
												placeholder="MENSAGEM"
												disabled={loading}
												className="textarea-contact-form text-white"
												rows="10"
												cols="46"
												size="lg"
												name="message"
												onChange={e => setMessage(e.target.value)}
												value={message}
											/>
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col className="pl-lg-2 pr-lg-2" lg={12} md={12} sm={12}>
										<Form.Group>
											<Button
												className="adjust-button-contact-submit"
												type="submit"
												disabled={loading}
												variant="primary"
												size="lg"
												block
											>
												ENVIAR
											</Button>
										</Form.Group>
									</Col>
								</Row>
							</form>
						</Wrapper>
					</Col>
				</Row>
			</div>
		</section>
		<section
		className="contact-form"
		style={{
			backgroundImage: `linear-gradient(to bottom, #fff 0%, #F9F9F9B3 50%, #000000CC 100%), url(${
				backgroundImage &&
				(backgroundImage.indexOf('http') > -1
					? backgroundImage
					: baseUrlOfGCS + backgroundImage)
			})`,
		}}
	>
		<div className="container-fluid adjust-container-contact">
			<Row>
				<Col
					xl={4}
					lg={4}
					md={10}
					sm={10}
					className="h-100 justify-content-end d-none d-sm-none d-md-none d-lg-none d-xl-flex image-container-realtor"
				>
					<img src={city} alt="mulher" />
				</Col>
				<Col
					xl={6}
					lg={9}
					md={12}
					sm={12}
					className="h-100 position-specific"
					style={{
						marginRight: 0,
						top: 0,
					}}
				>
					<Wrapper className="adjust-form py-5">
						{contentLoading ? (
							<>
								<ContentLoader
									speed={2}
									width={400}
									height={160}
									viewBox="0 0 400 160"
									backgroundColor="#f3f3f3"
									foregroundColor="#ecebeb"
								>
									<rect x="0" y="6" rx="3" ry="3" width="88" height="6" />
									<rect x="0" y="24" rx="3" ry="3" width="52" height="6" />
									<rect x="0" y="56" rx="3" ry="3" width="410" height="6" />
									<rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
									<rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
								</ContentLoader>
							</>
						) : (
							<>
								<Title className="m-0 pb-5">
									Área do Corretor
								</Title>
								<Description className="pb-5 pb-xl-4">
									Sou corretor e desejo realizar parceria com a MMestre Empreendimentos
								</Description>
							</>
						)}

						<form onSubmit={onSubmitContactForm} id="contact-form">
							<Row>
								<Col className="pl-lg-0" xl={8} lg={8} md={12} sm={12}>
									<Form.Group>
										<Form.Control
											type="text"
											size="lg"
											disabled={loading}
											placeholder="NOME *"
											required={true}
											className="input-contact-form"
											name="name"
											value={name}
											onChange={e => setNameValue(e.target.value)}
										/>
									</Form.Group>
								</Col>
								<Col className="pr-lg-0" xl={4} lg={4} md={12} sm={12}>
									<Form.Group>
										<MaskedInput
											className="form-control form-control-lg input-contact-form"
											disabled={loading}
											required={true}
											type="tel"
											name="phone"
											guide={false}
											placeholder="TELEFONE *"
											mask={maskService.phoneMask}
											value={phone}
											onChange={e => setPhoneValue(e.target.value)}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row className="pb-2">
								<Col
									className="pl-lg-0 pr-lg-0"
									xl={12}
									lg={12}
									md={12}
									sm={12}
								>
									<Form.Group>
										<Form.Control
											type="email"
											size="lg"
											disabled={loading}
											placeholder="E-MAIL *"
											required={true}
											className="input-contact-form"
											name="email"
											value={email}
											onChange={e => setEmailValue(e.target.value)}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col
									className="pl-lg-0 pr-lg-0"
									xl={12}
									lg={12}
									md={12}
									sm={12}
								>
									<p
										style={{ letterSpacing: '0.1rem' }}
										className="pb-3 pb-xl-2 m-0 m-xl-2 text-white"
									>
										PREFIRO QUE ENTRE EM CONTATO VIA:
									</p>
									<LineField className="m-0 m-xl-2">
										<Form.Group>
											<LabelWrapper className="m-0">
												<CheckboxContainer>
													<HiddenCheckbox
														name="whatsAppChecked"
														checked={whatsAppChecked}
														disabled={loading}
														onChange={e =>
															setWhatsAppChecked(!whatsAppChecked)
														}
													/>
													<StyledCheckbox checked={whatsAppChecked}>
														<Icon viewBox="0 0 24 24">
															<polyline points="20 6 9 17 4 12" />
														</Icon>
													</StyledCheckbox>
												</CheckboxContainer>
												<SpanTitle>WhatsApp</SpanTitle>
											</LabelWrapper>
										</Form.Group>
										<Form.Group>
											<LabelWrapper className="m-0">
												<CheckboxContainer>
													<HiddenCheckbox
														name="phoneChecked"
														disabled={loading}
														checked={phoneChecked}
														onChange={e => setPhoneChecked(!phoneChecked)}
													/>
													<StyledCheckbox checked={phoneChecked}>
														<Icon viewBox="0 0 24 24">
															<polyline points="20 6 9 17 4 12" />
														</Icon>
													</StyledCheckbox>
												</CheckboxContainer>
												<SpanTitle>Ligação</SpanTitle>
											</LabelWrapper>
										</Form.Group>
										<Form.Group>
											<LabelWrapper className="m-0">
												<CheckboxContainer>
													<HiddenCheckbox
														name="emailChecked"
														disabled={loading}
														checked={emailChecked}
														onChange={e => setEmailChecked(!emailChecked)}
													/>
													<StyledCheckbox checked={emailChecked}>
														<Icon viewBox="0 0 24 24">
															<polyline points="20 6 9 17 4 12" />
														</Icon>
													</StyledCheckbox>
												</CheckboxContainer>
												<SpanTitle>E-mail</SpanTitle>
											</LabelWrapper>
										</Form.Group>
									</LineField>
								</Col>
							</Row>
							<Row>
								<Col className="pl-lg-2 pr-lg-2" lg={12} md={12} sm={12}>
									<Form.Group>
										<Form.Control
											as="textarea"
											placeholder="MENSAGEM"
											disabled={loading}
											className="textarea-contact-form text-white"
											rows="10"
											cols="46"
											size="lg"
											name="message"
											onChange={e => setMessage(e.target.value)}
											value={message}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col className="pl-lg-2 pr-lg-2" lg={12} md={12} sm={12}>
									<Form.Group>
										<Button
											className="adjust-button-contact-submit"
											type="submit"
											disabled={loading}
											variant="primary"
											size="lg"
											block
										>
											ENVIAR
										</Button>
									</Form.Group>
								</Col>
							</Row>
						</form>
					</Wrapper>
				</Col>
			</Row>
		</div>
	</section>
	</>
	);
}
