import React, { useEffect, useState, useCallback } from 'react';
import ContentLoader from 'react-content-loader';
import {
	FaInstagram,
	FaWhatsapp,
	FaYoutube,
	FaFacebookF,
} from 'react-icons/fa';
import { Row, Col, Image, Button, Form } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import MaskedInput from 'react-text-mask';

import {
	MarketingDiv,
	ImageMarketing,
	Title,
	TextDetailsWrapper,
	IconWrapperColor,
} from './styles';
import Svg from '../../../utils/svg';
import logo from '../../../assets/images/vertical_logo.png';
import getFieldValue from '../../../helpers/fields';
import { getVariableById } from '../../../helpers/variable';
import { baseUrlOfGCS } from '../../../constants/urls';
import {
	ContactHeader,
	ContactSubtitle,
	StyledIcons,
} from '../FooterGeneral/styles';
import { constantUtil } from '../../../utils';
import { apiService, maskService, toastService } from '../../../services';
import constants from '../../../utils/constants';

export default function Footer() {
	const page = useSelector(state => state.page.home.informations);
	const { variables } = useSelector(state => state.variables);
	const [newsletterTitle, setNewsletterTitle] = useState('');
	const [advertisingTitle, setAdvertisingTitle] = useState('');
	const [advertisingImage, setAdvertisingImage] = useState('');
	const [advertisingLink, setAdvertisingLink] = useState('');
	const [whatsAppTitle, setWhatsAppTitle] = useState('');
	const [whatsAppSubtitle, setWhatsAppSubtitle] = useState('');
	const [attendingOnlineTitle, setAttendingOnlineTitle] = useState('');
	const [attendingOnlineSubtitle, setAttendingOnlineSubtitle] = useState('');
	const [attendingTitle, setAttendingTitle] = useState('');
	const [attendingSubtitle, setAttendingSubtitle] = useState('');
	const [whatsAppNumber, setWhatsAppNumber] = useState('');
	const [instagramLink, setInstagramLink] = useState('');
	const [youTubeLink, setYouTubeLink] = useState('');
	const [facebookLink, setFacebookLink] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(false);
	const [contentLoading, setContentLoading] = useState(false);

	const setInitialLoading = useCallback(bool => {
		setContentLoading(bool);
	}, []);

	const setNameValue = useCallback(value => {
		setName(value);
	}, []);

	const setPhoneValue = useCallback(value => {
		setPhone(value);
	}, []);

	const setEmailValue = useCallback(value => {
		setEmail(value);
	}, []);

	const onSubmitNewsletterForm = useCallback(event => {
		if (event) {
			event.preventDefault();
		}
		setLoading(true);

		const formTag = document.querySelector('#newsletter-form');
		const formTagSiblings = formTag.elements;
		const data = {};

		for (let index = 0; index < formTagSiblings.length; index++) {
			const item = formTagSiblings.item(index);

			if (item.name && item.value) {
				data[item.name] = item.value;
			}
		}

		if (!data.phone) {
			data.phone = '-';
		}

		apiService
			.post('api/website/subscribers', data)
			.then(response => {
				toastService.showToast(
					constantUtil.toasts.success,
					response.data.message,
				);
				setEmail('');
				setPhone('');
				setName('');
			})
			.catch(error => {
				toastService.showToast(
					constantUtil.toasts.error,
					error.response.data.message,
				);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	useEffect(() => {
		try {
			setInitialLoading(true);
			if (variables) {
				setWhatsAppTitle(getVariableById(variables, 16));
				setWhatsAppSubtitle(getVariableById(variables, 19));
				setAttendingOnlineTitle(getVariableById(variables, 17));
				setAttendingOnlineSubtitle(getVariableById(variables, 20));
				setAttendingTitle(getVariableById(variables, 18));
				setAttendingSubtitle(getVariableById(variables, 7));
				setWhatsAppNumber(getVariableById(variables, 11));
				setInstagramLink(getVariableById(variables, 14));
				setYouTubeLink(getVariableById(variables, 12));
				setFacebookLink(getVariableById(variables, 13));
			}
		} catch (error) {
		} finally {
			setTimeout(() => setInitialLoading(false), 1500);
		}
	}, [variables]);

	useEffect(() => {
		try {
			setInitialLoading(true);
			if (page) {
				setNewsletterTitle(getFieldValue(page.fields, 15).content);
				setAdvertisingTitle(getFieldValue(page.fields, 16).content);
				setAdvertisingImage(getFieldValue(page.fields, 17).content);
				setAdvertisingLink(getFieldValue(page.fields, 39).content);
			}
		} catch (error) {
		} finally {
			setInitialLoading(false);
		}
	}, [page]);

	return (
		<footer>
			<section className="adjust-first-piece-footer container-fluid">
				<Row className="pt-5 pt-lg-5 px-lg-5">
					<Col
						xl={4}
						lg={12}
						md={12}
						sm={12}
						className="d-flex justify-content-center align-items-start"
					>
						<img
							className="img-fluid d-none d-sm-none d-md-none d-lg-none d-xl-block"
							src={Svg.email}
							alt="Ícone"
						/>
						<h1 className="pl-lg-0 pl-xl-4 m-0 text-uppercase text-white text-center text-sm-center text-md-center text-lg-left pb-5 pb-sm-5 pb-md-5 pb-lg-5 pb-xl-0">
							{contentLoading ? (
								<ContentLoader
									speed={2}
									width={400}
									height={160}
									viewBox="0 0 400 160"
									backgroundColor="#f3f3f3"
									foregroundColor="#ecebeb"
									style={{ width: '100%' }}
								>
									<rect x="0" y="1" rx="3" ry="3" width="400" height="12" />
									<rect x="0" y="32" rx="3" ry="3" width="300" height="12" />
									<rect x="0" y="64" rx="3" ry="3" width="200" height="12" />
								</ContentLoader>
							) : (
								ReactHtmlParser(newsletterTitle)
							)}
						</h1>
					</Col>
					<Col
						xl={6}
						lg={9}
						md={12}
						sm={12}
						className="pb-5 pb-sm-5 pb-md-5 pb-lg-0"
					>
						<form onSubmit={onSubmitNewsletterForm} id="newsletter-form">
							<Row>
								<Col lg={6} md={6} sm={12}>
									<Form.Group>
										<Form.Control
											type="text"
											size="lg"
											placeholder="NOME *"
											required={true}
											className="input-newsletter-form"
											name="name"
											disabled={loading}
											value={name}
											onChange={e => setNameValue(e.target.value)}
										/>
									</Form.Group>
								</Col>
								<Col lg={6} md={6} sm={12}>
									<Form.Group>
										<Form.Control
											type="email"
											size="lg"
											placeholder="E-MAIL *"
											required={true}
											className="input-newsletter-form"
											name="email"
											disabled={loading}
											value={email}
											onChange={e => setEmailValue(e.target.value)}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col lg={6} md={6} sm={12}>
									<Form.Group>
										<MaskedInput
											className="form-control form-control-lg input-newsletter-form"
											disabled={loading}
											type="tel"
											name="phone"
											guide={false}
											placeholder="TELEFONE"
											mask={maskService.phoneMask}
											value={phone}
											onChange={e => setPhoneValue(e.target.value)}
										/>
									</Form.Group>
								</Col>
								<Col lg={6} md={6} sm={12}>
									<Form.Group>
										<Button
											disabled={loading}
											className="w-100 adjust-newsletter-button"
											type="submit"
											variant="primary"
											size="lg"
											block
										>
											CADASTRAR
										</Button>
									</Form.Group>
								</Col>
							</Row>
						</form>
					</Col>
					<Col
						xl={2}
						lg={3}
						md={12}
						sm={12}
						className="d-flex justify-content-center align-items-center"
					>
						{/*Início: Bloco renderizado em telas desktop*/}
						<a
							href={advertisingLink}
							rel="noopener noreferrer"
							target="_blank"
							className="d-none d-sm-none d-md-none d-lg-block"
						>
							{contentLoading ? (
								<ContentLoader
									speed={4}
									width="100%"
									height={200}
									viewBox="0 0 200 200"
									backgroundColor="#f3f3f3"
									foregroundColor="#ecebeb"
									style={{ width: '100%' }}
								>
									<rect x="0" y="0" rx="0" ry="0" width="200" height="200" />
								</ContentLoader>
							) : (
								<MarketingDiv width="200px" height="200px">
									<ImageMarketing
										uri={
											advertisingImage && advertisingImage.indexOf('http') > -1
												? advertisingImage
												: baseUrlOfGCS + advertisingImage
										}
										width="200px"
										height="200px"
									/>
									<TextDetailsWrapper>
										<Title>{advertisingTitle}</Title>
									</TextDetailsWrapper>
								</MarketingDiv>
							)}
						</a>
						{/*Fim: Bloco renderizado em telas desktop*/}
						{/*Início: Bloco renderizado na responsividade*/}
						<a
							href={advertisingLink}
							rel="noopener noreferrer"
							target="_blank"
							className="text-white d-flex d-sm-flex d-md-flex align-items-center justify-content-center d-lg-none w-100"
						>
							{contentLoading ? (
								<ContentLoader
									speed={4}
									width="100%"
									height={200}
									viewBox="0 0 200 200"
									backgroundColor="#f3f3f3"
									foregroundColor="#ecebeb"
									style={{ width: '100%' }}
								>
									<rect x="0" y="0" rx="0" ry="0" width="200" height="200" />
								</ContentLoader>
							) : (
								<div
									className="p-3"
									style={{
										maxWidth: '200px',
										height: '200px',
										border: '1px solid #fff',
										backgroundImage: `linear-gradient(transparent 60%, rgba(0, 0, 0, 0.9)), url(${
											advertisingImage && advertisingImage.indexOf('http') > -1
												? advertisingImage
												: baseUrlOfGCS + advertisingImage
										})`,
									}}
								>
									<p>{advertisingTitle}</p>
								</div>
							)}
						</a>
						{/*Fim: Bloco renderizado na responsividade*/}
					</Col>
				</Row>
				<Row className="py-5 p-lg-5">
					<Col lg={4} sm={12} md={4}>
						{/*Início: Bloco renderizado em telas desktop*/}
						<div className="justify-content-center align-items-center d-none d-sm-none d-md-none d-lg-none d-xl-flex">
							<div className="align-self-start">
								<a
									href={constants.urls.whatsAppApiLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									<IconWrapperColor
										className="d-flex justify-content-center align-items-center"
										bg="#4FBF3E"
									>
										<Image
											style={{
												width: 'auto',
												height: '36px',
											}}
											src={Svg.whatsapp}
											fluid
										/>
									</IconWrapperColor>
								</a>
							</div>
							<div className="text-white">
								<ContactHeader
									style={{ borderColor: '#fff' }}
									className="pb-3 text-white"
								>
									{ReactHtmlParser(whatsAppTitle)}
								</ContactHeader>
								<ContactSubtitle className="pt-3 text-white">
									{ReactHtmlParser(whatsAppSubtitle)}
								</ContactSubtitle>
							</div>
						</div>
						{/*Fim: Bloco renderizado em telas desktop*/}
						{/*Início: Bloco renderizado na responsividade*/}
						<div className="text-white w-100 text-uppercase text-center pb-5 pb-sm-5 pb-md-0 pb-lg-0 d-block d-sm-block d-md-block d-lg-block d-xl-none">
							<p className="m-0">{ReactHtmlParser(whatsAppTitle)}</p>
							<hr className="border-white" />
							<p className="m-0">{ReactHtmlParser(whatsAppSubtitle)}</p>
						</div>
						{/*Fim: Bloco renderizado na responsividade*/}
					</Col>
					<Col lg={4} md={4} sm={12}>
						{/*Início: Bloco renderizado em telas desktop*/}
						<div className=" justify-content-center align-items-center d-none d-sm-none d-md-none d-lg-none d-xl-flex">
							<div className="align-self-start">
								<IconWrapperColor
									className="d-flex justify-content-center align-items-center"
									bg="#D68400"
								>
									<Image
										style={{
											width: 'auto',
											height: '47px',
										}}
										src={Svg.businessWoman}
										fluid
									/>
								</IconWrapperColor>
							</div>
							<div className="text-white">
								<ContactHeader
									style={{ borderColor: '#fff' }}
									className="pb-3 text-white"
								>
									{ReactHtmlParser(attendingOnlineTitle)}
								</ContactHeader>
								<ContactSubtitle className="pt-3 text-white text-sm-left text-md-left text-lg-left">
									{ReactHtmlParser(attendingOnlineSubtitle)}
								</ContactSubtitle>
							</div>
						</div>
						{/*Fim: Bloco renderizado em telas desktop*/}
						{/*Início: Bloco renderizado na responsividade*/}
						<div className="text-white w-100 text-uppercase text-center pb-5 pb-sm-5 pb-md-0 pb-lg-0 d-block d-sm-block d-md-block d-lg-block d-xl-none">
							<p className="m-0">{ReactHtmlParser(attendingOnlineTitle)}</p>
							<hr className="border-white" />
							<p className="m-0">{ReactHtmlParser(attendingOnlineSubtitle)}</p>
						</div>
						{/*Fim: Bloco renderizado na responsividade*/}
					</Col>
					<Col lg={4} sm={12} md={4}>
						<div className="d-flex justify-content-center align-items-center">
							{/*Início: Bloco renderizado em telas desktop*/}
							<div className="text-white d-none d-sm-none d-md-none d-lg-none d-xl-block">
								<ContactHeader
									style={{ borderColor: '#fff' }}
									className="m-0 pb-3 pb-lg-3 text-white"
								>
									{ReactHtmlParser(attendingTitle)}
								</ContactHeader>
								<ContactSubtitle className="m-0 pt-3 pt-lg-3 text-white">
									{ReactHtmlParser(attendingSubtitle)}
								</ContactSubtitle>
							</div>
							{/*Fim: Bloco renderizado em telas desktop*/}
							{/*Início: Bloco renderizado na responsividade*/}
							<div className="text-white w-100 text-uppercase text-center d-block d-sm-block d-md-block d-lg-block d-xl-none">
								<p className="m-0">{ReactHtmlParser(attendingTitle)}</p>
								<hr className="border-white" />
								<p className="m-0">{ReactHtmlParser(attendingSubtitle)}</p>
							</div>
							{/*Fim: Bloco renderizado na responsividade*/}
						</div>
					</Col>
				</Row>
			</section>
			<section className="adjust-second-piece-footer container-fluid">
				<Row className="py-5 p-lg-5 p-sm-5 p-md-5">
					<Col
						className="d-flex justify-content-center align-items-center pb-5 pb-lg-0 pb-sm-0 pb-md-0"
						lg={2}
						md={4}
						sm={12}
					>
						<Link to="/">
							<img src={logo} alt="M. Mestre Empreendimentos" />
						</Link>
					</Col>
					<Col
						className="adjust-copyright d-flex justify-content-center align-items-center pb-5 pb-lg-0 pb-sm-0 pb-md-0 text-center text-sm-center text-md-center text-lg-center"
						lg={7}
						md={4}
						sm={12}
					>
						<p className="m-0">
							Produzido por{' '}
							<a
								href={constantUtil.urls.softmakers}
								rel="noopener noreferrer"
								target="_blank"
							>
								SoftMakers
							</a>
						</p>
					</Col>
					<Col
						className="d-flex justify-content-center align-items-center pb-5 pb-sm-0 pb-md-0 pb-lg-0"
						lg={3}
						md={4}
						sm={12}
					>
						<IconContext.Provider
							value={{
								color: '#AFAFAF',
								size: 30,
							}}
						>
							<StyledIcons className="w-100 d-flex align-items-center justify-content-around">
								<a
									href={instagramLink}
									target="_blank"
									rel="noopener noreferrer"
									className="adjust-social-link color-footer-home"
								>
									<FaInstagram />
								</a>
								<a
									href={facebookLink}
									target="_blank"
									rel="noopener noreferrer"
									className="adjust-social-link color-footer-home"
								>
									<FaFacebookF />
								</a>
								<a
									href={constants.urls.whatsAppApiLink}
									target="_blank"
									rel="noopener noreferrer"
									className="adjust-social-link color-footer-home"
								>
									<FaWhatsapp />
								</a>
								<a
									href={youTubeLink}
									target="_blank"
									rel="noopener noreferrer"
									className="adjust-social-link color-footer-home"
								>
									<FaYoutube />
								</a>
							</StyledIcons>
						</IconContext.Provider>
					</Col>
				</Row>
			</section>
		</footer>
	);
}
