import { SET_CITIES } from '../actions/cities';

const INITIAL_STATE = {
	cities: [],
};

export default function cities(city = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_CITIES:
			return {
				...city,
				cities: action.payload,
			};
		default:
			return city;
	}
}
