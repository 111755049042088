import styled from 'styled-components';
import device from '../../../utils/devices.js';

export const Wrapper = styled.div.attrs(props => ({
	position: props.position || 'static',
	zIndex: props.zIndex || 0,
}))`
	position: ${props => props.position};
	z-index: ${props => props.zIndex};

	@media ${device.mobileS} {
		background-color: transparent;
		padding: 20px;
	}

	@media ${device.tablet} {
		background-color: transparent;
		padding: 20px;
	}

	@media ${device.laptop} {
		background-color: transparent;
		padding: 50px;
	}

	@media ${device.laptopL} {
		background-color: transparent;
		padding: 50px;
	}

	@media ${device.desktop} {
		background-color: transparent;
		padding: 50px;
	}

	@media ${device.desktopL} {
		background-color: transparent;
		padding: 50px;
	}
`;

export const UnorderedList = styled.ul`
	list-style-type: none;
`;

export const UnorderedItem = styled.li.attrs(props => ({
	color: props.color || '#f9f9f9',
}))`
	text-align: center;
	margin-left: 20px;
	a {
		text-transform: uppercase;
		letter-spacing: 4px;
		display: inline-block;
		font-weight: 600;
		text-shadow: 5px;

		&:link {
			color: ${props => props.color};
		}

		&:visited {
			color: #ffffff;
			text-decoration: underline;
		}

		&:hover {
			color: #d60c11;
			text-decoration: none;
		}

		&:active {
			color: #a8050a;
			text-decoration: underline;
		}

		@media ${device.mobileS} {
			font-size: 0.5rem;
		}

		@media ${device.tablet} {
			font-size: 0.425rem;
		}

		@media ${device.laptop} {
			font-size: 0.8rem;
		}

		@media ${device.desktop} {
			font-size: 1.25rem;
		}
	}
`;

export const SpecificMargin = styled.div`
	@media ${device.mobileS} {
		margin-left: 0;
	}

	@media ${device.tablet} {
		margin-left: 20px;
	}

	@media ${device.laptop} {
		margin-left: 40px;
	}

	@media ${device.laptopL} {
		margin-left: 40px;
	}

	@media ${device.desktop} {
	}
`;
