import styled from 'styled-components';
import Devices from '../../../utils/devices';

export const ButtonSubmit = styled.button.attrs(props => ({
	size: props.size || '100%',
	height: props.height || 'auto',
	bgColor: props.bgColor || '#b88e4b',
	bgActive: props.bgActive || '#b88e4bcc',
	bgHover: props.bgHover || '#b88e20',
	fontSize: props.fontSize || '1.3em',
}))`
	display: flex;
	flex: 1;
	justify-content: center;
	border: none;
	color: #fff;
	text-transform: uppercase;
	font-weight: lighter;
	letter-spacing: 0.1em;
	font-size: ${props => props.fontSize};
	outline: none;
	background-color: ${props => props.bgColor};
	margin: ${props => props.marging};
	padding: ${props => props.padding};
	width: ${props => props.size};
	height: ${props => props.height};
	border-radius: ${props => props.radius};
	cursor: pointer;

	:active {
		background-color: ${props => props.bgActive};
		border: 1px solid #0005;
	}

	:hover {
		background-color: ${props => props.bgHover};
		color: #000;
		font-weight: bold;
	}

	@media ${Devices.mobileS} {
		margin-top: 10px;
		margin-bottom: 10px;
		width: 90%;
	}

	@media ${Devices.tablet} {
		width: 90%;
		margin: 0;
	}

	@media ${Devices.laptop} {
		width: 82%;
		margin: 0;
	}

	@media ${Devices.laptopL} {
		width: 82%;
		margin: 0;
	}
`;
