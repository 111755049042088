import React, { useState, useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { FaWhatsapp, FaUserTie, FaInstagram } from 'react-icons/fa';

import { Container, ImmobileInfrastructure, Area, Bed, Garage } from './styles';
import GarageIcon from '../../../assets/icons/garage.png';
import AreaIcon from '../../../assets/icons/area.png';
import BedIcon from '../../../assets/icons/bed.png';
import defaultImage from '../../../assets/images/default.jpg';
import homeIcon from '../../../assets/svg/home.svg';
import { baseUrlOfGCS } from '../../../constants/urls';
import { getVariableById } from '../../../helpers/variable';
import constants from '../../../utils/constants';

function ImmobileCard({
	item = {
		address: {
			state: {
				uf: '',
			},
		},
		images: [],
	},
	location,
}) {
	const [info, setInfo] = useState({});

	const { variables } = useSelector(state => state.variables);
	const [cardAttendingNumber, setCardAttendingNumber] = useState('');
	const [cardWhatsAppNumber, setCardWhatsAppNumber] = useState('');
	const [cardWhatsAppText, setCardWhatsAppText] = useState('');

	const populateInfo = useCallback(() => {
		switch (item.type) {
			case 'apartment':
				setInfo(item.apartments);
				break;
			case 'house':
				setInfo(item.houses);
				break;
			case 'flat':
				setInfo(item.houses);
				break;
			case 'medicBusiness':
				setInfo({ area: item?.coworkings?.total_area });
				break;
			case 'medicCoworking':
				setInfo({ area: item?.coworkings?.total_area });
				break;
			case 'stores':
				setInfo({ area: item?.commercials?.total_area });
				break;
			case 'medicalCenter':
				break;
			case 'hub':
				setInfo({ area: item?.commercials?.total_area });
				break;
			case 'hangar':
				setInfo({ area: item?.commercials?.total_area });
				break;
			case 'coworking':
				setInfo({ area: item?.commercials?.total_area });
				break;
			case 'commercial':
				setInfo({ area: item?.commercials?.total_area });
				break;
			default:
				setInfo({});
				break;
		}
	}, [item]);

	const renderBuildingImage = useCallback(() => {
		if (item?.images.length) {
			const image = item?.images.filter(({ featured }) => featured === 1)[0];
			if (image === undefined) return defaultImage;

			return image?.image_uri.indexOf('http') > -1
				? image?.image_uri
				: baseUrlOfGCS + image?.image_uri;
		}
	}, [item]);

	const renderLink = useCallback(() => {
		let url = '';

		if (
			item.type === 'flat' ||
			item.type === 'house' ||
			item.type === 'apartment'
		) {
			url = url + '/residenciais';
		} else if (
			item.type === 'medicBusiness' ||
			item.type === 'medicCoworking' ||
			item.type === 'coworking'
		) {
			url = url + '/coworkings';
		} else if (item.type === 'medicalCenter') {
			url = url + '/clinicas-medicas';
		} else {
			url = url + '/comerciais';
		}

		url = url + `/${item.id}/detalhes`;

		return url;
	}, [item.id, item.type]);

	useEffect(() => {
		populateInfo();
	}, [populateInfo]);

	useEffect(() => {
		setCardWhatsAppNumber(getVariableById(variables, 11));
		setCardAttendingNumber(getVariableById(variables, 10));
		setCardWhatsAppText(getVariableById(variables, 23));
	}, [variables]);

	return (
		<Container>
			<Link to={renderLink()}>
				<div
					className="adjust-building-card d-flex flex-column-reverse"
					style={{
						backgroundImage: `url(${
							item?.images.length
								? renderBuildingImage()
								: require('../../../assets/images/default.jpg')
						})`,
						filter: `${ item.is_available == false  ?  'grayscale(100)' : 'grayscale(0)'}`,
					}}
				>
					<div>

						<p className="m-0 adjust-building-reference">
							<small>{item.reference}</small>
						</p>
						<p className="m-0 adjust-building-name d-flex justify-content-between">
							<p style={{
								maxWidth: `${ item.is_available == false ? "70%" : "100%"}`,
								overflowX: 'hidden',
								textOverflow: 'ellipsis'
							}}>{item.name}</p>
							<p style={{
								fontSize: `1rem`,
								fontWeight: `bold`,
							}}>{ item.is_available == false ? 'Alugado' : ''}</p>
						</p>

					</div>

				</div>
			</Link>
			<div className="d-flex flex-row justify-content-center align-items-stretch">
				<div className="adjust-building-address">
					<p className="m-0">{item.address.street}</p>
					<p className="m-0">
						<b>
							{item.address.city}/{item.address.state.uf}
						</b>
					</p>
				</div>
				<div className="adjust-building-whatsapp d-flex justify-content-center align-items-center">
					<a
						className="adjust-building-contact-tag-a"
						href={`${
							constants.urls.whatsAppApiLink
						}?text=${cardWhatsAppText} "${
							item.reference ? item.reference : item.name
						}".`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaWhatsapp />
					</a>
				</div>



				<div className="adjust-building-instagram justify-content-center align-items-center "
				style={{
					display: `${item.type === "medicBusiness" || item.type === "medicCoworking" || item.type === "coworking" ? 'flex' : "none"}`
					}}>

					<a
						className="adjust-building-contact-tag-a"
						href={`${
							constants.urls.InstagramApiLink
						}?text=${cardWhatsAppText} "${
							item.reference ? item.reference : item.name
						}".`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaInstagram />
					</a>
				</div>
				<div className="adjust-building-attending d-flex justify-content-center align-items-center">
					<a
						href={`tel:${cardAttendingNumber}`}
						rel="noopener noreferrer"
						className="adjust-building-contact-tag-a"
					>
						<FaUserTie />
					</a>
				</div>
			</div>
			<ImmobileInfrastructure>
				{info.area && (
					<Area>
						<img src={AreaIcon} alt="Área ícone" />
						<small>{info && info.area} m²</small>
					</Area>
				)}
				{info.bedrooms_amount && (
					<Bed>
						<img src={BedIcon} alt="Cama ícone" />
						<small>
							{info && info.bedrooms_amount > 1
								? `${info && info.bedrooms_amount} quartos`
								: `${info && info.bedrooms_amount} quarto`}
						</small>
					</Bed>
				)}
				{info.garages_amount && (
					<Garage>
						<img src={GarageIcon} alt="Garagem ícone" />
						<small>
							{info && info.garages_amount > 1
								? `${info && info.garages_amount} vagas`
								: `${info && info.garages_amount} vaga`}
						</small>
					</Garage>
				)}
				{item?.business_events?.map((item, index) => (
					<div style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}	>
						<img src={homeIcon} alt="Casa ícone" style={{width: '30px'}}/>
						<small style={{
							color: '#4b4b4d',
						}}>{item?.event === "RENT" ? "Aluguel" : "Venda"}</small>
					</div>
				))}
			</ImmobileInfrastructure>
		</Container>
	);
}

export default withRouter(ImmobileCard);
