import styled from 'styled-components';
import device from '../../../utils/devices';

export const Wrapper = styled.div`
	position: fixed;
	bottom: 60px;
	right: 20px;
	z-index: 30;
	opacity: 1;
	-webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
	 -moz-animation: fadein 0.5s; /* Firefox < 16 */
		-ms-animation: fadein 0.5s; /* Internet Explorer */
		 -o-animation: fadein 0.5s; /* Opera < 12.1 */
				animation: fadein 0.5s;
}


@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@media ${device.maxWidthTablet} {
	right: 2.5%;
}`;

export const Container = styled.button`
	background-color: #7d0004;
	border-radius: 50%;
	padding: 10px;
	border: 2px solid #fff;

	&:focus {
		outline: 1px;
	}
`;
