import styled from 'styled-components';
import device from '../../../utils/devices';

export const Container = styled.div`
	max-width: 100%;
	height: auto;
	background-color: #7d0004;
	margin-top: 50px;
	padding-top: 100px;
	position: relative;
	z-index: 1;
`;

export const AbsoluteInfo = styled.div`
	max-width: 100%;
	height: auto;
	background-color: #610306;
	padding: 30px;
`;

export const Separator = styled.div`
	margin-top: 15px;
	height: 1px;
	width: 100%;
	background-color: #ffffff;
`;

export const AuthorInfo = styled.h3`
	text-transform: uppercase;
	font-size: 0.6em;
	color: #fff9;
	letter-spacing: 1px;
	font-weight: lighter;
	margin-top: 10px;
`;

export const IconWrapper = styled.div``;

export const FooterInfo = styled.div`
	width: 100%;
	height: auto;
`;

export const TextTitle = styled.h1`
	@media ${device.mobileS} {
		display: flex;
		flex-direction: column;
		align-self: center;
		font-size: 1.8em;
		font-weight: lighter;
		color: #fff;
		text-transform: uppercase;
		text-align: start;
		width: 100%;
		padding-bottom: 20px;
	}

	@media ${device.tablet} {
		display: flex;
		flex-direction: column;
		align-self: center;
		font-size: 1.3em;
		font-weight: lighter;
		color: #fff;
		text-transform: uppercase;
		text-align: start;
		width: 100%;
		padding-bottom: 20px;
	}

	@media ${device.laptop} {
		display: flex;
		flex-direction: column;
		align-self: center;
		font-size: 1.8em;
		font-weight: lighter;
		color: #fff;
		text-transform: uppercase;
		text-align: start;
		width: 100%;
		padding-bottom: 20px;
	}
`;

export const LineField = styled.div`
	@media ${device.mobileS} {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-template-rows: repeat(2, 1fr);
	}

	@media ${device.tablet} {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 50%);
	}

	@media ${device.laptop} {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 50%);
	}
`;

export const MarketingDiv = styled.div`
	width: ${props => props.width};
	height: ${props => props.height};
	background-color: #000;
	position: relative;
	display: flex;
	justify-content: center;
`;

export const ImageMarketing = styled.img`
	width: ${props => props.width};
	height: ${props => props.height};
	background-image: linear-gradient(transparent 60%, rgba(0, 0, 0, 0.9)),
		url(${props => props.uri});
	background-size: cover;
	background-color: transparent;
	filter: grayscale(100%);
	position: absolute;
	opacity: 0.6;
	object-fit: cover;
`;

export const TextDetailsWrapper = styled.div`
	padding: 1rem;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: column;
`;

export const IconWrapperColor = styled.div`
	background: ${props => props.bg};

	@media ${device.mobileS} {
		border-radius: 31px 31px 31px 0;
		width: 59px;
		height: 59px;
		text-align: center;
		margin: 0 auto;
	}

	@media ${device.tablet} {
		border-radius: 31px 31px 31px 0;
		width: 59px;
		height: 59px;
		text-align: center;
		margin: 0 auto;
	}

	@media ${device.laptop} {
		border-radius: 31px 31px 31px 0;
		width: 59px;
		height: 59px;
		text-align: center;
		margin-right: 20px;
	}
`;

export const TextDetailsTitle = styled.h1`
	@media ${device.mobileS} {
		font-size: 1.6em;
		letter-spacing: 0.1em;
		color: #fff;
		font-weight: lighter;
		padding-top: 10px;
		text-transform: uppercase;
		text-align: center;
	}

	@media ${device.tablet} {
		font-size: 1.4em;
		letter-spacing: 0.1em;
		color: #fff;
		font-weight: lighter;
		padding-top: ${props => props.padding};
		text-transform: uppercase;
	}

	@media ${device.laptop} {
		font-size: 1.6em;
		letter-spacing: 0.1em;
		color: #fff;
		font-weight: lighter;
		padding-top: ${props => props.padding};
		text-transform: uppercase;
	}
`;

export const TextDetailsParagraph = styled.p`
	color: #fff;
	font-size: 1.2em;
	letter-spacing: 0.2em;
	width: 100%;
	text-align: left;
	padding-top: 10px;
`;

export const Title = styled.h3`
	letter-spacing: 2.7px;
	margin: 0;
	font-size: 1rem;
	color: #fff;
`;
