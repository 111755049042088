import { SET_STATES } from '../actions/states';

const INITIAL_STATE = {
	states: [
		{
			id: 1,
			name: 'PERNAMBUCO',
			uf: 'PE',
			created_at: '2020-02-13 15:06:40',
			updated_at: '2020-02-13 15:06:40',
			deleted_at: null,
			ibge_id: null,
		},
		{
			id: 2,
			name: 'PARAÍBA',
			uf: 'PB',
			created_at: '2020-02-13 15:06:40',
			updated_at: '2020-02-13 15:06:40',
			deleted_at: null,
			ibge_id: null,
		},
	],
};

export default function states(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_STATES:
			return {
				...state,
				states: action.payload,
			};

		default:
			return state;
	}
}
