import React, { useEffect, useState, Fragment } from 'react';

import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Media from 'react-media';
import { SelectGroup, SelectOption } from './styles';
import InputText from '../../atoms/Input';
import Button from '../../atoms/Button';
import { constantUtil } from '../../../utils';
import { eventService } from '../../../services';
import { format } from '../../../helpers';

function SearchBarDetailed(props) {
	const { states = [] } = useSelector(state => state.states);
	const { cities = [] } = useSelector(state => state.cities);
	const [businessEventTypes] = useState(constantUtil.businessEventTypes);
	const [constructionTypes, setConstructionTypes] = useState(
		constantUtil.buildingTypes.filter(
			({ nature }) => nature === props.location.pathname.replace('/', ''),
		),
	);
	const [monetaryValues] = useState(constantUtil.monetaryValues);
	const [bedroomsOptions] = useState(constantUtil.bedroomsQuantity);
	const [bathroomsOptions] = useState(constantUtil.bathroomsQuantity);
	const [citiesOptions, setCitiesOptions] = useState(cities);

	const [stateSearchOption, setStateSearchOption] = useState('');
	const [citySearchOption, setCitySearchOption] = useState('');
	const [
		businessEventTypeSearchOption,
		setBusinessEventTypeSearchOption,
	] = useState('');
	const [
		constructionTypeSearchOption,
		setConstructionTypeSearchOption,
	] = useState('');
	const [
		minMonetaryValueSearchOption,
		setMinMonetaryValueSearchOption,
	] = useState('');
	const [
		maxMonetaryValueSearchOption,
		setMaxMonetaryValueSearchOption,
	] = useState('');
	const [minBedroomSearchOption, setMinBedroomSearchOption] = useState('');
	const [maxBedroomSearchOption, setMaxBedroomSearchOption] = useState('');
	const [minBathroomSearchOption, setMinBathroomSearchOption] = useState('');
	const [maxBathroomSearchOption, setMaxBathroomSearchOption] = useState('');
	const [
		neighborhoodOrCodeSearchOption,
		setNeighborhoodOrCodeSearchOption,
	] = useState('');

	useEffect(() => {
		setConstructionTypes(
			constantUtil.buildingTypes.filter(
				({ nature }) => nature === props.location.pathname.replace('/', ''),
			),
		);

		if (props.location.search) {
			const aux = format.paramsToObject(props.location.search);

			if (aux.state_id) {
				setStateOption(aux.state_id);
			}

			if (aux.city) {
				setCityOption(aux.city);
			}

			if(aux.business_event_type){
				setBusinessEventTypeOption(aux.business_event_type);
			}

			if(aux.min_price){
				setMinMonetaryValueSearchOption(aux.min_price);
			}

			if(aux.max_price){
				setMaxMonetaryValueSearchOption(aux.max_price);
			}

			if(aux.min_bedroom){
				setMinBedroomSearchOption(aux.min_bedroom);
			}

			if(aux.max_bedroom){
				setMaxBedroomSearchOption(aux.max_bedroom);
			}

			if(aux.min_bathroom){
				setMinBathroomSearchOption(aux.min_bathroom);
			}

			if(aux.max_bathroom){
				setMaxBathroomSearchOption(aux.max_bathroom);
			}

			if (aux.construction_type) {
				setConstructionTypeOption(aux.construction_type);
			}

			if (aux.neighborhood_or_code) {
				setNeighborhoodOrCodeSearchOption(aux.neighborhood_or_code);
			}
		}
	}, [props]);

	function search() {		
		if (
			props.match.params.type === 'coworkings' ||
			props.match.params.type === 'comerciais' ||
			props.match.params.type === 'clinicas-medicas'
		) {
			eventService.emit(constantUtil.events.searchBarButtonClicked, {
				stateSearchOption,
				citySearchOption,
				constructionTypeSearchOption,
				neighborhoodOrCodeSearchOption,
				pathname: `/${props.match.params.type}`,
			});
			window.location.reload();
		}

		if (props.match.params.type === 'residenciais') {			
			eventService.emit(constantUtil.events.searchBarButtonClicked, {
				stateSearchOption,
				businessEventTypeSearchOption,
				constructionTypeSearchOption,
				minMonetaryValueSearchOption,
				maxMonetaryValueSearchOption,
				minBedroomSearchOption,
				maxBedroomSearchOption,
				minBathroomSearchOption,
				maxBathroomSearchOption,
				neighborhoodOrCodeSearchOption,
				pathname: `/${props.match.params.type}`,
			});
			window.location.reload();
		};
	}

	function setStateOption(value) {
		setStateSearchOption(value);
	}

	function setCityOption(value) {
		setCitySearchOption(value);
	}

	function setBusinessEventTypeOption(value) {
		setBusinessEventTypeSearchOption(value);
	}

	function setConstructionTypeOption(value) {
		setConstructionTypeSearchOption(value);
	}

	function setMonetaryValueOption(value) {
		setMinMonetaryValueSearchOption(
			value.split(':')[0] ? value.split(':')[0] : '',
		);
		setMaxMonetaryValueSearchOption(
			value.split(':')[1] ? value.split(':')[1] : '',
		);
	}

	function setBedroomsOption(value) {
		setMinBedroomSearchOption(value.split(':')[0] ? value.split(':')[0] : '');
		setMaxBedroomSearchOption(value.split(':')[1] ? value.split(':')[1] : '');
	}

	function setBathroomsOption(value) {
		setMinBathroomSearchOption(value.split(':')[0] ? value.split(':')[0] : '');
		setMaxBathroomSearchOption(value.split(':')[1] ? value.split(':')[1] : '');
	}

	function setNeighborhoodOrCodeOption(value) {
		setNeighborhoodOrCodeSearchOption(value);
	}

	function updateCities(value) {
		setCityOption('');
		if (value) {
			setCitiesOptions(
				cities.filter(city => parseInt(city.state_id) === parseInt(value)),
			);
		} else {
			setCitiesOptions(cities);
		}
	}

	return (
		<div
			id="advanced-search-bar"
			className="container-fluid d-flex justify-content-center align-items-center"
			style={{ paddingLeft: "5px", paddingRight: "5px" }} 
		>
			<Row className="w-100">
				<Col
					lg="7"
					sm="12"
					md="12"
					className="d-flex flex-md-wrap justify-content-start"
					style={{ padding: "0", flexGrow: "1", width: "100%", flexWrap: "wrap" }} 
				>
					<SelectGroup						
						onChange={e => {
							setStateOption(e.target.value);
							updateCities(e.target.value);
						}}
						className="w-100-mobile-list-screens flex-grow-1"
					>
						<SelectOption value="">ESTADO</SelectOption>
						{states.map((state, index) => (
							<SelectOption
								key={index}
								value={state.id}
								selected={
									parseInt(stateSearchOption) === parseInt(state.id)
								}
							>
								{state.name}
							</SelectOption>
						))}
					</SelectGroup>
					<SelectGroup
						className="w-100-mobile-list-screens flex-grow-1"
						onChange={e => setMonetaryValueOption(e.target.value)}
					>
						<SelectOption value="">VALOR</SelectOption>
						{monetaryValues.map((monetaryValue, index) => (
							<SelectOption 
								key={index} 
								value={monetaryValue.value}
								selected={
									(minMonetaryValueSearchOption + ':' + maxMonetaryValueSearchOption) === monetaryValue.value
								}
							>										
								{monetaryValue.label}
							</SelectOption>
						))}
					</SelectGroup>
					{(props.match.params.type === 'residenciais' || props.match.params.type === 'comerciais') &&  (
						<>
						<SelectGroup
							className="w-100-mobile-list-screens flex-grow-1"
							onChange={e => setBusinessEventTypeOption(e.target.value)}
						>
							<SelectOption value="">NATUREZA</SelectOption>
							{businessEventTypes.map((businessEventType, index) => (
								<SelectOption 
									key={index} 
									value={businessEventType.value}
									selected={
										businessEventTypeSearchOption === businessEventType.value
									}
									>										
									{businessEventType.label}
								</SelectOption>
							))}
						</SelectGroup>
						<SelectGroup
							className="w-100-mobile-list-screens flex-grow-1"
							onChange={e => setConstructionTypeOption(e.target.value)}
						>
							<SelectOption value="">TIPO</SelectOption>
							{constructionTypes.map((constructionType, index) => (
								<SelectOption
									key={index}
									value={constructionType.value}
									selected={
										constructionTypeSearchOption === constructionType.value
									}
								>
									{constructionType.label}
								</SelectOption>
							))}
						</SelectGroup>			
						<SelectGroup
							className="w-100-mobile-list-screens flex-grow-1"
							onChange={e => setBathroomsOption(e.target.value)}
						>
							<SelectOption value="">BANHEIROS</SelectOption>
							{bathroomsOptions.map((bathroomOption, index) => (
								<SelectOption 
									key={index} 
									value={bathroomOption.value}
									selected={
										(minBathroomSearchOption + ':' + maxBathroomSearchOption) === bathroomOption.value
									}	
								>
									{bathroomOption.label}
								</SelectOption>
							))}
						</SelectGroup>	
						</>
					)}			
					{(props.match.params.type === 'residenciais' || props.match.params.type === 'clinicas-medicas') &&  (
						<>
						<SelectGroup
							className="w-100-mobile-list-screens flex-grow-1"
							onChange={e => setBedroomsOption(e.target.value)}
						>
							<SelectOption value="">QUARTOS</SelectOption>
							{bedroomsOptions.map((bedroomOption, index) => (
								<SelectOption 
									key={index} 
									value={bedroomOption.value}
									selected={
										(minBedroomSearchOption + ':' + maxBedroomSearchOption) === bedroomOption.value
									}
								>										
									{bedroomOption.label}
								</SelectOption>
							))}
						</SelectGroup>
						</>
					)}
					{(props.match.params.type === 'clinicas-medicas') &&  (
						<>
						<SelectGroup
							className="w-100-mobile-list-screens flex-grow-1"
							onChange={e => setBathroomsOption(e.target.value)}
						>
							<SelectOption value="">BANHEIROS</SelectOption>
							{bathroomsOptions.map((bathroomOption, index) => (
								<SelectOption 
									key={index} 
									value={bathroomOption.value}
									selected={
										(minBathroomSearchOption + ':' + maxBathroomSearchOption) === bathroomOption.value
									}	
								>
									{bathroomOption.label}
								</SelectOption>
							))}
						</SelectGroup>	
						</>
					)}			
				</Col>
				<Col					
					className="d-none d-sm-none d-md-block"
					style={{ paddingLeft: "0" }} 
				>
					<div className="adjust-search-bar-input w-100" style={{ position: "absolute" }} >
						<input
							style={{
								backgroundImage: 'none',
								backgroundColor: 'transparent',
								border: '1px solid transparent',
								color: '#585858',
								letterSpacing: '0.1rem',
								boxShadow: 'none',
								padding: '1em',
								margin: '0',
								transition: '0.5s ease-out',
								outline: 'none',
								borderLeftStyle: 'solid',
								borderColor: 'rgb(225, 225, 225)',
							}}
							className="w-100"
							value={neighborhoodOrCodeSearchOption}
							onChange={e => setNeighborhoodOrCodeOption(e.target.value)}
							placeholder="BAIRRO OU CÓDIGO"
							borderColor="transparent"
							textColor="#585858"
							size="100%"
							margin="0"
						/> 
					</div>
				</Col>
				<Col
					lg="2"
					sm="12"
					md="6"
					className="d-flex flex-md-wrap flex-lg-nowrap justify-content-end w-100-mobile-list-screens"
					style={{ padding: '0' }}
				>
					<div className="adjust-search-bar-button w-100-mobile-list-screens" style={{ display: 'contents' }}> 
						<Button
							onClick={() => search()}
							txt="Buscar"
							style={{ display: 'block' }}
						/>
					</div>
				</Col>
			</Row>
		</div>
	);
}

export default withRouter(SearchBarDetailed);
