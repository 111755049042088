import React, { useEffect, useState, useCallback } from 'react';

import { MdKeyboardArrowRight } from 'react-icons/md';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import { Line, Description, CoworkingBox } from './styles';
import getFieldValue from '../../../helpers/fields';
import { baseUrlOfGCS } from '../../../constants/urls';

export default function CoworkingDescription() {
	const page = useSelector(state => state.page.home.informations);
	const [firstImage, setFirstImage] = useState('');
	const [firstBlockTitle, setFirstBlockTitle] = useState('');
	const [firstBlockDescription, setFirstBlockDescription] = useState('');
	const [secondImage, setSecondImage] = useState('');
	const [secondBlockTitle, setSecondBlockTitle] = useState('');
	const [secondBlockDescription, setSecondBlockDescription] = useState('');
	const [loading, setLoading] = useState(false);

	const setInitialLoading = useCallback(bool => {
		setLoading(bool);
	}, []);

	useEffect(() => {
		try {
			setInitialLoading(true);
			setFirstImage(getFieldValue(page.fields, 9).content);
			setFirstBlockTitle(getFieldValue(page.fields, 18).content);
			setFirstBlockDescription(getFieldValue(page.fields, 10).content);
			setSecondImage(getFieldValue(page.fields, 11).content);
			setSecondBlockTitle(getFieldValue(page.fields, 19).content);
			setSecondBlockDescription(getFieldValue(page.fields, 12).content);
		} catch (error) {
		} finally {
			setTimeout(() => setInitialLoading(false), 1500);
		}
	}, [page, setInitialLoading]);

	return (
		<div>
			<Row className="p-0 py-lg-5 py-xl-5">
				<Col
					className="adjust-link-in-block-section"
					xl={6}
					lg={6}
					sm={12}
					md={12}
				>
					<Link to={`/clinicas-medicas`}>
						<CoworkingBox className="w-75 d-flex flex-column py-5 px-0 py-sm-5 px-sm-0 p-md-5 p-lg-5 p-xl-5 justify-content-center align-items-center adjust-coworking-box-for-responsive">
							<div className="w-100 d-flex justify-content-between align-content-start text-white adjust-title-block">
								<span>&nbsp;</span>
								<h1 className="m-0 text-center">
									{loading ? (
										<ContentLoader
											speed={2}
											style={{ width: '100%' }}
											height={160}
											width={400}
											backgroundColor="#f3f3f3"
											foregroundColor="#ecebeb"
											viewBox="0 0 400 160"
										>
											<rect
												x="70"
												y="15"
												rx="3"
												ry="3"
												width="300"
												height="12"
											/>
											<rect
												x="70"
												y="40"
												rx="3"
												ry="3"
												width="300"
												height="8"
											/>
											<rect
												x="70"
												y="60"
												rx="3"
												ry="3"
												width="300"
												height="4"
											/>
										</ContentLoader>
									) : (
										ReactHtmlParser(firstBlockTitle)
									)}
								</h1>
								<MdKeyboardArrowRight size={28} />
							</div>
							<Line className="mt-5 mb-5" />
							<Description>
								{loading ? (
									<ContentLoader
										speed={2}
										style={{ width: '100%' }}
										height={160}
										width={400}
										viewBox="0 0 400 160"
										backgroundColor="#f3f3f3"
										foregroundColor="#ecebeb"
									>
										<rect x="5" y="15" rx="3" ry="3" width="410" height="6" />
										<rect x="5" y="31" rx="3" ry="3" width="380" height="6" />
										<rect x="5" y="47" rx="3" ry="3" width="340" height="6" />
										<rect x="5" y="63" rx="3" ry="3" width="320" height="6" />
										<rect x="5" y="79" rx="3" ry="3" width="178" height="6" />
										<rect x="5" y="95" rx="3" ry="3" width="160" height="6" />
									</ContentLoader>
								) : (
									ReactHtmlParser(firstBlockDescription)
								)}
							</Description>
						</CoworkingBox>
					</Link>
				</Col>
				<Col
					xl={6}
					lg={6}
					sm={12}
					md={12}
					className="p-0 p-sm-0 p-md-0 adjust-column-for-coworking-image-responsive"
				>
					{loading ? (
						<ContentLoader
							speed={2}
							style={{ width: '100%' }}
							height={600}
							viewBox="0 0 600 600"
							backgroundColor="#f3f3f3"
							foregroundColor="#ecebeb"
						>
							<rect x="1" y="9" rx="2" ry="2" width="600" height="600" />
						</ContentLoader>
					) : (
						<img
							className="img-fluid adjust-coworking-image-for-responsive"
							src={
								firstImage && firstImage.indexOf('http') > -1
									? firstImage
									: baseUrlOfGCS + firstImage
							}
							alt="primeira imagem"
						/>
					)}
				</Col>
			</Row>
			<Row className="p-0 py-lg-5 py-xl-5 d-flex d-sm-flex d-md-flex d-lg-flex d-xl-flex flex-column-reverse flex-sm-column-reverse flex-md-column-reverse flex-lg-row flex-xl-row">
				<Col
					xl={6}
					lg={6}
					sm={12}
					md={12}
					className="p-0 p-sm-0 p-md-0 adjust-column-for-coworking-image-responsive"
				>
					{loading ? (
						<ContentLoader
							speed={2}
							style={{ width: '100%' }}
							height={800}
							width={800}
							viewBox="0 0 800 800"
							backgroundColor="#f3f3f3"
							foregroundColor="#ecebeb"
						>
							<rect x="1" y="9" rx="2" ry="2" width="800" height="800" />
						</ContentLoader>
					) : (
						<img
							className="img-fluid adjust-to-left-coworking-image-for-responsive"
							src={
								secondImage && secondImage.indexOf('http') > -1
									? secondImage
									: baseUrlOfGCS + secondImage
							}
							alt="segunda imagem"
						/>
					)}
				</Col>
				<Col
					className="adjust-link-in-block-section block-gold"
					xl={6}
					lg={6}
					sm={12}
					md={12}
				>
					<Link to={`/comerciais`}>
						<CoworkingBox className="w-75 d-flex flex-column py-5 px-0 py-sm-5 px-sm-0 p-md-5 p-lg-5 p-xl-5 justify-content-center align-items-center adjust-coworking-box-for-responsive adjust-force-to-left-block-section to-right">
							<div className="w-100 d-flex justify-content-between align-content-start text-white adjust-title-block">
								<span>&nbsp;</span>
								<h1 className="m-0 text-center">
									{loading ? (
										<ContentLoader
											speed={2}
											style={{ width: '100%' }}
											height={160}
											width={400}
											backgroundColor="#f3f3f3"
											foregroundColor="#ecebeb"
											viewBox="0 0 400 160"
										>
											<rect
												x="70"
												y="15"
												rx="3"
												ry="3"
												width="300"
												height="12"
											/>
											<rect
												x="70"
												y="40"
												rx="3"
												ry="3"
												width="300"
												height="8"
											/>
											<rect
												x="70"
												y="60"
												rx="3"
												ry="3"
												width="300"
												height="4"
											/>
										</ContentLoader>
									) : (
										ReactHtmlParser(secondBlockTitle)
									)}
								</h1>
								<MdKeyboardArrowRight size={28} />
							</div>
							<Line className="mt-5 mb-5" />
							<Description>
								{loading ? (
									<ContentLoader
										speed={2}
										style={{ width: '100%' }}
										height={160}
										width={400}
										viewBox="0 0 400 160"
										backgroundColor="#f3f3f3"
										foregroundColor="#ecebeb"
									>
										<rect x="5" y="15" rx="3" ry="3" width="410" height="6" />
										<rect x="5" y="31" rx="3" ry="3" width="380" height="6" />
										<rect x="5" y="47" rx="3" ry="3" width="340" height="6" />
										<rect x="5" y="63" rx="3" ry="3" width="320" height="6" />
										<rect x="5" y="79" rx="3" ry="3" width="178" height="6" />
										<rect x="5" y="95" rx="3" ry="3" width="160" height="6" />
									</ContentLoader>
								) : (
									ReactHtmlParser(secondBlockDescription)
								)}
							</Description>
						</CoworkingBox>
					</Link>
				</Col>
			</Row>
		</div>
	);
}
