const size = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopM: '1366px',
	laptopL: '1440px',
	desktop: '1920px',
	desktopL: '2560px',
};

// TODO For a future breakpoints refactor
const breakpoints = {
	screenXs: '480px', // Extra small screen
	screenSm: '768px', // Small screen
	screenMd: '992px', // Medium screen
	screenLg: '1200px', // Large screen
	screenXlg: '1600px', // Extra Large screen
	screenXxlg: '1900px', // Extra Large screen
};

const maxBreakpoints = {
	$screenXsMmax: breakpoints.screenSm - 1,
	$screenSmMax: breakpoints.screenMd - 1,
	$screenMdMax: breakpoints.screenLg - 1,
	$screenLgMax: breakpoints.screenXlg - 1,
	$screenXlgMax: breakpoints.screenXxlg - 1,
};

export default {
	mobileS: `(min-width: ${size.mobileS})`,
	mobileM: `(min-width: ${size.mobileM})`,
	mobileL: `(min-width: ${size.mobileL})`,
	tablet: `(min-width: ${size.tablet})`,
	laptop: `(min-width: ${size.laptop})`,
	laptopM: `(min-width: ${size.laptopM})`,
	laptopL: `(min-width: ${size.laptopL})`,
	desktop: `(min-width: ${size.desktop})`,
	desktopL: `(min-width: ${size.desktop})`,
	maxWidthTablet: `(max-width: ${size.tablet})`,
};

/*
	Media Queries Modules

	@media ${Devices.mobileS} {

	}

	@media ${Devices.tablet} {

	}

	@media ${Devices.laptop} {

	}

		@media ${Devices.laptopL} {

	}

*/
