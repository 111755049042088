import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    bottom: 10px;
    right: 20px;
    z-index: 1000;
    
    max-width: 250px;
    width: 100%;
    height: 40px;
    padding: 10px 20px;

    background-color: rgb(246, 152, 0);
    color: rgb(255, 255, 255);
    opacity: 1;
    border-radius: 20px;

    cursor: pointer;

    svg{
        max-width: 10%;
        fill: rgb(255, 255, 255);
        margin-right: 5px;
    }

    a{
        text-decoration: none;
        color: rgb(255, 255, 255);
        transition: all 0.3s ease;
        font-size: 0.9rem;
        
    }
`;