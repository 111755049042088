export function getVariable(variables = [], slug) {
	return variables.find(variable => variable.slug === slug) || {};
}

export function getVariableById(variables = [], id) {
	const item = variables.find(variable => variable.id === id);

	return item ? item.value : '';
}

export default {
	getVariable: getVariable,
	getVariableById: getVariableById,
};
