import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { Pagination, Row, Col } from 'react-bootstrap';
import { ActionsTriggers } from './styles';
import Immobile from '../../molecules/ImmobileCard';
import { eventService } from '../../../services';
import { constantUtil } from '../../../utils';
import ContentLoaderGrid from '../../template/ContentLoaderGrid';
import Media from 'react-media';

const reducer = (state, action) => {
	switch (action.type) {
		case 'switch_one':
			return { ...state, one: false };
		case 'switch_two':
			return { ...state, two: false };
		case 'switch_three':
			return { ...state, three: false };
		default:
			return state;
	}
};

export default function CardList() {
	const [paginate, setPaginate] = useState({
		data: [],
		total: 0,
		last_page: 1,
		current_page: 1,
	});
	const [pagination, setPagination] = useState([]);
	const [loading, setLoading] = useState(false);

	const setInitialLoading = useCallback(bool => {
		setLoading(bool);
	}, []);

	const listenEvents = useCallback(() => {
		eventService.on(constantUtil.events.paginateUpdated, value => {
			setPaginate(value);
		});
	}, []);

	const pageChanged = useCallback(
		event => {
			const changePage = event.target.text
				? event.target.text
				: event.target.value;

			if (changePage) {
				const next = changePage.indexOf('Next') > -1;
				const previous = changePage.indexOf('Previous') > -1;
				const pageNumber = parseInt(changePage);

				if (next) {
					eventService.emit(constantUtil.events.pageChanged, {
						page: paginate.current_page + 1,
					});
				} else if (previous) {
					eventService.emit(constantUtil.events.pageChanged, {
						page: paginate.current_page - 1,
					});
				} else {
					eventService.emit(constantUtil.events.pageChanged, {
						page: pageNumber,
					});
				}
			}
		},
		[paginate.current_page],
	);

	const buildPagination = useCallback(() => {
		const auxiliaryArray = [];

		auxiliaryArray.push(
			<Pagination.Prev key={0} disabled={paginate.current_page === 1} />,
		);

		for (let index = 0; index < paginate.last_page; index++) {
			auxiliaryArray.push(
				<Pagination.Item
					key={index + 1}
					active={paginate.current_page === index + 1}
				>
					{index + 1}
				</Pagination.Item>,
			);
		}

		auxiliaryArray.push(
			<Pagination.Next
				key={paginate.last_page + 1}
				disabled={paginate.current_page === paginate.last_page}
			/>,
		);

		setPagination(auxiliaryArray);
	}, [paginate.current_page, paginate.last_page]);

	function renderCards(array = []) {
		return (
			<>
				{loading ? (
					<Media
						queries={{
							small: '(max-width: 599px)',
							medium: '(min-width: 600px)',
							large: '(min-width: 1440px)',
						}}
					>
						{matches => (
							<Fragment>
								{matches.small && <ContentLoaderGrid column={1} width={600} />}

								{matches.medium && (
									<ContentLoaderGrid column={3} width={1366} />
								)}
							</Fragment>
						)}
					</Media>
				) : (
					array.map((item, index) => (
						<Col
							key={index}
							xl={4}
							lg="6"
							md="6"
							sm="12"
							className="d-flex justify-content-center align-items-center pb-5 pb-sm-5 pb-md-0 pb-lg-0"
						>
							<Immobile key={item.id} item={item} />
						</Col>
					))
				)}
			</>
		);
	}

	useEffect(() => {
		listenEvents();
	}, []);

	useEffect(() => {
		try {
			setInitialLoading(true);
			buildPagination();
		} finally {
			setTimeout(() => setInitialLoading(false), 1500);
		}
	}, [buildPagination]);

	return (
		<section className="container">
			<Row>
				<Col className="">
					<Row className="mt-5 mb-3 mb-lg-0 d-flex justify-content-center align-items-center">
						<Col
							xl={12}
							lg={12}
							md={12}
							sm={12}
							className="pb-3 pb-sm-3 pb-md-0 pb-lg-0 pb-xl-0 d-flex justify-content-between aling-items-center"
						>
							<p className="m-0 text-center text-sm-center text-md-left text-xl-left text-lg-left">
								<b>{paginate.total}</b> propriedade(s) encontrada(s)
							</p>
						</Col>
					</Row>
					<Row>{renderCards(paginate.data)}</Row>
					<Row>
						<Col>
							<ActionsTriggers>
								<Pagination
									onClick={event => {
										pageChanged(event);
									}}
								>
									{pagination}
								</Pagination>
							</ActionsTriggers>
						</Col>
					</Row>
				</Col>
			</Row>
		</section>
	);
}
