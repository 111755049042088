import api from '../services/api';
import { useState, useEffect, useCallback } from 'react';

export default function Fonts() {
	const[font, setFont] = useState('Montserrat');

	const getFont = useCallback(() => {
		api.get('api/manager/fonts')
				.then(response => {
					setFont(response.data);
				})
	}, []);

	useEffect(() => {
		getFont();
	}, []);

	console.log(font);

	return font;
};