import styled from 'styled-components';

export const SubtitleHeader = styled.h2.attrs(props => ({
	fontSize: props.fontSize || '1.0em',
	color: props.color || '#fff',
	letter: props.letter || '0em',
	weight: props.weight || '100',
	padding: props.padding || '0',
}))`
	font-size: ${props => props.fontSize};
	color: ${props => props.color};
	letter-spacing: ${props => props.letter};
	font-weight: ${props => props.weight};
	padding: ${props => props.padding};
	text-align: ${props => props.align};
`;
