import {
	SET_APARTMENTS,
	SET_FLATS,
	SET_HANGARS,
	SET_HOUSES,
	SET_STORES,
	SET_IMMOBILES_RESEARCH,
	SET_IMMOBILE_SHOW_DETAILS,
} from '../actions/immobiles';

const INITIAL_STATE = {
	hangars: [],
	stores: [],
	houses: [],
	apartments: [],
	flats: [],
	immobilesResearch: [],
	immobileShowDetails: {},
};

export default function immobiles(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_APARTMENTS:
			return {
				...state,
				apartments: action.payload,
			};
		case SET_FLATS:
			return {
				...state,
				flats: action.payload,
			};
		case SET_HANGARS:
			return {
				...state,
				hangars: action.payload,
			};
		case SET_STORES:
			return {
				...state,
				stores: action.payload,
			};
		case SET_HOUSES:
			return {
				...state,
				houses: action.payload,
			};
		case SET_IMMOBILES_RESEARCH:
			return {
				...state,
				immobilesResearch: action.payload,
			};
		case SET_IMMOBILE_SHOW_DETAILS:
			return {
				...state,
				immobileShowDetails: action.payload,
			};
		default:
			return state;
	}
}
