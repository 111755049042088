import React, { useState, useEffect } from 'react';

import { Row, Col, ButtonGroup, Button, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import getFieldValue from '../../../helpers/fields';
import { baseUrlOfGCS } from '../../../constants/urls';
import { constantUtil } from '../../../utils';
import { toastService } from '../../../services';

function Search(props) {
	const page = useSelector(state => state.page.home.informations);
	const { states = [] } = useSelector(state => state.states);
	const [businessEventType, setBusinessEventType] = useState('rent');
	const [type, setType] = useState({});
	const [stateId, setStateId] = useState(null);
	const [neighborhoodOrCode, setNeighborhoodOrCode] = useState('');
	const [homeSearchSectionTitle, setHomeSearchSectionTitle] = useState('');
	const [disableButton, setDisableButton] = useState(false);
	const [
		homeSearchSectionBackground,
		setHomeSearchSectionBackground,
	] = useState('');

	useEffect(() => {
		setHomeSearchSectionTitle(getFieldValue(page.fields, 6).content);
		setHomeSearchSectionBackground(getFieldValue(page.fields, 36).content);
	}, [page]);

	function search() {
		if (Object.keys(type).length > 0) {
			props.history.push(
				`/${type.nature}?construction_type=${type.value}${
					stateId ? '&state_id=' + stateId : ''
				}${
					neighborhoodOrCode
						? '&neighborhood_or_code=' + neighborhoodOrCode
						: ''
				}${
					businessEventType ? '&business_event_type=' + businessEventType : ''
				}`,
			);
		} else {
			toastService.showToast(
				constantUtil.toasts.error,
				'Você precisa informar, pelo menos, o tipo do imóvel.',
			);
		}
	}

	function setTypeOption(value) {
		const aux = constantUtil.buildingTypes.find(item => item.value === value);

		if (aux) {
			if (aux.nature === 'residenciais') {
				setDisableButton(false);
			} else {
				setDisableButton(true);
				setBusinessEventTypeOption('rent');
			}
		} else {
		}

		setType(aux ? aux : {});
	}

	function setStateIdOption(id) {
		const aux = states.find(item => parseInt(item.id) === parseInt(id));
		setStateId(aux ? aux.id : null);
	}

	function setBusinessEventTypeOption(value) {
		setBusinessEventType(value);
	}

	function setNeighborhoodOrCodeOption(value) {
		setNeighborhoodOrCode(value);
	}

	return (
		<section
			id="home-search"
			style={{
				backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%), url(${
					homeSearchSectionBackground &&
					(homeSearchSectionBackground.indexOf('http') > -1
						? homeSearchSectionBackground
						: baseUrlOfGCS + homeSearchSectionBackground)
				})`,
			}}
		>
			<div className="container-fluid">
				<Row className="py-5 py-sm-5 py-md-5 px-xl-0 p-lg-5 py-xl-5">
					<Col
						lg={12}
						md={12}
						sm={12}
						xl={12}
						className="p-0 p-sm-0 p-md-0 p-lg-0 py-xl-5"
					>
						<h1 className="title text-uppercase text-center m-0 pb-5">
							{ReactHtmlParser(homeSearchSectionTitle)}
						</h1>
						<Row className="adjust-row-business-event">
							<Col
								xl={3}
								lg={4}
								md={6}
								sm={12}
								className="d-flex justify-content-center align-items-center"
							>
								<Form.Group className="w-100">
									<ButtonGroup
										className="w-100"
										size="lg"
										aria-label="Tipo de negócio"
									>
										<Button
											disabled={disableButton}
											className={`w-50 text-uppercase bg-white btn-business-event ${
												businessEventType === 'sale' ? 'active' : ''
											}`}
											onClick={() => setBusinessEventTypeOption('sale')}
										>
											Comprar
										</Button>
										<Button
											className={`w-50 text-uppercase bg-white btn-business-event ${
												businessEventType === 'rent' ? 'active' : ''
											}`}
											onClick={() => setBusinessEventTypeOption('rent')}
										>
											ALUGAR
										</Button>
									</ButtonGroup>
								</Form.Group>
							</Col>
							<Col xl={3} lg={4} md={6} sm={12}>
								<Form.Group>
									<Form.Control
										as="select"
										size="lg"
										className="px-3 text-uppercase select-business-event"
										onChange={e => {
											setTypeOption(e.target.value);
										}}
										custom="true"
									>
										<option value="">Tipo de imóvel</option>
										{constantUtil.buildingTypes.map((type, index) => (
											<option key={index} value={type.value}>
												{type.label}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</Col>
							<Col xl={2} lg={4} md={6} sm={12}>
								<Form.Group>
									<Form.Control
										as="select"
										size="lg"
										className="px-3 text-uppercase select-business-event"
										onChange={e => {
											setStateIdOption(e.target.value);
										}}
										custom="true"
									>
										<option value="">Estado</option>
										{states.map((state, index) => (
											<option key={index} value={state.id}>
												{state.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</Col>
							<Col xl={2} lg={4} md={6} sm={12}>
								<Form.Group>
									<Form.Control
										type="text"
										size="lg"
										placeholder="Nome ou código..."
										className="input-business-event text-uppercase"
										name="neighborhoodOrCode"
										value={neighborhoodOrCode}
										onChange={e => setNeighborhoodOrCodeOption(e.target.value)}
									/>
								</Form.Group>
							</Col>
							<Col
								xl={2}
								lg={8}
								md={12}
								sm={12}
								className="d-flex justify-content-end align-items-center"
							>
								<Form.Group>
									<Button
										block
										onClick={search}
										size="lg"
										className="btn-search-business-event text-uppercase"
									>
										Buscar
									</Button>
								</Form.Group>
							</Col>
						</Row>
					</Col>
				</Row>
			</div>
		</section>
	);
}

export default withRouter(Search);
