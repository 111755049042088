export const SET_HOME_PAGE_INFORMATIONS = 'SET_HOME_PAGE_INFORMATIONS';
export const SET_HOME_PAGE_RANDOM_IMMOBILE = 'SET_HOME_PAGE_RANDOM_IMMOBILE';
export const SET_INSTITUCIONAL_PAGE_INFORMATIONS =
	'SET_INSTITUCIONAL_PAGE_INFORMATIONS';
export const SET_RESIDENCIAIS_PAGE_INFORMATIONS =
	'SET_RESIDENCIAIS_PAGE_INFORMATIONS';
export const SET_COMERCIAIS_PAGE_INFORMATIONS =
	'SET_COMERCIAIS_PAGE_INFORMATIONS';
export const SET_COMEDIC_PAGE_INFORMATIONS = 'SET_COMEDIC_PAGE_INFORMATIONS';
export const SET_MEDICAL_PAGE_INFORMATIONS = 'SET_MEDICAL_PAGE_INFORMATIONS';
export const SET_CONSTRUCTION_DETAILS_PAGE_INFORMATIONS =
	'SET_CONSTRUCTION_DETAILS_PAGE_INFORMATIONS';

export const setHomePageInformations = homePageInformations => ({
	type: SET_HOME_PAGE_INFORMATIONS,
	payload: homePageInformations,
});

export const setHomePageRandomImmobile = randomImmobile => ({
	type: SET_HOME_PAGE_RANDOM_IMMOBILE,
	payload: randomImmobile,
});

export const setInstitucionalPageInformations = institucionalPageInformations => ({
	type: SET_INSTITUCIONAL_PAGE_INFORMATIONS,
	payload: institucionalPageInformations,
});

export const setResidenciaisPageInformations = residenciaisPageInformations => ({
	type: SET_RESIDENCIAIS_PAGE_INFORMATIONS,
	payload: residenciaisPageInformations,
});

export const setComerciaisPageInformations = comerciaisPageInformations => ({
	type: SET_COMERCIAIS_PAGE_INFORMATIONS,
	payload: comerciaisPageInformations,
});

export const setComedicPageInformations = comedicPageInformations => ({
	type: SET_COMEDIC_PAGE_INFORMATIONS,
	payload: comedicPageInformations,
});

export const setMedicalPageInformations = medicalPageInformations => ({
	type: SET_MEDICAL_PAGE_INFORMATIONS,
	payload: medicalPageInformations,
});

export const setConstructionDetailsPageInformations = constructionDetailsPageInformations => ({
	type: SET_CONSTRUCTION_DETAILS_PAGE_INFORMATIONS,
	payload: constructionDetailsPageInformations,
});
