import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/template/Header';
import AdvancedSearch from '../../components/organisms/SearchBarDetailed';
import CardList from '../../components/organisms/CardList';
import Footer from '../../components/template/FooterGeneral';
import { Container } from './styles';
import api from '../../services/api';
import { SET_VARIABLES } from '../../store/actions/variables';
import { eventService } from '../../services';
import { constantUtil } from '../../utils';
import {
	SET_COMEDIC_PAGE_INFORMATIONS,
	SET_COMERCIAIS_PAGE_INFORMATIONS,
	SET_RESIDENCIAIS_PAGE_INFORMATIONS,
	SET_MEDICAL_PAGE_INFORMATIONS,
} from '../../store/actions/page';
import MetaTags from '../../components/atoms/Seo';
import { SET_STATES } from '../../store/actions/states';
import { SET_CITIES } from '../../store/actions/cities';

export default function PropertiesList(props) {
	const dispatch = useDispatch();
	const residentialPage = useSelector(
		state => state.page.residenciais.informations,
	);
	const commercialPage = useSelector(
		state => state.page.comerciais.informations,
	);
	const comedicPage = useSelector(state => state.page.comedic.informations);
	const medicalPage = useSelector(state => state.page.medical.informations);

	const [type, setType] = useState('');
	const [page, setPage] = useState(1);

	const trackingId = constantUtil.keys.googleAnalytics;

	const listenEvents = useCallback(() => {
		eventService.on(
			constantUtil.events.searchBarButtonClicked,
			({
				stateSearchOption,
				businessEventTypeSearchOption,
				constructionTypeSearchOption,
				minMonetaryValueSearchOption,
				maxMonetaryValueSearchOption,
				minBedroomSearchOption,
				maxBedroomSearchOption,
				minBathroomSearchOption,
				maxBathroomSearchOption,
				neighborhoodOrCodeSearchOption,
				citySearchOption,
				pathname,
			}) => {
				const search = {};

				if (stateSearchOption) {
					search.state_id = stateSearchOption;
				}
				if (businessEventTypeSearchOption) {
					search.business_event_type = businessEventTypeSearchOption;
				}
				if (constructionTypeSearchOption) {
					search.construction_type = constructionTypeSearchOption;
				}
				if (minMonetaryValueSearchOption) {
					search.min_price = minMonetaryValueSearchOption;
				}
				if (maxMonetaryValueSearchOption) {
					search.max_price = maxMonetaryValueSearchOption;
				}
				if (minBedroomSearchOption) {
					search.min_bedroom = minBedroomSearchOption;
				}
				if (maxBedroomSearchOption) {
					search.max_bedroom = maxBedroomSearchOption;
				}
				if (minBathroomSearchOption) {
					search.min_bathroom = minBathroomSearchOption;
				}
				if (maxBathroomSearchOption) {
					search.max_bathroom = maxBathroomSearchOption;
				}
				if (neighborhoodOrCodeSearchOption) {
					search.neighborhood_or_code = neighborhoodOrCodeSearchOption;
				}
				if (citySearchOption) {
					search.city = citySearchOption;
				}

				const queryString = new URLSearchParams(search);

				props.history.push(`${pathname}?${queryString}`);
			},
		);
		eventService.on(constantUtil.events.pageChanged, ({ page }) => {
			setPage(page);
		});
	}, [props.history]);

	const renderMetaTags = useCallback(() => {
		if (type === 'commercial') {
			return (
				<MetaTags
					description={commercialPage ? commercialPage.description : ''}
					keywords={commercialPage ? commercialPage.keywords : ''}
				/>
			);
		}
		if (type === 'residentials') {
			return (
				<MetaTags
					description={residentialPage ? residentialPage.description : ''}
					keywords={residentialPage ? residentialPage.keywords : ''}
				/>
			);
		}
		if (type === 'coworkings') {
			return (
				<MetaTags
					description={comedicPage ? comedicPage.description : ''}
					keywords={comedicPage ? comedicPage.keywords : ''}
				/>
			);
		}
		if (type === 'medicalCenter') {
			return (
				<MetaTags
					description={medicalPage ? medicalPage.description : ''}
					keywords={medicalPage ? medicalPage.keywords : ''}
				/>
			);
		}
	},[
		comedicPage.description,
		comedicPage.keywords,
		commercialPage.description,
		commercialPage.keywords,
		medicalPage.description,
		medicalPage.keywords,
		residentialPage.description,
		residentialPage.keywords,
		type,
	]);

	const getVariables = useCallback(() => {
		return api.get('api/manager/variables');
	}, []);

	const getStates = useCallback(() => {
		return api.get('api/states');
	}, []);

	const getCities = useCallback(state_id => {
		return api.get(
			`api/website/cities${state_id ? '?state_id=' + state_id : ''}`,
		);
	}, []);

	const getPageData = useCallback(() => {
		return api.get(`api/manager${props.location.pathname}`);
	}, [props.location.pathname]);

	const getData = useCallback(() => {
		return api.get(
			`api/constructions?type=${type}&page=${page}${
				props.location.search ? '&' + props.location.search.slice(1) : ''
			}`,
		);
	}, [page, props.location.search, type]);

	const getAllData = useCallback(() => {
		getVariables().then(response => {
			if (response.status === 200) {
				dispatch({
					type: SET_VARIABLES,
					payload: response.data,
				});
			}
		});
		getPageData().then(response => {
			if (response.status === 200) {
				if (props.location.pathname === '/residenciais') {
					dispatch({
						type: SET_RESIDENCIAIS_PAGE_INFORMATIONS,
						payload: response.data.page,
					});
				}
				if (props.location.pathname === '/comerciais') {
					dispatch({
						type: SET_COMERCIAIS_PAGE_INFORMATIONS,
						payload: response.data.page,
					});
				}
				if (props.location.pathname === '/coworkings') {
					dispatch({
						type: SET_COMEDIC_PAGE_INFORMATIONS,
						payload: response.data.page,
					});
				}
				if (props.location.pathname === '/clinicas-medicas') {
					dispatch({
						type: SET_MEDICAL_PAGE_INFORMATIONS,
						payload: response.data.page,
					});
				}
			}
		});
		getStates().then(response => {
			if (response.status === 200) {
				dispatch({
					type: SET_STATES,
					payload: response.data,
				});
			}
		});

		if (props.match.params.type !== 'residenciais') {
			getCities().then(response => {
				if (response.status === 200) {
					dispatch({
						type: SET_CITIES,
						payload: response.data,
					});
				}
			});
		}
	}, [
		dispatch,
		getCities,
		getData,
		getPageData,
		getStates,
		getVariables,
		props.location.pathname,
		props.match.params.type,
		type,
	]);

	useEffect(() => {
		listenEvents();
	}, [listenEvents]);

	useEffect(() => {
		const currentPath = props.location.pathname;

		window.gtag('config', trackingId, { page_path: currentPath });

		setPage(1);

		if (currentPath === '/residenciais') {
			setType('residentials');
		}
		if (currentPath === '/comerciais') {
			setType('commercial');
		}
		if (currentPath === '/coworkings') {
			setType('coworkings');
		}
		if (currentPath === '/clinicas-medicas') {
			setType('medicalCenter');
		}
	}, [props.location.pathname]);

	useEffect(() => {
		getAllData();
	}, [type]);

	useEffect(() => {
		getAllData();
	}, [props.location.search]);

	useEffect(() => {
		if (type) {
			getData().then(res => {
				if (res.status === 200) {
					eventService.emit(constantUtil.events.paginateUpdated, res.data);
				}
			});
		}
	}, [page, type]);

	return (
		<Container>
			{renderMetaTags()}
			<Header />
			<AdvancedSearch />
			<CardList />
			<Footer />
		</Container>
	);
}
