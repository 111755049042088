import styled from 'styled-components';
import People from '../../assets/images/static/people.png';
import Wrapper from '../../assets/svg/red-wrapper.svg';
import Image from '../../assets/images/static/photo-one.png';
import { InputGroup, Button } from 'react-bootstrap';
import BackgroundGreen from '../../assets/images/static/bg-green.png';

export const Container = styled.div`
	width: 100%;
`;

export const ContainerMargin = styled.div`
	padding-right: 9vw;
	padding-left: 9vw;

	@media (max-width: 768px) {
		height: 100%;
	}
`;

export const ImageWrapper = styled.div`
	position: relative;
`;

export const AbsoluteWrapper = styled.div`
	position: absolute;
	background-color: #0007;
	width: 90%;
	height: auto;
	bottom: 68px;
	color: #fff;
	font-size: 20px;

	@media (max-width: 768px) {
		width: 100%;
		height: auto;
		bottom: 0;
	}

	@media (max-width: 1219px) {
		width: 100%;
		height: auto;
		bottom: 0;
		padding-top: 20px;
		padding-bottom: 20px;
	}
`;

export const ProductTitle = styled.h1`
	font-family: 'Montserrat', sans-serif;
	font-size: 40px;

	@media (max-width: 768px) {
		font-size: 40px;
	}
`;

export const ProductSubtitle = styled.h2`
	font-family: 'Brandon Grotesque Medium' !important;
	font-size: 16px;
	text-align: left;
	font-weight: 200;

	@media (max-width: 768px) {
		font-size: 14px;
	}
`;

export const DetailWrapper = styled.div`
	position: absolute;
	right: 0;
	top: -100px;
	background-color: #ccb28b;
	height: auto;
	width: 300px;
	padding: 27px 29px 10px 29px;
	z-index: 1;

	@media (max-width: 1199px) {
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		width: 100%;
		top: 0px;
		margin-top: 20px;
	}
`;

export const TitleDetails = styled.h2`
	font-size: 30px;
	color: #fff;
	font-weight: 100;
`;

export const UnorderedList = styled.ul`
	list-style: none;
	margin-top: 1rem!important;
    margin-bottom: 0rem!important;
`;

export const UnorderedItem = styled.li`
	font-family: 'Montserrat regular', sans-serif !important;
	line-height: 2.4;
	font-weight: lighter;
	color: #fff;

	@media (max-width: 1199px) {
		font-size: 20px;
	}
`;

export const UnorderedItemInfo = styled(UnorderedItem)`
	font-family: 'Montserrat regular', sans-serif !important;
	line-height: 1.5;
	font-weight: lighter;
	opacity: 0.8;
	color: #fff;
`;

export const WrapperSpanIcon = styled.div`
	display: flex;
	flex-direction: column;
	align-self: center;
	width: 100%;
`;

export const SpanIcon = styled.span`
	font-family: 'Brandon Grotesque Medium' !important;
	font-size: 14px;
	position: relative;
	top: 15px;
	padding-top: 5px;
	color: #7f7f7f;
	text-align: center;
	font-weight: ${props => props.weight || 'normal'};
`;

export const TitleSection = styled.h2`
	font-size: 30px;
	color: ${props => props.color || '#7F7F7F'};
	font-weight: ${props => props.weight || 'bold'};

	@media (max-width: 768px) {
		font-size: 30px;
	}
`;

export const CardTitle = styled.h2`
	font-size: 14px;
	font-weight: bold;
	color: #7f7f7f;
`;

export const ParagraphProduct = styled.p`
	font-size: ${props => props.size + 'px' || '14px'};
	color: ${props => props.color || '#7F7F7F'};
	text-align: ${props => props.align || 'start'};
	font-weight: ${props => props.weight || 'light'};
	line-height: 18px;
`;

export const ParagraphProductWrapper = styled.div`
	font-size: ${props => props.size + 'px' || '14px'};
	color: ${props => props.color || '#7F7F7F'};
	padding-top: 8px;
	text-align: ${props => props.align || 'start'};
	font-weight: ${props => props.weight || 'light'};
`;

export const RoundedTitle = styled(TitleSection)`
	font-family: 'Montserrat bold', sans-serif !important;
	font-size: 12px;
	font-weight: bolder;
	text-transform: uppercase;

	@media (max-width: 768px) {
		font-size: 14px;
	}
`;

export const RoundedParagraph = styled(ParagraphProduct)`
	font-size: 12px;
`;

export const DivBackground = styled.div`
	background-image: url(${People});
	background-size: cover;
	position: relative;
	width: 100%;
	height: auto;
	background-repeat: no-repeat;

	::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: #000;
		opacity: 0.4;
	}
`;

export const GreenBackground = styled.div`
	background-image: url(${BackgroundGreen});
	background-size: cover;
	position: relative;
	width: 100%;
	max-width: 240px;
	height: auto;
	background-repeat: no-repeat;
	padding: 20px;

	@media (min-width: 768px) and (max-width: 992px) {
		width: 400px;
	}
`;

export const WhiteBackground = styled.div`
	background-color: #fff;
	height: auto;
	margin: 0 auto;
	text-align: center;
`;

export const BackgroundImage = styled.div`
	background-image: url(${Image});
	background-size: cover;
	position: relative;
	width: 100%;
	height: 299px;
	background-repeat: no-repeat;

	::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: #a40e0e;
		opacity: 0.4;
	}
`;

export const SvgBackground = styled.div`
	background-image: url(${Wrapper});
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	width: 100%;
	height: 100%;
`;

export const FormTitle = styled(TitleSection)`
	color: #fff;
	font-size: 40px;
`;

export const TechnicalTitle = styled(FormTitle)`
	font-size: 28px;
	font-weight: normal;
`;

export const StyledFormControl = styled(InputGroup)`
	opacity: 0.8;
	font-size: 14px;
`;

export const CustomButton = styled(Button)`
	background-color: ${props => props.color || '#2B5D88'} !important;
	outline: 0;
	border: none;
	width: 200px;
	padding: 10px;
	color: white;
	border-radius: 5px;
	text-transform: uppercase;

	:hover {
		background-color: ${props => props.hover || '#3777AF'} !important;
		border: none;
	}
	:active {
		background-color: ${props => props.active || '#3777AF'} !important;
		border: none;
	}
`;

export const TechnicalInfo = styled.div`
	background-color: #a40e0e;
	height: auto;
	padding: 30px;
	width: ${props => props.size || '100%'};

	@media (max-width: 1199px) {
		width: 100%;
	}
`;

export const LabelInfo = styled.p`
	color: ${props => props.color || 'rgba(255,255,255,0.5)'};
	margin: 0;
	font-size: ${props => props.size + 'px' || '15px'};
`;

export const TextInfo = styled.p`
	color: ${props => props.color || '#fff'};
	margin: 0;
	font-size: 20px;
`;

export const MediaTitle = styled.h5`
	color: ${props => props.color || '#7F7F7F'};
	font-weight: ${props => props.weight || 'bold'};
	font-size: ${props => props.size + 'px' || '14px'};
`;

export const WrapperWhite = styled.div`
	border-radius: 50%;
	background-color: #fff;
	z-index: 1;
	padding: 10px;
	text-align: center;
	width: 50px;
	height: auto;
`;

export const GridContainer = styled.div`
	display: grid;
`;

export const ErrorParagraph = styled.p`
	color: #fff;
	font-size: 16px;
`;
