import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import { Container } from './styles';
import Pin from '../../atoms/Pin';
import { constantUtil } from '../../../utils';

export default function Map() {
	const page = useSelector(state => state.page.home.informations);
	const [buildings, setBuildings] = useState([]);

	useEffect(() => {
		if (page.houses || page.apartments || page.hangars || page.stores) {
			setBuildings([
				...page.houses,
				...page.apartments,
				...page.hangars,
				...page.stores,
			]);
		}
	}, [page]);

	function renderPins() {
		return buildings
			.map((building, index) => {
				if (building.address.latitude && building.address.longitude) {
					return (
						<Pin
							key={index}
							lat={parseFloat(building.address.latitude)}
							lng={parseFloat(building.address.longitude)}
						/>
					);
				} else {
					return null;
				}
			})
			.filter(value => value);
	}

	function renderMap() {
		return (
			<GoogleMapReact
				bootstrapURLKeys={{ key: constantUtil.keys.googleMaps }}
				center={constantUtil.latLng}
				defaultZoom={18}
				yesIWantToUseGoogleMapApiInternals
				key={constantUtil.keys.googleMaps}
				options={{
					streetViewControl: true,
					disableDefaultUI: false,
					mapTypeControl: true,
					styles: [
						{
							featureType: 'poi',
							elementType: 'labels',
							stylers: [{ visibility: 'on' }],
						},
					],
				}}
			>
				{renderPins()}
			</GoogleMapReact>
		);
	}

	return <Container>{renderMap()}</Container>;
}
