import styled from 'styled-components';
import Devices from '../../../utils/devices';

export const Container = styled.div`
	height: auto;
	max-width: 100%;
	height: auto;
	background-color: #75282a;
	font-family: 'Brandon Grotesque Light';

	section {
		letter-spacing: 2.1px;
	}
`;

export const Describe = styled.div`
	background-color: #bba57d;
	width: 100%;
	height: 100px;

	.desctiption__paragraph {
		color: #fff;
		font-size: 1rem;
		letter-spacing: 0.1rem;
		line-height: 1.3rem;
		font-weight: lighter;

		@media ${Devices.laptop} {
			margin-left: 330px;
			margin-top: 35px;
		}
	}
`;

export const ImageWrapper = styled.img`
	@media ${Devices.laptop} {
		position: absolute;
		width: 350px;
		height: auto;
		z-index: 1;
		top: 1678px;
	}

	@media ${Devices.desktopL} {
		position: absolute;
		width: 350px;
		height: auto;
		z-index: 1;
		top: 1990px;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: row;
`;

export const HeaderPadding = styled.div`
	padding-top: 30px;
`;

export const HeaderCenter = styled.div`
	@media ${Devices.mobileS} {
		text-align: center;
	}

	@media ${Devices.tablet} {
		text-align: center;
	}

	@media ${Devices.laptop} {
		text-align: left;
	}

	@media ${Devices.laptopL} {
		text-align: left;
	}
`;

export const ContactForms = styled.div`
	color: #ffffff;
	width: 100%;

	@media ${Devices.mobileS} {
		.title {
			font-weight: bold;
			font-size: 1.3em;
			letter-spacing: 0.1em;
		}
	}

	@media ${Devices.tablet} {
		.title {
			font-weight: bold;
			font-size: 1.3em;
			letter-spacing: 0.1em;
		}
	}

	@media ${Devices.laptop} {
		.title {
			font-weight: bold;
			font-size: 2.4em;
			letter-spacing: 0.1em;
			text-align: left;
		}
	}

	@media ${Devices.laptopL} {
		.title {
			font-weight: bold;
			font-size: 2.4em;
			letter-spacing: 0.1em;
			text-align: left;
		}
	}

	.subtitle {
		font-weight: bolder;
		font-size: 1.8em;
		padding-bottom: 15px;
		letter-spacing: 0.2em;
	}
	.border-bot {
		border-bottom: 1px solid #fff;
	}
`;

export const SubtitleWrapper = styled.div`
	@media ${Devices.mobileS} {
		display: flex;
		justify-content: center;
		padding-right: 290px;
	}

	@media ${Devices.tablet} {
		padding-right: 120px;
	}

	@media ${Devices.laptop} {
		padding-right: 100px;
	}

	@media ${Devices.laptopL} {
		padding: 0px;
		display: flex;
		justify-content: start;
	}
`;

export const Paragraph = styled.p`
	@media ${Devices.mobileS} {
		font-size: 12px;
	}

	@media ${Devices.tablet} {
		font-size: 14px;
	}

	@media ${Devices.laptop} {
		font-size: 16px;
	}

	@media ${Devices.laptopL} {
		font-size: 18px;
	}
`;

export const Whatsapp = styled.div`
	font-size: 14px;
	margin: 15px;

	text-decoration: none;

	.span__subtitle {
		text-align: center;
		letter-spacing: 1.8px;
		font-size: 1.1em;
	}
	.icon__wrap {
		width: 45px;
		margin-bottom: 20px;
		height: auto;
		align-self: center;
	}
	a {
		color: #fff;
		margin-top: 10px;
		text-align: center;
		text-decoration: none;
	}
`;

export const OnlineConsultant = styled.div`
	margin: 15px;
	font-size: 14px;

	.span__subtitle {
		text-align: center;
		letter-spacing: 1.8px;
		font-size: 1.1em;
	}
	.icon__wrap {
		width: 30px;
		height: 35px;
		margin-top: 15px;
		align-self: center;
	}

	button {
		color: #fff;
		margin-top: 10px;
		text-align: center;
	}
	button:hover {
		color: #fff;
	}
`;

export const Attendance = styled.div`
	margin: 15px;
	font-size: 14px;

	.span__subtitle {
		text-align: center;
		letter-spacing: 1.8px;
		font-size: 1.1em;
	}
	.icon__wrap {
		width: 35px;
		height: 35px;
		align-self: center;
	}
	button {
		color: #fff;
		margin-top: 10px;
		text-align: center;
	}
	button:hover {
		color: #fff;
	}
`;

export const Separator = styled.div`
	height: 0.5px;
	width: 100px;
	background-color: #ffffff;
`;

export const Catchphrase = styled.div`
	display: flex;
	justify-content: space-around;
	margin-top: 50px;
`;

export const TabTextContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 30px;
	margin: 0 auto;
	justify-content: space-between;
	align-items: center;
`;

export const FormActions = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
`;

export const PaddingContainer = styled.div`
	@media ${Devices.mobileM} {
		padding: 20px;
	}

	@media ${Devices.laptop} {
		padding-left: 350px;
	}
`;
