import styled from 'styled-components';
import devices from '../../../utils/devices';

export const Container = styled.div``;

export const Wrapper = styled.div`
	background-color: #7D0004;

	 /* @media ${devices.mobileS} {
	 	padding: 30px;
	 	margin: 10px;
	 	position: relative;
	 	bottom: 500px;
	 }

	 @media ${devices.tablet} {
	 	padding: 70px;
	 	position: relative;
	 	left: 0;
	 	top: 150px;
	 	z-index: 2;
	 }

	 @media ${devices.laptop} {
	 	width: 600px;
	 	padding: 90px;
	 	position: relative;
	 	z-index: 2;
	 	left: -30px;
	 }

	 @media ${devices.laptopL} {
	 	width: 600px;
	 	padding: 90px;
	 	position: relative;
	 	z-index: 2;
	 	left: 0;
	 }

	 @media ${devices.desktop} {
	 	width: 800px;
	 	padding: 90px;
	 	position: relative;
	 	left: 0;
	 	right: 300px;
	 	top: 190px;
	 	z-index: 2;
	 } */
`;

export const Image = styled.img``;

export const Title = styled.h3`
	color: #ffffff;
	letter-spacing: 6.3px;
	line-height: 2.5rem;
	text-transform: uppercase;
	padding-bottom: 2rem;
`;

export const Description = styled.div.attrs(props => ({
	transform: props.transform || 'initial',
	fontSize: props.fontSize || '0.9rem',
}))`
	font-family: 'Montserrat', sans-serif;
	color: #fff;
	letter-spacing: 1.8px;
	line-height: 1.5rem;
	font-size: ${props => props.fontSize};
	font-weight: lighter;
	text-align: justify;

	text-transform: ${props => props.transform};
`;

export const LineField = styled.div`
	display: flex;

	@media ${devices.mobileS} {
		flex-direction: column;
	}

	@media ${devices.tablet} {
		flex-direction: column;
	}

	@media ${devices.laptop} {
		flex-direction: row;
		justify-content: space-between;
		padding-bottom: 10px;
	}
`;

export const ColumnField = styled.div`
	display: flex;

	@media ${devices.mobileS} {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	@media ${devices.tablet} {
		flex-direction: column;
		align-items: center;
		width: 100%;
	}

	@media ${devices.laptop} {
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		margin-right: 5px;
		width: 100%;
	}
`;

export const InputField = styled.input.attrs(props => ({
	size: props.size || '50%',
	height: props.height || 'auto',
	margin: props.margin || '0.5em',
	padding: props.padding || '1rem',
	rows: props.rows || '100',
	cols: props.cols || '100',
}))`
	background-image: none;
	background-color: transparent;
	border: 1px solid #fff;
	color: #ddd;
	letter-spacing: 0.1rem;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	width: ${props => props.size};
	height: ${props => props.height};
	padding: ${props => props.padding};
	margin: ${props => props.margin};
	-webkit-transition: 0.5s;
	transition: 0.5s ease-out;
	outline: none;

	::placeholder {
		color: #ffffff;

		letter-spacing: 0.1rem;
		/* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #ffffff;

		letter-spacing: 0.1rem;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: #ffffff;

		letter-spacing: 0.1rem;
	}

	:focus {
		border: 1px solid #fff;
		background-color: #ddd8;
		color: white;
	}
`;

export const Message = styled.textarea`
	background-image: none;
	background-color: transparent;
	-webkit-transition: 0.5s;
	transition: 0.5s ease-out;
	outline: none;
	border: 1px solid #fff;
	color: #ddd;
	letter-spacing: 0.1rem;
	padding: 1rem;
	resize: none;

	&::placeholder {
		color: #ffffff;

		letter-spacing: 0.1rem;
		/* Firefox */
	}

	&:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #ffffff;

		letter-spacing: 0.1rem;
	}

	&:-ms-input-placeholder {
		/* Microsoft Edge */
		color: #ffffff;

		letter-spacing: 0.1rem;
	}

	:focus {
		border: 1px solid #fff;
		background-color: #ddd8;
		color: white;
	}
`;
export const Phone = styled(InputField)``;

export const Email = styled(InputField)``;

export const LineBox = styled.div`
	position: absolute;
	opacity: 0;
	height: 0;
	width: 0;
`;

export const Check = styled.input`
	position: absolute;
	height: 25px;
	width: 25px;
	top: 0;
	left: 0;
	background-color: #ff6100;
`;

export const LabelWrapper = styled.label``;

export const SpanTitle = styled.span`
	font-weight: lighter;
	font-size: 0.8em;
	margin-left: 10px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	color: #fff;
`;

export const ButtonSubmit = styled.button`
	background-color: #b88e4b;
	border: none;
	border-radius: 5px;
	color: #fff;
	text-transform: uppercase;
	font-weight: lighter;
	letter-spacing: 0.1em;
	font-size: 1.3em;
	outline: none;
	width: 100%;
	padding: 1rem;

	:active {
		background-color: #b88e4bcc;
		border: 1px solid #000;
	}

	:hover {
		background-color: #ff6;
		color: #000;
		font-weight: bold;
	}
`;

export const Icon = styled.svg`
	fill: none;
	stroke: #000;
	stroke-width: 2px;
`;

export const CheckboxContainer = styled.div`
	display: inline-block;
	vertical-align: middle;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	// Hide checkbox visually but remain accessible to screen readers.
	// Source: https://polished.js.org/docs/#hidevisually
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

export const StyledCheckbox = styled.div`
	display: inline-block;
	width: 32px;
	height: 32px;
	background: ${props => (props.checked ? '#ddd' : '#7D0004')};
	border-radius: 1px;
	border: 1px solid #fff;

	transition: all 150ms;

	${HiddenCheckbox}:focus + & {
		box-shadow: 0 0 0 2px #fff;
	}

	${Icon} {
		visibility: ${props => (props.checked ? 'visible' : 'hidden')};
	}
`;

export const ErrorStyled = styled.label`
	color: #b88e4b;
	font-weight: bold;
	font-size: small;
	letter-spacing: 0.1em;
	width: 100%;
`;
