import styled from 'styled-components';

export const TitleHeader = styled.h1.attrs(props => ({
	fontSize: props.fontSize || '1.4em',
	color: props.color || '#fff',
	letter: props.letter || '0',
	weight: props.weight || '100',
	padding: props.padding || '0',
	margin: props.margin || '0',
}))`
	font-size: ${props => props.fontSize};
	color: ${props => props.color};
	letter-spacing: ${props => props.letter};
	font-weight: ${props => props.weight};
	padding: ${props => props.padding};
	margin: ${props => props.margin};
	text-align: ${props => props.align};
	text-transform: ${props => props.transform};
	font-family: ${props => props.family};
`;
