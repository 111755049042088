const events = {
	searchBarButtonClicked: "SEARCH_BAR_BUTTON_CLICKED",
	paginateUpdated: "PAGINATE_UPDATED",
	pageChanged: "PAGE_CHANGED",
	stateUpdated: "STATE_UPDATED",
};
const keys = {
	googleMaps: "AIzaSyDd3h6tQgwcxog8YB4bj__vBBQ0OD9Gv4E",
	googleAnalytics: "G-32Y5GZWBRE",
};
const buildingTypes = [
	{
		value: "apartment",
		label: "APARTAMENTO",
		nature: "residenciais",
	},
	{
		value: "house",
		label: "CASA",
		nature: "residenciais",
	},
	{
		value: "coworking",
		label: "COWORKING",
		nature: "coworkings",
	},
	{
		value: "medicCoworking",
		label: "COWORKING MÉDICO",
		nature: "coworkings",
	},
	{
		value: "medicBusiness",
		label: "EMPRESARIAL MÉDICO",
		nature: "coworkings",
	},
	{
		value: "flat",
		label: "FLAT",
		nature: "residenciais",
	},
	{
		value: "hangar",
		label: "GALPÃO",
		nature: "comerciais",
	},
	{
		value: "hub",
		label: "HUB EMPRESARIAL",
		nature: "comerciais",
	},
	{
		value: "stores",
		label: "LOJA",
		nature: "comerciais",
	},
];
const toasts = {
	success: "success",
	error: "error",
};
const latLng = {
	lat: -7.953246,
	lng: -36.206769,
};
const urls = {
	softmakers: "https://www.softmakers.com.br/",
	// manager: "https://gerenciador.mmestre.com.br",
	manager: 'http://127.0.0.1:8000/api/',
	whatsAppApiLink: "https://api.whatsapp.com/send?phone=5581997462048",
	InstagramApiLink: "https://www.instagram.com/mmestreempreendimentos/"
};
const monetaryValues = [
	{
		value: "0:500",
		label: "R$ 0,00 > R$ 500,00",
	},
	{
		value: "500:1000",
		label: "R$ 500,00 > R$ 1.000,00",
	},
	{
		value: "1000:1500",
		label: "R$ 1.000,00 > R$ 1.500,00",
	},
	{
		value: "1500:2000",
		label: "R$ 1.500,00 > R$ 2.000,00",
	},
	{
		value: "2000:>",
		label: "Maior que R$ 2.000,00",
	},
];
const bedroomsQuantity = [
	{
		value: "1:1",
		label: "1 quarto",
	},
	{
		value: "2:2",
		label: "2 quartos",
	},
	{
		value: "3:3",
		label: "3 quartos",
	},
	{
		value: "4:4",
		label: "4 quartos",
	},
	{
		value: "4:>",
		label: "Mais que 4 quartos",
	},
];
const bathroomsQuantity = [
	{
		value: "1:1",
		label: "1 banheiro",
	},
	{
		value: "2:2",
		label: "2 banheiros",
	},
	{
		value: "3:3",
		label: "3 banheiros",
	},
	{
		value: "4:4",
		label: "4 banheiros",
	},
	{
		value: "4:>",
		label: "Mais que 4 banheiros",
	},
];
const businessEventTypes = [
	{ value: "rent", label: "ALUGAR" },
	{ value: "sale", label: "COMPRAR" },
];
const masks = {
	phone10: [
		"(",
		/[1-9]/,
		/\d/,
		")",
		" ",
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		"-",
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	],
	phone11: [
		"(",
		/[1-9]/,
		/\d/,
		")",
		" ",
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		/\d/,
		"-",
		/\d/,
		/\d/,
		/\d/,
		/\d/,
	],
};

export default {
	masks,
	events,
	buildingTypes,
	toasts,
	keys,
	latLng,
	urls,
	monetaryValues,
	bedroomsQuantity,
	bathroomsQuantity,
	businessEventTypes,
};
