import { constantUtil } from '../utils';

function phoneMask(userInput) {
	const numbers = userInput.match(/\d/g);
	const total = numbers ? numbers.join('').length : 0;

	return total > 10 ? constantUtil.masks.phone11 : constantUtil.masks.phone10;
}

export default {
	phoneMask,
};
