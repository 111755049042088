import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from './styles';
import LargeLogo from '../../assets/images/logo_color_responsive/logo_mmestre_color_w_362.png';
import Banner from '../../components/molecules/BannerProduct';
import Footer from '../../components/template/FooterGeneral';
import Header from '../../components/template/Header';
import InstitucionalInfo from '../../components/molecules/InstitucionalInfo';
import api from '../../services/api';
import { SET_INSTITUCIONAL_PAGE_INFORMATIONS } from '../../store/actions/page';
import MetaTags from '../../components/atoms/Seo';
import { SET_VARIABLES } from '../../store/actions/variables';
import { useHistory } from 'react-router-dom';
import { constantUtil } from '../../utils';

export default function Institucional(props) {
	const dispatch = useDispatch();
	const page = useSelector(state => state.page.institucional.informations);

	const { listen } = useHistory();
	const trackingId = constantUtil.keys.googleAnalytics;

	useEffect(() => {
		window.gtag('config', trackingId, { page_path: props.location.pathname });
		getPageInformations().then(response => {
			if (response.status === 200) {
				dispatch({
					type: SET_INSTITUCIONAL_PAGE_INFORMATIONS,
					payload: response.data.page,
				});
			}
		});

		getVariablesFromApi().then(response => {
			if (response.status === 200) {
				dispatch({
					type: SET_VARIABLES,
					payload: response.data,
				});
			}
		});
	}, [dispatch]);

	function getPageInformations() {
		return api.get('api/manager/institucional');
	}

	function getVariablesFromApi() {
		return api.get('api/manager/variables');
	}

	function renderMetaTags() {
		return <MetaTags description={page.description} keywords={page.keywords} />;
	}

	return (
		<Container>
			{renderMetaTags()}
			<Header />
			<Banner logo={LargeLogo} />
			<InstitucionalInfo />
			<Footer />
		</Container>
	);
}
