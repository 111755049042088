import React, { useEffect, useState, useCallback } from 'react';
import ContentLoader from 'react-content-loader';

import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

import { Wrapper } from './styles';
import Title from '../../atoms/Title';
import Subtitle from '../../atoms/Subtitle';
import getFieldValue from '../../../helpers/fields';
import { baseUrlOfGCS } from '../../../constants/urls';

export default function InstitucionalInfo() {
	const institutional = useSelector(
		state => state.page.institucional.informations,
	);

	const [pageTitle, setPageTitle] = useState('');
	const [pageContent, setPageContent] = useState('');
	const [pageFirstImage, setPageFirstImage] = useState('');
	const [pageSecondImage, setPageSecondImage] = useState('');
	const [pageYearsMetric, setPageYearsMetric] = useState('');
	const [pageBusinessMetric, setPageBusinessMetric] = useState('');
	const [pageBuildingsMetric, setPageBuildingsMetric] = useState('');
	const [pageUnitiesMetric, setPageUnitiesMetric] = useState('');
	const [loading, setLoading] = useState(false);

	const setInitialLoading = useCallback(bool => {
		setLoading(bool);
	}, []);

	useEffect(() => {
		try {
			setInitialLoading(true);
			try {
				if (institutional.title) {
					setPageTitle(institutional.title);
				}
			} catch (e) {}

			try {
				const pageContent = getFieldValue(institutional.fields, 26).content;
				if (pageContent) {
					setPageContent(pageContent);
				}
			} catch (e) {}

			try {
				const pageFirstImage = getFieldValue(institutional.fields, 24).content;
				if (pageFirstImage) {
					setPageFirstImage(pageFirstImage);
				}
			} catch (e) {}

			try {
				const pageSecondImage = getFieldValue(institutional.fields, 23).content;
				if (pageSecondImage) {
					setPageSecondImage(pageSecondImage);
				}
			} catch (e) {}

			try {
				const pageYearsMetric = getFieldValue(institutional.fields, 27).content;
				if (pageYearsMetric) {
					setPageYearsMetric(pageYearsMetric);
				}
			} catch (e) {}

			try {
				const pageUnitiesMetric = getFieldValue(institutional.fields, 28)
					.content;
				if (pageUnitiesMetric) {
					setPageUnitiesMetric(pageUnitiesMetric);
				}
			} catch (e) {}

			try {
				const pageBuildingsMetric = getFieldValue(institutional.fields, 29)
					.content;
				if (pageBuildingsMetric) {
					setPageBuildingsMetric(pageBuildingsMetric);
				}
			} catch (e) {}

			try {
				const pageBusinessMetric = getFieldValue(institutional.fields, 30)
					.content;
				if (pageBusinessMetric) {
					setPageBusinessMetric(pageBusinessMetric);
				}
			} catch (e) {}
		} catch {
		} finally {
			setTimeout(() => setInitialLoading(false), 1500);
		}
	}, [institutional, setInitialLoading]);

	return (
		<Wrapper id="institutional-content">
			<Container>
				<Row>
					<Col lg="12" md="12" sm="12">
						<Row className="py-5">
							<Col lg="12" md="12" sm="12">
								{loading ? (
									<ContentLoader
										width="100%"
										speed={1}
										viewBox="0 0 400 20"
										style={{ maxWidth: '100%' }}
									>
										<rect x="0" y="0" rx="3" ry="3" width="50" height="6" />
										<rect x="0" y="13" rx="4" ry="4" width="100" height="6" />
									</ContentLoader>
								) : (
									<Title
										txt={ReactHtmlParser(pageTitle)}
										color="#000"
										fontSize="2em"
										weight="bold"
									/>
								)}
							</Col>
						</Row>
						<Row>
							<Col
								lg="6"
								md="12"
								sm="12"
								className="pb-5 pb-sm-5 pb-md-5 pb-lg-0 pb-xl-0"
							>
								{loading ? (
									<ContentLoader
										width="100%"
										speed={1}
										viewBox="0 0 400 475"
										style={{ maxWidth: '100%' }}
									>
										<rect x="0" y="0" rx="3" ry="3" width="200" height="9" />
										<rect x="0" y="13" rx="4" ry="4" width="100" height="9" />
										<rect x="0" y="29" rx="4" ry="4" width="50" height="8" />
										<rect x="0" y="50" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="65" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="79" rx="4" ry="4" width="500" height="10" />
										<rect x="0" y="93" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="107" rx="4" ry="4" width="200" height="10" />
										<rect x="0" y="123" rx="4" ry="4" width="600" height="9" />
										<rect x="0" y="139" rx="4" ry="4" width="500" height="8" />
										<rect x="0" y="155" rx="4" ry="4" width="400" height="10" />
										<rect x="0" y="171" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="187" rx="4" ry="4" width="500" height="10" />
										<rect x="0" y="203" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="220" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="240" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="260" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="280" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="300" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="320" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="340" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="360" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="380" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="600" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="420" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="440" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="460" rx="4" ry="4" width="600" height="10" />
										<rect x="0" y="480" rx="4" ry="4" width="600" height="10" />
									</ContentLoader>
								) : (
									ReactHtmlParser(pageContent)
								)}
							</Col>
							<Col lg="6" md="12" sm="12" className="text-center">
								{loading ? (
									<ContentLoader
										speed={1}
										height="auto"
										viewBox="0 0 400 475"
										backgroundColor="#f3f3f3"
										foregroundColor="#ecebeb"
										style={{ maxWidth: '100%' }}
									>
										<rect
											x="100"
											y="0"
											rx="2"
											ry="2"
											width="200"
											height="200"
										/>
										<rect
											x="100"
											y="218"
											rx="2"
											ry="2"
											width="200"
											height="200"
										/>
									</ContentLoader>
								) : (
									<>
										<Image
											className="pb-5"
											src={
												pageFirstImage.indexOf('http') > -1
													? pageFirstImage
													: baseUrlOfGCS + pageFirstImage
											}
											fluid
											alt="Imagem principal"
										/>
										<Image
											className="pt-5"
											src={
												pageSecondImage.indexOf('http') > -1
													? pageSecondImage
													: baseUrlOfGCS + pageSecondImage
											}
											fluid
											alt="Imagem Secundária"
										/>
									</>
								)}
							</Col>
						</Row>
						<Row className="py-5">
							<Col
								xl={3}
								lg={3}
								md={6}
								sm={12}
								className="text-center pb-5 pb-xl-0"
							>
								<Subtitle
									txt={pageYearsMetric}
									color="#BBA57D"
									fontSize="1.3em"
									padding="10px 0 0 0"
								/>
								<Title
									txt="Anos de história"
									color="#BBA57D"
									fontSize="1.3em"
									padding="5px 0 0 0"
								/>
							</Col>
							<Col
								xl={3}
								lg={3}
								md={6}
								sm={12}
								className="text-center pb-5 pb-xl-0"
							>
								<Subtitle
									txt={pageUnitiesMetric}
									color="#BBA57D"
									fontSize="1.3em"
									padding="10px 0 0 0"
								/>
								<Title
									txt="Unidades"
									color="#BBA57D"
									fontSize="1.3em"
									padding="5px 0 0 0"
								/>
							</Col>
							<Col
								xl={3}
								lg={3}
								md={6}
								sm={12}
								className="text-center pb-5 pb-xl-0"
							>
								<Subtitle
									txt={pageBuildingsMetric}
									color="#BBA57D"
									fontSize="1.3em"
									padding="10px 0 0 0"
								/>
								<Title
									txt="Imóveis disponíveis"
									color="#BBA57D"
									fontSize="1.3em"
									padding="5px 0 0 0"
								/>
							</Col>
							<Col xl={3} lg={3} md={6} sm={12} className="text-center pb-xl-0">
								<Subtitle
									txt={pageBusinessMetric}
									color="#BBA57D"
									fontSize="1.3em"
									padding="10px 0 0 0"
								/>
								<Title
									txt="Negócios fechados"
									color="#BBA57D"
									fontSize="1.3em"
									padding="5px 0 0 0"
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</Wrapper>
	);
}
