import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100%;
	max-width: 477px;
	position: relative;
	color: #ffffff;
	flex-direction: column;
	justify-content: center;
	background-color: transparent;
	margin-top: 15px;
	margin-bottom: 15px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

	a {
		:hover {
			text-decoration: none;
			opacity: 0.8;
		}
	}
`;

export const Image = styled.div`
	height: 191px;
	position: inherit;
	background-image: linear-gradient(transparent 60%, rgba(0, 0, 0, 0.9)),
		url(${props => props.uri});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: transparent;

	:hover {
	}
`;

export const ImmobileInfo = styled.div`
	display: flex;
	flex-direction: row;
	background-color: #e1e1e1;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const Location = styled.div`
	padding: 15px;
	display: flex;
	flex-direction: column;
	justify-content: baseline;
	flex: 2.5;

	small {
		font-size: 13px;
		color: #727272;
	}

	span {
		font-size: 13px;
		font-weight: 600;
		color: #727272;
		margin-top: 5px;
	}
`;

export const Whatsapp = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	background-color: #7ad06d;
	cursor: pointer;

	:hover {
		background-color: #5bad4e;
	}
`;

export const BusinessWoman = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	background-color: #b88e4b;
	cursor: pointer;
	:hover {
		background-color: #d09f51;
	}
`;

export const ImmobileBio = styled.div`
	padding: 15px;
	display: flex;
	position: absolute;
	flex-direction: column;
	justify-content: space-between;
	top: 165px;
	font-size: 13pt;
	font-weight: 500;
	letter-spacing: -0.17px;
	small {
		font-size: 7pt;
		font-weight: lighter;
		margin-bottom: 2px;
	}
`;

export const ImmobileInfrastructure = styled.div`
	padding: 15px;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	background-color: #ffffff;
	height: 79px;
`;

export const Area = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #4b4b4d;

	img {
		width: 30px;
	}
`;

export const Bed = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #4b4b4d;

	img {
		width: 45px;
	}
`;

export const Garage = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	color: #4b4b4d;

	img {
		width: 30px;
	}
`;
