import React, { useEffect, useState } from 'react';

import { Container, ImageWrapper } from './styles';

import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useSelector } from 'react-redux';

import getFieldValue from '../../../helpers/fields';

import { baseUrlOfGCS } from '../../../constants/urls';

export default function BannerProduct(props) {
	const institucional = useSelector(
		state => state.page.institucional.informations,
	);
	const [background, setBackground] = useState('');

	useEffect(() => {
		const background = getFieldValue(institucional.fields, 22).content;
		if (background) setBackground(background);
	}, [institucional]);

	return (
		<Container>
			<div
				className="d-flex justify-content-center align-items-center"
				style={{
					backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100%), url(${
						background.indexOf('http') > -1
							? background
							: baseUrlOfGCS + background
					})`,
					backgroundPosition: 'center',
					height: 370,
				}}
			>
				<ImageWrapper className="text-center">
					<Row className="w-100 m-0">
						<Col lg="12" sm="12" md="12">
							<Image fluid src={props.logo} />
						</Col>
					</Row>
				</ImageWrapper>
			</div>
		</Container>
	);
}
