import React from 'react';
import ContentLoader from 'react-content-loader';

const ContentLoaderGrid = ({
	width = 1280,
	heading = { width: 140, height: 24 },
	row = 2,
	column = 4,
	padding = 12,
	borderRadius = 4,
	hasHeader = true,
	hasListContent = true,
	style,
	...props
}) => {
	const group = [];
	let height;

	for (let index = 1; index <= row; index++) {
		for (let jindex = 0; jindex < column; jindex++) {
			const itemWidth = (width - padding * (column + 1)) / column;

			const xAxis = padding + jindex * (itemWidth + padding);

			const heightPositionOne = itemWidth;

			const heightPositionTwo = 20;

			const heightPositionThree = 20;

			const spaceBetweenGrids =
				padding +
				heightPositionOne +
				(padding / 2 + heightPositionTwo) +
				heightPositionThree +
				padding * 4;

			const yAxisOne =
				padding +
				heading.height +
				padding * 2 +
				spaceBetweenGrids * (index - 1);

			const yAxisTwo = yAxisOne + padding + heightPositionOne;

			const yAxisThree = yAxisTwo + padding / 2 + heightPositionTwo;

			group.push(
				<>
					<rect
						x={xAxis}
						y={yAxisOne}
						rx={borderRadius}
						ry={borderRadius}
						width={itemWidth}
						height={heightPositionOne}
					/>
					{hasListContent ? (
						<>
							<rect
								x={xAxis}
								y={yAxisTwo}
								rx={0}
								ry={0}
								width={itemWidth}
								height={heightPositionTwo}
							/>
							<rect
								x={xAxis}
								y={yAxisThree}
								rx={0}
								ry={0}
								width={itemWidth * 0.6}
								height={heightPositionThree}
							/>{' '}
						</>
					) : null}
				</>,
			);

			if (index === row) {
				height = yAxisThree + heightPositionThree;
			}
		}
	}

	return (
		<ContentLoader
			viewBox={`0 0 ${width} ${height}`}
			width={width}
			height={height}
			speed={2}
			row={row}
			col={column}
			style={{ style }}
		>
			{hasHeader
				? heading && (
						<rect
							x={padding}
							y={padding}
							rx={0}
							ry={0}
							width={heading.width}
							height={heading.height}
						/>
				  )
				: null}
			{group}
		</ContentLoader>
	);
};

export default ContentLoaderGrid;
