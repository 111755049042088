import styled from 'styled-components';
import Devices from '../../../utils/devices';

export const Container = styled.div``;

export const Wrapper = styled.div`
	@media ${Devices.mobileS} {
		position: relative;
		z-index: 1;
		background-color: #fff8;
		width: 100%;
		height: auto;
		padding: 30px;
	}

	@media ${Devices.tablet} {
		position: relative;
		z-index: 1;
		background-color: #fff8;
		width: 100%;
		height: 400px;
		padding: 50px;
	}

	@media ${Devices.laptop} {
		position: relative;
		z-index: 1;
		background-color: #fff8;
		width: 500px;
		height: 400px;
		left: 600px;
		padding: 30px;
	}

	@media ${Devices.laptopL} {
		position: relative;
		z-index: 1;
		background-color: #fff8;
		width: 500px;
		height: 400px;
		left: 800px;
		padding: 30px;
	}
`;

export const ImageWrapper = styled.div`
	position: relative;
	z-index: 1;
`;

export const TitleWrapper = styled.div`
	text-align: center;
	margin-top: 30px;
	font-weight: bold;
`;

export const ParagraphWrapper = styled.p`
	text-align: justify;
	color: #363636;
	padding-top: 20px;
`;

export const ButtonAction = styled.button`
	padding: 15px;
	color: #fff;
	background-color: #7d0004;
	text-transform: uppercase;

	&:hover {
		background-color: #a23438;
	}

	&:active {
		background-color: #7d0004;
	}
`;
