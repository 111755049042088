import {
	SET_HOME_PAGE_INFORMATIONS,
	SET_HOME_PAGE_RANDOM_IMMOBILE,
	SET_INSTITUCIONAL_PAGE_INFORMATIONS,
	SET_COMEDIC_PAGE_INFORMATIONS,
	SET_COMERCIAIS_PAGE_INFORMATIONS,
	SET_RESIDENCIAIS_PAGE_INFORMATIONS,
	SET_CONSTRUCTION_DETAILS_PAGE_INFORMATIONS,
	SET_MEDICAL_PAGE_INFORMATIONS,
} from '../actions/page';

const INITIAL_STATE = {
	home: {
		randomImmobile: {},
		informations: {
			page: {
				id: 1,
				title: 'Página inicial',
				slug: 'pagina-inicial',
				icon: 'icon-home',
				description: 'A imobiliária',
				keywords: 'Imobiliária',
				created_at: '2020-02-13 15:06:40',
				updated_at: '2020-03-27 10:33:23',
				deleted_at: null,
				banners: [],
				banner: {
					id: 39,
					name: 'FLAT 503A – EDF. BEACH CLASS JAQUEIRA',
					description:
						'Sala, circulação, cozinha\r\n\r\nPróximo de shoppings, supermercados, farmácias, padarias e restaurantes, além de próximo ao Parque da Jaqueira.',
					price: '0',
					type: 'flat',
					status: 'launch',
					address_id: 58,
					recreation_areas_id: 31,
					infrastructures_id: 31,
					created_at: '2020-02-27 17:54:15',
					updated_at: '2020-02-27 17:54:15',
					deleted_at: null,
					spotlight: null,
					iptu: null,
					reference: null,
					nearby_id: null,
					convenience: null,
					construction_company_name: null,
					construction_company_image: null,
					included: null,
					address: {
						id: 58,
						street: 'AV. Parnamirim',
						cep: '51020-260',
						number: '375',
						neighborhood: 'Parnamirim',
						complement: null,
						city: 'Recife',
						latitude: '-8.291350796617285',
						longitude: '-35.94687954560373',
						state_id: 1,
						created_at: '2020-02-27 17:54:15',
						updated_at: '2020-02-27 17:54:15',
						deleted_at: null,
						state: {
							id: 1,
							name: 'PERNAMBUCO',
							uf: 'PE',
							created_at: '2020-02-13 15:06:40',
							updated_at: '2020-02-13 15:06:40',
							deleted_at: null,
							ibge_id: null,
						},
					},
					coworkings: null,
					houses: {
						id: 7,
						bedrooms_amount: 1,
						bathroom_amount: 1,
						suites_amount: 1,
						garages_amount: 1,
						area: '44',
						construction_id: 39,
						created_at: '2020-02-27 17:54:15',
						updated_at: '2020-02-27 17:54:15',
						deleted_at: null,
						total_area: null,
					},
					hubs: null,
					images: [],
					businesses: null,
					commercials: null,
					apartments: null,
					business_events: [],
					infrastructure: {
						id: 31,
						gas: true,
						hall: true,
						service_elevator: true,
						service_ladder: false,
						security_cabin: false,
						parking: true,
						social_elevator: true,
						created_at: '2020-02-27 17:54:15',
						updated_at: '2020-02-27 17:54:15',
					},
					recreation: {
						id: 31,
						pool: true,
						fitness: true,
						party_room: true,
						camp: true,
						playroom: true,
						created_at: '2020-02-27 17:54:15',
						updated_at: '2020-02-27 17:54:15',
					},
					nearby: null,
				},
				hangars: [
					{
						id: 60,
						name: 'GALPÃO L11Q13',
						description:
							'12m X 25m\r\n1 garagem\r\n4 escritórios\r\nWcs\r\nBem Localizado',
						price: null,
						type: 'commercial',
						status: 'launch',
						address_id: 70,
						recreation_areas_id: null,
						infrastructures_id: null,
						created_at: '2020-02-28 16:13:43',
						updated_at: '2020-02-28 16:13:43',
						deleted_at: null,
						spotlight: null,
						iptu: null,
						reference: null,
						nearby_id: null,
						convenience: null,
						construction_company_name: null,
						construction_company_image: null,
						included: null,
						address: {
							id: 70,
							street: 'Rua Vereador José Moraes Irmão',
							cep: '55190-380',
							number: '304',
							neighborhood: 'Malaquias Cardoso',
							complement: null,
							city: 'Santa Cruz do Capibaribe',
							latitude: '-8.290817033673632',
							longitude: '-35.95298312596435',
							state_id: 1,
							created_at: '2020-02-28 16:13:43',
							updated_at: '2020-02-28 16:13:43',
							deleted_at: null,
							state: {
								id: 1,
								name: 'PERNAMBUCO',
								uf: 'PE',
								created_at: '2020-02-13 15:06:40',
								updated_at: '2020-02-13 15:06:40',
								deleted_at: null,
								ibge_id: null,
							},
						},
						coworkings: null,
						houses: null,
						hubs: null,
						images: [],
						businesses: null,
						commercials: {
							id: 18,
							type: 'hangar',
							name: 'GALPÃO L11Q13',
							description:
								'12m X 25m\r\n1 garagem\r\n4 escritórios\r\nWcs\r\nBem Localizado',
							nearby_id: 18,
							address_id: 70,
							construction_id: 60,
							created_at: '2020-02-28 16:13:43',
							updated_at: '2020-02-28 16:13:43',
							deleted_at: null,
							total_area: null,
						},
						apartments: null,
						business_events: [],
						infrastructure: null,
						recreation: null,
						nearby: null,
					},
					{
						id: 59,
						name: 'Galpão L10Q13',
						description: '12m X 25m\r\n2 escritórios\r\nWcs\r\nBem Localizado',
						price: null,
						type: 'commercial',
						status: 'launch',
						address_id: 69,
						recreation_areas_id: null,
						infrastructures_id: null,
						created_at: '2020-02-28 16:11:27',
						updated_at: '2020-02-28 16:11:27',
						deleted_at: null,
						spotlight: null,
						iptu: null,
						reference: null,
						nearby_id: null,
						convenience: null,
						construction_company_name: null,
						construction_company_image: null,
						included: null,
						address: {
							id: 69,
							street: 'Rua Vereador José Moraes Irmão',
							cep: '55190-380',
							number: '246',
							neighborhood: 'Malaquias Cardoso',
							complement: null,
							city: 'Santa Cruz do Capibaribe',
							latitude: '-8.302792497902301',
							longitude: '-35.94375632695312',
							state_id: 1,
							created_at: '2020-02-28 16:11:27',
							updated_at: '2020-02-28 16:11:27',
							deleted_at: null,
							state: {
								id: 1,
								name: 'PERNAMBUCO',
								uf: 'PE',
								created_at: '2020-02-13 15:06:40',
								updated_at: '2020-02-13 15:06:40',
								deleted_at: null,
								ibge_id: null,
							},
						},
						coworkings: null,
						houses: null,
						hubs: null,
						images: [],
						businesses: null,
						commercials: {
							id: 17,
							type: 'hangar',
							name: 'Galpão L10Q13',
							description:
								'12m X 25m\r\n2 escritórios\r\nWcs\r\nBem Localizado',
							nearby_id: 17,
							address_id: 69,
							construction_id: 59,
							created_at: '2020-02-28 16:11:27',
							updated_at: '2020-02-28 16:11:27',
							deleted_at: null,
							total_area: null,
						},
						apartments: null,
						business_events: [],
						infrastructure: null,
						recreation: null,
						nearby: null,
					},
					{
						id: 58,
						name: 'Galpão L20Q11',
						description:
							'12m X 25m\r\n4 escritórios\r\nWcs\r\nCisterna individual\r\nBem localizado',
						price: null,
						type: 'commercial',
						status: 'launch',
						address_id: 68,
						recreation_areas_id: null,
						infrastructures_id: null,
						created_at: '2020-02-28 16:07:57',
						updated_at: '2020-02-28 16:07:57',
						deleted_at: null,
						spotlight: null,
						iptu: null,
						reference: null,
						nearby_id: null,
						convenience: null,
						construction_company_name: null,
						construction_company_image: null,
						included: null,
						address: {
							id: 68,
							street: 'Rua Vereador José Moraes Irmão',
							cep: '55190-380',
							number: '311',
							neighborhood: 'Malaquias cardoso',
							complement: null,
							city: 'Santa Cruz do Capibaribe',
							latitude: '-8.289373158726798',
							longitude: '-35.94478629521484',
							state_id: 1,
							created_at: '2020-02-28 16:07:57',
							updated_at: '2020-02-28 16:07:57',
							deleted_at: null,
							state: {
								id: 1,
								name: 'PERNAMBUCO',
								uf: 'PE',
								created_at: '2020-02-13 15:06:40',
								updated_at: '2020-02-13 15:06:40',
								deleted_at: null,
								ibge_id: null,
							},
						},
						coworkings: null,
						houses: null,
						hubs: null,
						images: [],
						businesses: null,
						commercials: {
							id: 16,
							type: 'hangar',
							name: 'Galpão L20Q11',
							description:
								'12m X 25m\r\n4 escritórios\r\nWcs\r\nCisterna individual\r\nBem localizado',
							nearby_id: 16,
							address_id: 68,
							construction_id: 58,
							created_at: '2020-02-28 16:07:57',
							updated_at: '2020-02-28 16:07:57',
							deleted_at: null,
							total_area: null,
						},
						apartments: null,
						business_events: [],
						infrastructure: null,
						recreation: null,
						nearby: null,
					},
				],
				stores: [
					{
						id: 66,
						name: 'Via Shopping',
						description:
							'Salas comerciais com 24 m² cada uma\r\nEstacionamento próprio\r\nSegurança armado diurno\r\nVigilante noturno\r\nSistema de monitoramento\r\nAcesso 24 horas\r\nWC nos dois pisos\r\nMelhor localização da cidade\r\nZelador',
						price: null,
						type: 'commercial',
						status: 'launch',
						address_id: 76,
						recreation_areas_id: null,
						infrastructures_id: null,
						created_at: '2020-02-28 17:31:09',
						updated_at: '2020-02-28 17:31:09',
						deleted_at: null,
						spotlight: null,
						iptu: null,
						reference: null,
						nearby_id: null,
						convenience: null,
						construction_company_name: null,
						construction_company_image: null,
						included: null,
						address: {
							id: 76,
							street: 'Avenida 29 de Dezembro',
							cep: '55194280',
							number: '715',
							neighborhood: 'São Cristóvão',
							complement: null,
							city: 'Santa Cruz do Capibaribe',
							latitude: '-8.290986900965864',
							longitude: '-35.97098915842904',
							state_id: 1,
							created_at: '2020-02-28 17:31:09',
							updated_at: '2020-02-28 17:31:09',
							deleted_at: null,
							state: {
								id: 1,
								name: 'PERNAMBUCO',
								uf: 'PE',
								created_at: '2020-02-13 15:06:40',
								updated_at: '2020-02-13 15:06:40',
								deleted_at: null,
								ibge_id: null,
							},
						},
						coworkings: null,
						houses: null,
						hubs: null,
						images: [],
						businesses: null,
						commercials: {
							id: 21,
							type: 'stores',
							name: 'Via Shopping',
							description:
								'Salas comerciais com 24 m² cada uma\r\nEstacionamento próprio\r\nSegurança armado diurno\r\nVigilante noturno\r\nSistema de monitoramento\r\nAcesso 24 horas\r\nWC nos dois pisos\r\nMelhor localização da cidade\r\nZelador',
							nearby_id: 24,
							address_id: 76,
							construction_id: 66,
							created_at: '2020-02-28 17:31:09',
							updated_at: '2020-02-28 17:31:09',
							deleted_at: null,
							total_area: null,
						},
						apartments: null,
						business_events: [],
						infrastructure: null,
						recreation: null,
						nearby: null,
					},
					{
						id: 63,
						name: 'LOJAS DO CENTRO MÉDICO',
						description:
							'5m x 5m \r\nBanheiros individuais\r\nPorta automática\r\nPorcelanato',
						price: null,
						type: 'commercial',
						status: 'launch',
						address_id: 73,
						recreation_areas_id: null,
						infrastructures_id: null,
						created_at: '2020-02-28 16:34:14',
						updated_at: '2020-02-28 16:34:14',
						deleted_at: null,
						spotlight: null,
						iptu: null,
						reference: null,
						nearby_id: null,
						convenience: null,
						construction_company_name: null,
						construction_company_image: null,
						included: null,
						address: {
							id: 73,
							street: 'Rua Major Negrinho',
							cep: '55192-415',
							number: '186',
							neighborhood: 'Novo',
							complement: null,
							city: 'Santa Cruz do Capibaribe',
							latitude: '-8.284145733770195',
							longitude: '-35.91798088754054',
							state_id: 1,
							created_at: '2020-02-28 16:34:14',
							updated_at: '2020-03-05 10:55:48',
							deleted_at: null,
							state: {
								id: 1,
								name: 'PERNAMBUCO',
								uf: 'PE',
								created_at: '2020-02-13 15:06:40',
								updated_at: '2020-02-13 15:06:40',
								deleted_at: null,
								ibge_id: null,
							},
						},
						coworkings: null,
						houses: null,
						hubs: null,
						images: [
							{
								id: 116,
								image_uri:
									'https://m-mestre-manager.softmakers.com.br/storage/app/public/images/1582920870.7796.jpeg',
								order: -1,
								type: 'normal',
								construction_id: 63,
								created_at: '2020-02-28 17:14:30',
								updated_at: '2020-02-28 17:14:30',
							},
							{
								id: 117,
								image_uri:
									'https://m-mestre-manager.softmakers.com.br/storage/app/public/images/1582921056.6376.jpeg',
								order: -1,
								type: 'normal',
								construction_id: 63,
								created_at: '2020-02-28 17:17:36',
								updated_at: '2020-02-28 17:17:36',
							},
						],
						businesses: null,
						commercials: {
							id: 19,
							type: 'stores',
							name: 'LOJAS DO CENTRO MÉDICO',
							description:
								'5m x 5m \r\nBanheiros individuais\r\nPorta automática\r\nPorcelanato',
							nearby_id: 21,
							address_id: 73,
							construction_id: 63,
							created_at: '2020-02-28 16:34:14',
							updated_at: '2020-02-28 16:34:14',
							deleted_at: null,
							total_area: null,
						},
						apartments: null,
						business_events: [],
						infrastructure: null,
						recreation: null,
						nearby: null,
					},
					{
						id: 52,
						name: 'COMERCIAL DA RUA MANOEL BALBINO',
						description:
							'Loja de Esquina \r\ntamanho 4,90m x 10,00m\r\nEscritório\r\nWC',
						price: null,
						type: 'commercial',
						status: 'launch',
						address_id: 62,
						recreation_areas_id: null,
						infrastructures_id: null,
						created_at: '2020-02-28 15:46:28',
						updated_at: '2020-02-28 15:46:28',
						deleted_at: null,
						spotlight: null,
						iptu: null,
						reference: null,
						nearby_id: null,
						convenience: null,
						construction_company_name: null,
						construction_company_image: null,
						included: null,
						address: {
							id: 62,
							street: 'Rua Manoel Balbino',
							cep: '55192-025',
							number: '209',
							neighborhood: 'Centro',
							complement: null,
							city: 'Santa Cruz do Capibaribe',
							latitude: '-8.292940369551387',
							longitude: '-35.93457244328613',
							state_id: 1,
							created_at: '2020-02-28 15:46:28',
							updated_at: '2020-02-28 15:46:28',
							deleted_at: null,
							state: {
								id: 1,
								name: 'PERNAMBUCO',
								uf: 'PE',
								created_at: '2020-02-13 15:06:40',
								updated_at: '2020-02-13 15:06:40',
								deleted_at: null,
								ibge_id: null,
							},
						},
						coworkings: null,
						houses: null,
						hubs: null,
						images: [
							{
								id: 114,
								image_uri:
									'https://m-mestre-manager.softmakers.com.br/storage/app/public/images/1582915935.7564.jpeg',
								order: -1,
								type: 'normal',
								construction_id: 52,
								created_at: '2020-02-28 15:52:15',
								updated_at: '2020-02-28 15:52:15',
							},
						],
						businesses: null,
						commercials: {
							id: 10,
							type: 'stores',
							name: 'COMERCIAL DA RUA MANOEL BALBINO',
							description:
								'Loja de Esquina \r\ntamanho 4,90m x 10,00m\r\nEscritório\r\nWC',
							nearby_id: 10,
							address_id: 62,
							construction_id: 52,
							created_at: '2020-02-28 15:46:28',
							updated_at: '2020-02-28 15:46:28',
							deleted_at: null,
							total_area: null,
						},
						apartments: null,
						business_events: [],
						infrastructure: null,
						recreation: null,
						nearby: null,
					},
				],
				houses: [
					{
						id: 36,
						name: 'CASAS DA RUA MAJOR NEGRINHO',
						description:
							'<p><strong>Bem localizadas</strong></p>\r\n\r\n<p><strong>1 sala</strong></p>\r\n\r\n<p><strong>1 cozinha</strong></p>\r\n\r\n<p><strong>1 &aacute;rea de servi&ccedil;o</strong></p>\r\n\r\n<p><strong>Cisterna individual</strong></p>',
						price: '300',
						type: 'house',
						status: 'launch',
						address_id: 55,
						recreation_areas_id: 28,
						infrastructures_id: 28,
						created_at: '2020-02-26 17:40:39',
						updated_at: '2020-03-26 09:38:29',
						deleted_at: null,
						spotlight: null,
						iptu: '0',
						reference: null,
						nearby_id: 62,
						convenience: '"[]"',
						construction_company_name: null,
						construction_company_image: null,
						included: '"[]"',
						address: {
							id: 55,
							street: 'Rua Major Negrinho',
							cep: '55192-415',
							number: '454',
							neighborhood: 'Bairro Novo',
							complement: null,
							city: 'Santa Cruz do Capibaribe',
							latitude: '-8.310622206098692',
							longitude: '-35.966646609406425',
							state_id: 1,
							created_at: '2020-02-26 17:40:39',
							updated_at: '2020-03-26 09:38:29',
							deleted_at: null,
							state: {
								id: 1,
								name: 'PERNAMBUCO',
								uf: 'PE',
								created_at: '2020-02-13 15:06:40',
								updated_at: '2020-02-13 15:06:40',
								deleted_at: null,
								ibge_id: null,
							},
						},
						coworkings: null,
						houses: {
							id: 6,
							bedrooms_amount: 2,
							bathroom_amount: 1,
							suites_amount: 0,
							garages_amount: 0,
							area: '72',
							construction_id: 36,
							created_at: '2020-02-26 17:40:39',
							updated_at: '2020-03-26 09:38:29',
							deleted_at: null,
							total_area: null,
						},
						hubs: null,
						images: [],
						businesses: null,
						commercials: null,
						apartments: null,
						business_events: [],
						infrastructure: {
							id: 28,
							gas: false,
							hall: false,
							service_elevator: false,
							service_ladder: false,
							security_cabin: false,
							parking: false,
							social_elevator: false,
							created_at: '2020-02-26 17:40:39',
							updated_at: '2020-02-26 17:40:39',
						},
						recreation: {
							id: 28,
							pool: false,
							fitness: false,
							party_room: false,
							camp: false,
							playroom: false,
							created_at: '2020-02-26 17:40:39',
							updated_at: '2020-02-26 17:40:39',
						},
						nearby: {
							id: 62,
							supermarket: false,
							supermarket_description: null,
							drug_store: false,
							drug_store_description: null,
							restaurant: false,
							restaurant_description: null,
							hotel: false,
							hotel_description: null,
							bus_stop: false,
							bus_stop_description: null,
							academy: false,
							academy_description: null,
							school: false,
							school_description: null,
							nursery: false,
							nursery_description: null,
							hospital: false,
							hospital_description: null,
							beach: false,
							beach_description: null,
							airport: false,
							airport_description: null,
							sports_academy: false,
							sports_academy_description: null,
							pub: false,
							pub_description: null,
							bazaar: false,
							bazaar_description: null,
							nightclub: false,
							nightclub_description: null,
							office_building: false,
							office_building_description: null,
							market: false,
							market_description: null,
							municipal_market: false,
							municipal_market_description: null,
							bakeries: false,
							bakeries_description: null,
							created_at: '2020-03-26 09:38:29',
							updated_at: '2020-03-26 09:38:29',
						},
					},
					{
						id: 35,
						name: 'CASAS DA RUA ABÍLIO CESAR',
						description:
							'<p><strong>Port&atilde;o em alum&iacute;nio</strong></p>\r\n\r\n<p><strong>1 sala</strong></p>\r\n\r\n<p><strong>Cozinha</strong></p>\r\n\r\n<p><strong>&Aacute;rea de servi&ccedil;o</strong></p>',
						price: '0',
						type: 'house',
						status: 'launch',
						address_id: 54,
						recreation_areas_id: 27,
						infrastructures_id: 27,
						created_at: '2020-02-26 17:29:36',
						updated_at: '2020-02-28 16:15:23',
						deleted_at: null,
						spotlight: null,
						iptu: null,
						reference: null,
						nearby_id: null,
						convenience: null,
						construction_company_name: null,
						construction_company_image: null,
						included: null,
						address: {
							id: 54,
							street: 'Rua Abílio Cesar Pedrosa Neto',
							cep: '55190-389',
							number: '34',
							neighborhood: 'Malaquias Cardoso',
							complement: null,
							city: 'Santa Cruz do Capibaribe',
							latitude: '-8.316296306589367',
							longitude: '-35.9802772848999',
							state_id: 1,
							created_at: '2020-02-26 17:29:36',
							updated_at: '2020-02-28 16:15:23',
							deleted_at: null,
							state: {
								id: 1,
								name: 'PERNAMBUCO',
								uf: 'PE',
								created_at: '2020-02-13 15:06:40',
								updated_at: '2020-02-13 15:06:40',
								deleted_at: null,
								ibge_id: null,
							},
						},
						coworkings: null,
						houses: {
							id: 5,
							bedrooms_amount: 3,
							bathroom_amount: 2,
							suites_amount: 1,
							garages_amount: 1,
							area: '100',
							construction_id: 35,
							created_at: '2020-02-26 17:29:36',
							updated_at: '2020-02-28 16:15:23',
							deleted_at: null,
							total_area: null,
						},
						hubs: null,
						images: [
							{
								id: 24,
								image_uri:
									'https://m-mestre-manager.softmakers.com.br/storage/app/public/images/1582749028.531.jpeg',
								order: -1,
								type: 'normal',
								construction_id: 35,
								created_at: '2020-02-26 17:30:28',
								updated_at: '2020-02-26 17:30:28',
							},
							{
								id: 25,
								image_uri:
									'https://m-mestre-manager.softmakers.com.br/storage/app/public/images/1582749037.1692.jpeg',
								order: -1,
								type: 'normal',
								construction_id: 35,
								created_at: '2020-02-26 17:30:37',
								updated_at: '2020-02-26 17:30:37',
							},
							{
								id: 26,
								image_uri:
									'https://m-mestre-manager.softmakers.com.br/storage/app/public/images/1582749043.9727.jpeg',
								order: -1,
								type: 'normal',
								construction_id: 35,
								created_at: '2020-02-26 17:30:43',
								updated_at: '2020-02-26 17:30:43',
							},
							{
								id: 27,
								image_uri:
									'https://m-mestre-manager.softmakers.com.br/storage/app/public/images/1582749051.8218.jpeg',
								order: -1,
								type: 'normal',
								construction_id: 35,
								created_at: '2020-02-26 17:30:51',
								updated_at: '2020-02-26 17:30:51',
							},
						],
						businesses: null,
						commercials: null,
						apartments: null,
						business_events: [],
						infrastructure: {
							id: 27,
							gas: false,
							hall: false,
							service_elevator: false,
							service_ladder: false,
							security_cabin: false,
							parking: false,
							social_elevator: false,
							created_at: '2020-02-26 17:29:36',
							updated_at: '2020-02-26 17:29:36',
						},
						recreation: {
							id: 27,
							pool: false,
							fitness: false,
							party_room: false,
							camp: false,
							playroom: false,
							created_at: '2020-02-26 17:29:36',
							updated_at: '2020-02-26 17:29:36',
						},
						nearby: null,
					},
				],
				apartments: [
					{
						id: 50,
						name: 'AP. 1901 – EDF. ACQUA HOME CLUB',
						description:
							'<p><strong>130 M&sup2; &Aacute;REA PRIVATIVA (quatro por andar)</strong></p>\r\n\r\n<p><br />\r\n<strong>Sala de jantar, sala de estar, cozinha/copa, despensa, &aacute;rea de servi&ccedil;o, quarto de servi&ccedil;o, wc servi&ccedil;o, wc social,</strong>&nbsp;<strong>uma piscina adulta e infantil,&nbsp;</strong><strong>01 su&iacute;te com closet, 01 quarto revers&iacute;vel.&nbsp;</strong></p>',
						price: '0',
						type: 'apartment',
						status: 'launch',
						address_id: 47,
						recreation_areas_id: 42,
						infrastructures_id: 42,
						created_at: '2020-02-28 15:11:29',
						updated_at: '2020-02-28 15:11:29',
						deleted_at: null,
						spotlight:
							'<p><strong>1 piscina com raia semiol&iacute;mpica</strong></p>\r\n\r\n<p><strong>1 prainha</strong></p>\r\n\r\n<p><strong>1 piscina aquecida</strong></p>\r\n\r\n<p><strong>1 jacuzzi</strong></p>\r\n\r\n<p><strong>Quadra</strong></p>\r\n\r\n<p><strong>02 guarita</strong></p>\r\n\r\n<p><strong>02 elevadores sociais panor&acirc;micos</strong></p>',
						iptu: null,
						reference: null,
						nearby_id: null,
						convenience: null,
						construction_company_name: null,
						construction_company_image: null,
						included: null,
						address: {
							id: 47,
							street: 'Avenida Amazonas',
							cep: '55016-420',
							number: 'S/N',
							neighborhood: 'Universitário',
							complement: null,
							city: 'Recife',
							latitude: null,
							longitude: null,
							state_id: 1,
							created_at: '2020-02-21 16:48:57',
							updated_at: '2020-02-21 17:17:46',
							deleted_at: null,
							state: {
								id: 1,
								name: 'PERNAMBUCO',
								uf: 'PE',
								created_at: '2020-02-13 15:06:40',
								updated_at: '2020-02-13 15:06:40',
								deleted_at: null,
								ibge_id: null,
							},
						},
						coworkings: null,
						houses: null,
						hubs: null,
						images: [],
						businesses: null,
						commercials: null,
						apartments: {
							id: 9,
							bedrooms_amount: 2,
							bathroom_amount: 1,
							garages_amount: 3,
							suites_amount: 1,
							area: '130',
							construction_id: 50,
							condominium_id: 14,
							created_at: '2020-02-28 15:11:29',
							updated_at: '2020-02-28 15:11:29',
							deleted_at: null,
							total_area: null,
							condominium: {
								id: 14,
								name: 'EDF. ACQUA HOME CLUB',
								price: '0',
								infrastructure_id: 26,
								recreation_areas_id: 26,
								address_id: 47,
								created_at: '2020-02-21 16:48:57',
								updated_at: '2020-02-21 17:17:46',
								deleted_at: null,
								construction_id: 32,
								total_area: null,
							},
						},
						business_events: [],
						infrastructure: {
							id: 42,
							gas: true,
							hall: true,
							service_elevator: true,
							service_ladder: false,
							security_cabin: false,
							parking: true,
							social_elevator: true,
							created_at: '2020-02-28 15:11:29',
							updated_at: '2020-02-28 15:11:29',
						},
						recreation: {
							id: 42,
							pool: true,
							fitness: true,
							party_room: true,
							camp: true,
							playroom: true,
							created_at: '2020-02-28 15:11:29',
							updated_at: '2020-02-28 15:11:29',
						},
						nearby: null,
					},
					{
						id: 49,
						name: 'AP. 1603 – EDF. ACQUA HOME CLUB',
						description:
							'<p><strong>100 M&sup2; &Aacute;REA PRIVATIVA (seis por andar)</strong></p>\r\n\r\n<p><strong>Sala de jantar, sala de estar, cozinha/copa, despensa, &aacute;rea de servi&ccedil;o, wc servi&ccedil;o, wc social,&nbsp;6 piscinas, uma piscina com raia semiol&iacute;mpica, uma piscina adulta e infantil.</strong><br />\r\n&nbsp;</p>',
						price: '0',
						type: 'apartment',
						status: 'launch',
						address_id: 47,
						recreation_areas_id: 41,
						infrastructures_id: 41,
						created_at: '2020-02-28 15:05:23',
						updated_at: '2020-02-28 15:05:23',
						deleted_at: null,
						spotlight:
							'<p><strong>Jacuzzi</strong></p>\r\n\r\n<p><strong>Prainha</strong></p>\r\n\r\n<p><strong>Piscina aquecida</strong></p>\r\n\r\n<p><strong>02 guarita</strong></p>\r\n\r\n<p><strong>02 elevadores sociais panor&acirc;micos</strong></p>',
						iptu: null,
						reference: null,
						nearby_id: null,
						convenience: null,
						construction_company_name: null,
						construction_company_image: null,
						included: null,
						address: {
							id: 47,
							street: 'Avenida Amazonas',
							cep: '55016-420',
							number: 'S/N',
							neighborhood: 'Universitário',
							complement: null,
							city: 'Recife',
							latitude: null,
							longitude: null,
							state_id: 1,
							created_at: '2020-02-21 16:48:57',
							updated_at: '2020-02-21 17:17:46',
							deleted_at: null,
							state: {
								id: 1,
								name: 'PERNAMBUCO',
								uf: 'PE',
								created_at: '2020-02-13 15:06:40',
								updated_at: '2020-02-13 15:06:40',
								deleted_at: null,
								ibge_id: null,
							},
						},
						coworkings: null,
						houses: null,
						hubs: null,
						images: [],
						businesses: null,
						commercials: null,
						apartments: {
							id: 8,
							bedrooms_amount: 2,
							bathroom_amount: 1,
							garages_amount: 2,
							suites_amount: 1,
							area: '100',
							construction_id: 49,
							condominium_id: 14,
							created_at: '2020-02-28 15:05:23',
							updated_at: '2020-02-28 15:05:23',
							deleted_at: null,
							total_area: null,
							condominium: {
								id: 14,
								name: 'EDF. ACQUA HOME CLUB',
								price: '0',
								infrastructure_id: 26,
								recreation_areas_id: 26,
								address_id: 47,
								created_at: '2020-02-21 16:48:57',
								updated_at: '2020-02-21 17:17:46',
								deleted_at: null,
								construction_id: 32,
								total_area: null,
							},
						},
						business_events: [],
						infrastructure: {
							id: 41,
							gas: true,
							hall: true,
							service_elevator: true,
							service_ladder: false,
							security_cabin: false,
							parking: true,
							social_elevator: true,
							created_at: '2020-02-28 15:05:23',
							updated_at: '2020-02-28 15:05:23',
						},
						recreation: {
							id: 41,
							pool: true,
							fitness: true,
							party_room: true,
							camp: false,
							playroom: true,
							created_at: '2020-02-28 15:05:23',
							updated_at: '2020-02-28 15:05:23',
						},
						nearby: null,
					},
					{
						id: 48,
						name: 'AP – EDF. GRAND RESIDENCE',
						description:
							'<p><strong>960 M&sup2; &Aacute;REA PRIVATIVA &nbsp;(quatro&nbsp;por andar)</strong></p>\r\n\r\n<p><br />\r\n<strong>Sala para 02 ambientes, &nbsp;cozinha, &aacute;rea de servi&ccedil;o, wc social.</strong></p>\r\n\r\n<p>&nbsp;</p>',
						price: '0',
						type: 'apartment',
						status: 'launch',
						address_id: 39,
						recreation_areas_id: 40,
						infrastructures_id: 40,
						created_at: '2020-02-28 14:41:15',
						updated_at: '2020-02-28 14:41:15',
						deleted_at: null,
						spotlight:
							'<p><strong>Varanda gourmet</strong></p>\r\n\r\n<p><strong>Piscina aquecida</strong></p>\r\n\r\n<p><strong>01 guarita</strong></p>\r\n\r\n<p><strong>Churrasqueira</strong></p>\r\n\r\n<p><strong>Brinquedoteca</strong></p>\r\n\r\n<p>&nbsp;</p>',
						iptu: null,
						reference: null,
						nearby_id: null,
						convenience: null,
						construction_company_name: null,
						construction_company_image: null,
						included: null,
						address: {
							id: 39,
							street: 'Av. Dr Arnaldo Monteiro',
							cep: '55192-370',
							number: '2-48',
							neighborhood: 'Bairro Novo',
							complement: null,
							city: 'Santa Cruz do Capibaribe',
							latitude: null,
							longitude: null,
							state_id: 1,
							created_at: '2020-02-21 14:42:24',
							updated_at: '2020-02-21 14:42:24',
							deleted_at: null,
							state: {
								id: 1,
								name: 'PERNAMBUCO',
								uf: 'PE',
								created_at: '2020-02-13 15:06:40',
								updated_at: '2020-02-13 15:06:40',
								deleted_at: null,
								ibge_id: null,
							},
						},
						coworkings: null,
						houses: null,
						hubs: null,
						images: [],
						businesses: null,
						commercials: null,
						apartments: {
							id: 7,
							bedrooms_amount: 1,
							bathroom_amount: 1,
							garages_amount: 2,
							suites_amount: 2,
							area: '960',
							construction_id: 48,
							condominium_id: 7,
							created_at: '2020-02-28 14:41:15',
							updated_at: '2020-02-28 14:41:15',
							deleted_at: null,
							total_area: null,
							condominium: {
								id: 7,
								name: 'EDF. GRAND RESIDENCE',
								price: '0',
								infrastructure_id: 19,
								recreation_areas_id: 19,
								address_id: 39,
								created_at: '2020-02-21 14:42:24',
								updated_at: '2020-02-21 14:42:24',
								deleted_at: null,
								construction_id: 24,
								total_area: null,
							},
						},
						business_events: [],
						infrastructure: {
							id: 40,
							gas: true,
							hall: true,
							service_elevator: true,
							service_ladder: false,
							security_cabin: false,
							parking: true,
							social_elevator: true,
							created_at: '2020-02-28 14:41:15',
							updated_at: '2020-02-28 14:41:15',
						},
						recreation: {
							id: 40,
							pool: true,
							fitness: true,
							party_room: true,
							camp: false,
							playroom: true,
							created_at: '2020-02-28 14:41:15',
							updated_at: '2020-02-28 14:41:15',
						},
						nearby: null,
					},
				],
				fields: [
					{
						id: 4,
						title: '[Seção 01] [Banners] Imagem "Comedic"',
						description: 'Imagem ilustrativa para a página "Comedic"',
						slug: 'secao-01-banners-imagem-comedic',
						type: 'Imagem',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 12:03:46',
						updated_at: '2020-03-16 17:07:55',
						deleted_at: null,
						values: [
							{
								id: 10,
								content:
									'pagina-inicial/p9jcksmxBbO15Xh64ZOPpkou23eOpemQOd05QA0Z.jpeg',
								order: 0,
								field_id: 4,
								created_at: '2020-03-16 15:46:29',
								updated_at: '2020-03-26 15:41:11',
								deleted_at: null,
							},
						],
					},
					{
						id: 5,
						title: '[Seção 01] [Banners] Imagem "Comerciais"',
						description: 'Imagem ilustrativa para a página "Comerciais"',
						slug: 'secao-01-banners-imagem-comerciais',
						type: 'Imagem',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:09:35',
						updated_at: '2020-03-16 17:08:03',
						deleted_at: null,
						values: [
							{
								id: 11,
								content:
									'pagina-inicial/mocNJtku5RCgW2sHMrWkB19GKgOosVnFoOsLK6Zq.jpeg',
								order: 0,
								field_id: 5,
								created_at: '2020-03-16 15:46:43',
								updated_at: '2020-03-26 15:41:11',
								deleted_at: null,
							},
						],
					},
					{
						id: 36,
						title: '[Seção 02] [Encontre seu imóvel] Imagem de fundo',
						description: 'Imagem de fundo da seção de busca na página inicial',
						slug: 'secao-02-encontre-seu-imovel-imagem-de-fundo',
						type: 'Imagem',
						page_id: 1,
						order: 0,
						created_at: '2020-03-30 11:15:16',
						updated_at: '2020-03-30 11:15:16',
						deleted_at: null,
						values: [
							{
								id: 32,
								content:
									'pagina-inicial/sfpdNFTri51QeQSPnijv6g6DbgjnA2OQ8o6AvlKt.jpeg',
								order: 0,
								field_id: 36,
								created_at: '2020-03-30 11:15:38',
								updated_at: '2020-03-30 11:21:39',
								deleted_at: null,
							},
						],
					},
					{
						id: 6,
						title: '[Seção 02] [Encontre seu imóvel] Título da seção',
						description: 'Texto apresentado no início da seção',
						slug: 'secao-02-encontre-seu-imovel-titulo-da-secao',
						type: 'Texto simples',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:13:13',
						updated_at: '2020-03-16 17:08:22',
						deleted_at: null,
						values: [
							{
								id: 3,
								content: 'Encontre seu imóvel',
								order: 0,
								field_id: 6,
								created_at: '2020-03-16 15:46:29',
								updated_at: '2020-03-16 15:48:48',
								deleted_at: null,
							},
						],
					},
					{
						id: 8,
						title: '[Seção 03] [Entre em contato] Texto descritivo da seção',
						description: 'Texto apresentado no corpo da seção',
						slug: 'secao-03-entre-em-contato-texto-descritivo-da-secao',
						type: 'Texto simples',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:18:16',
						updated_at: '2020-04-01 11:02:30',
						deleted_at: null,
						values: [
							{
								id: 4,
								content:
									'Entre em contato com um dos nossos especialistas e<br>tenha assistência que merece sem burocracias.',
								order: 0,
								field_id: 8,
								created_at: '2020-03-16 15:46:29',
								updated_at: '2020-04-01 11:03:14',
								deleted_at: null,
							},
						],
					},
					{
						id: 7,
						title: '[Seção 03] [Entre em contato] Título da seção',
						description: 'Texto apresentado no início da seção',
						slug: 'secao-03-entre-em-contato-titulo-da-secao',
						type: 'Texto simples',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:16:07',
						updated_at: '2020-03-16 17:08:59',
						deleted_at: null,
						values: [
							{
								id: 5,
								content: 'LOCALIDADES EXCLUSIVAS<br><b>M. MESTRE</b>',
								order: 0,
								field_id: 7,
								created_at: '2020-03-16 15:46:29',
								updated_at: '2020-04-01 10:56:44',
								deleted_at: null,
							},
						],
					},
					{
						id: 9,
						title: '[Seção 04] [Comedic] Primeiro bloco de imagem',
						description:
							'Primeira imagem ilustrativa para a seção referente ao Comedic',
						slug: 'secao-04-comedic-primeiro-bloco-de-imagem',
						type: 'Imagem',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:22:04',
						updated_at: '2020-03-16 17:09:45',
						deleted_at: null,
						values: [
							{
								id: 16,
								content:
									'pagina-inicial/mxmzt3EHGzIbJNkkRyvzA55wbtRdl8LMU9xKqkUu.png',
								order: 0,
								field_id: 9,
								created_at: '2020-03-18 16:27:17',
								updated_at: '2020-04-01 13:01:19',
								deleted_at: null,
							},
						],
					},
					{
						id: 10,
						title: '[Seção 04] [Comedic] Primeiro bloco de texto',
						description:
							'Primeiro bloco de texto descritivo para a seção referente ao Comedic',
						slug: 'secao-04-comedic-primeiro-bloco-de-texto',
						type: 'Editor de texto',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:23:10',
						updated_at: '2020-03-16 17:09:55',
						deleted_at: null,
						values: [
							{
								id: 1,
								content:
									'<p>Priorize seu tempo de trabalho oferecendo mobilidade e requinte aos seus clientes. Fa&ccedil;a parte do primeiro centro m&eacute;dico compartilhado do agreste de Pernambuco.</p>',
								order: 0,
								field_id: 10,
								created_at: '2020-03-16 15:46:29',
								updated_at: '2020-04-01 13:09:28',
								deleted_at: null,
							},
						],
					},
					{
						id: 11,
						title: '[Seção 04] [Comedic] Segundo bloco de imagem',
						description:
							'Segunda imagem ilustrativa para a seção referente ao Comedic',
						slug: 'secao-04-comedic-segundo-bloco-de-imagem',
						type: 'Imagem',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:24:10',
						updated_at: '2020-03-16 17:10:04',
						deleted_at: null,
						values: [
							{
								id: 17,
								content:
									'pagina-inicial/C2H376ceu3ERrU5SKsXu7wHJj52WmlDVS8RsMSIY.png',
								order: 0,
								field_id: 11,
								created_at: '2020-03-18 16:29:39',
								updated_at: '2020-04-01 13:01:19',
								deleted_at: null,
							},
						],
					},
					{
						id: 12,
						title: '[Seção 04] [Comedic] Segundo bloco de texto',
						description:
							'Segundo bloco de texto descritivo para a seção referente ao Comedic',
						slug: 'secao-04-comedic-segundo-bloco-de-texto',
						type: 'Editor de texto',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:24:40',
						updated_at: '2020-03-16 17:10:12',
						deleted_at: null,
						values: [
							{
								id: 2,
								content:
									'<p>Ambiente com um ecossistema empreendedor e funcional para sua empresa desenvolver produtos e servi&ccedil;os com mais foco e toda praticidade dos grandes espa&ccedil;os de inova&ccedil;&atilde;o e engajamento.</p>',
								order: 0,
								field_id: 12,
								created_at: '2020-03-16 15:46:29',
								updated_at: '2020-04-01 13:10:25',
								deleted_at: null,
							},
						],
					},
					{
						id: 18,
						title: '[Seção 04] [Comedic] Título do primeiro bloco',
						description:
							'Título do primeiro bloco de texto descritivo para a seção referente ao Comedic',
						slug: 'secao-04-comedic-titulo-do-primeiro-bloco',
						type: 'Texto simples',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 17:04:27',
						updated_at: '2020-03-16 17:10:23',
						deleted_at: null,
						values: [
							{
								id: 14,
								content: 'COWORKING MÉDICO',
								order: 0,
								field_id: 18,
								created_at: '2020-03-18 10:16:18',
								updated_at: '2020-03-18 10:32:47',
								deleted_at: null,
							},
						],
					},
					{
						id: 19,
						title: '[Seção 04] [Comedic] Título do segundo bloco',
						description:
							'Título do segundo bloco de texto descritivo para a seção referente ao Comedic',
						slug: 'secao-04-comedic-titulo-do-segundo-bloco',
						type: 'Texto simples',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 17:06:02',
						updated_at: '2020-03-16 17:10:33',
						deleted_at: null,
						values: [
							{
								id: 15,
								content: 'SALAS DE REUNIÕES',
								order: 0,
								field_id: 19,
								created_at: '2020-03-18 10:16:18',
								updated_at: '2020-03-18 10:44:29',
								deleted_at: null,
							},
						],
					},
					{
						id: 37,
						title:
							'[Seção 05] [Formulário de contato] Papel de parede da seção',
						description:
							'Imagem de fundo da seção referente ao formulário de contato',
						slug: 'secao-05-formulario-de-contato-papel-de-parede-da-secao',
						type: 'Imagem',
						page_id: 1,
						order: 0,
						created_at: '2020-04-02 09:27:11',
						updated_at: '2020-04-02 09:27:11',
						deleted_at: null,
						values: [
							{
								id: 33,
								content:
									'pagina-inicial/fGGgpe39yPJVaSnwIRXh8B2aWjbF5wByBdLkL66I.png',
								order: 0,
								field_id: 37,
								created_at: '2020-04-02 09:27:37',
								updated_at: '2020-04-02 09:27:37',
								deleted_at: null,
							},
						],
					},
					{
						id: 13,
						title: '[Seção 05] [Formulário de contato] Título da seção',
						description: 'Texto apresentado no início da seção',
						slug: 'secao-05-formulario-de-contato-titulo-da-secao',
						type: 'Texto simples',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:26:32',
						updated_at: '2020-03-16 17:10:59',
						deleted_at: null,
						values: [
							{
								id: 6,
								content: 'NÃO ENCONTROU<br>O IMÓVEL?',
								order: 0,
								field_id: 13,
								created_at: '2020-03-16 15:46:29',
								updated_at: '2020-04-02 15:13:03',
								deleted_at: null,
							},
						],
					},
					{
						id: 14,
						title:
							'[Seção 05] [Formulário em contato] Texto descritivo da seção',
						description: 'Texto apresentado no corpo da seção',
						slug: 'secao-05-formulario-em-contato-texto-descritivo-da-secao',
						type: 'Editor de texto',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:28:08',
						updated_at: '2020-03-16 17:11:12',
						deleted_at: null,
						values: [
							{
								id: 9,
								content:
									'<p>Descreva o que est&aacute; procurando como im&oacute;vel ideial e um consultor MMestre enviar&aacute; as melhores op&ccedil;&otilde;es para voc&ecirc;.</p>',
								order: 0,
								field_id: 14,
								created_at: '2020-03-16 15:46:29',
								updated_at: '2020-03-18 10:56:05',
								deleted_at: null,
							},
						],
					},
					{
						id: 17,
						title:
							'[Seção 06] [Formulário de inscrição para newsletters] Imagem da propaganda',
						description: 'Imagem ilustrativa da propaganda',
						slug:
							'secao-06-formulario-de-inscricao-para-newsletters-imagem-da-propaganda',
						type: 'Imagem',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:41:00',
						updated_at: '2020-03-16 17:11:44',
						deleted_at: null,
						values: [
							{
								id: 18,
								content:
									'pagina-inicial/GgY4a4gh2SXEoVSbTlYWoYopDIKuHZiC1JZ0vNWZ.png',
								order: 0,
								field_id: 17,
								created_at: '2020-03-18 16:35:32',
								updated_at: '2020-04-02 16:01:56',
								deleted_at: null,
							},
						],
					},
					{
						id: 39,
						title:
							'[Seção 06] [Formulário de inscrição para newsletters] Link da propaganda',
						description: 'Link que direcionará ao artefato da propaganda',
						slug:
							'secao-06-formulario-de-inscricao-para-newsletters-link-da-propaganda',
						type: 'Texto simples',
						page_id: 1,
						order: 0,
						created_at: '2020-04-02 16:04:31',
						updated_at: '2020-04-02 16:04:31',
						deleted_at: null,
						values: [
							{
								id: 34,
								content: '#',
								order: 0,
								field_id: 39,
								created_at: '2020-04-02 16:06:21',
								updated_at: '2020-04-02 16:06:21',
								deleted_at: null,
							},
						],
					},
					{
						id: 16,
						title:
							'[Seção 06] [Formulário de inscrição para newsletters] Título da propaganda',
						description: 'Texto apresentado na propaganda',
						slug:
							'secao-06-formulario-de-inscricao-para-newsletters-titulo-da-propaganda',
						type: 'Texto simples',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:40:22',
						updated_at: '2020-03-16 17:11:53',
						deleted_at: null,
						values: [
							{
								id: 7,
								content: 'Quer vender seu imóvel com agilidade e segurança?',
								order: 0,
								field_id: 16,
								created_at: '2020-03-16 15:46:29',
								updated_at: '2020-04-02 15:35:25',
								deleted_at: null,
							},
						],
					},
					{
						id: 15,
						title:
							'[Seção 06] [Formulário de inscrição para newsletters] Título da seção',
						description: 'Texto apresentado no início da seção',
						slug:
							'secao-06-formulario-de-inscricao-para-newsletters-titulo-da-secao',
						type: 'Texto simples',
						page_id: 1,
						order: 0,
						created_at: '2020-03-16 15:30:13',
						updated_at: '2020-03-16 17:11:29',
						deleted_at: null,
						values: [
							{
								id: 8,
								content: 'Cadastre-se e<br>receba novidades<br>exclusivas',
								order: 0,
								field_id: 15,
								created_at: '2020-03-16 15:46:29',
								updated_at: '2020-04-02 15:35:25',
								deleted_at: null,
							},
						],
					},
				],
			},
		},
	},
	institucional: {
		informations: {
			page: {
				id: 8,
				title: 'A imobiliária',
				slug: 'a-imobiliaria',
				icon: 'icon-briefcase',
				description: 'A imobiliária',
				keywords: 'Imobiliária',
				created_at: '2020-03-17 10:09:05',
				updated_at: '2020-03-26 11:56:05',
				deleted_at: null,
				fields: [
					{
						id: 22,
						title: '[IMAGEM] Banner principal',
						description: 'Banner que irá aparecer no cabeçalho da página',
						slug: 'imagem-banner-principal',
						type: 'Imagem',
						page_id: 8,
						order: 0,
						created_at: '2020-03-17 14:28:18',
						updated_at: '2020-03-26 10:48:35',
						deleted_at: null,
						values: [
							{
								id: 31,
								content:
									'a-imobiliaria/80ZVcyx5LDbZEkwuLa7P13jWVNiUKVDtDLk3ZfF6.jpeg',
								order: 0,
								field_id: 22,
								created_at: '2020-03-24 13:34:36',
								updated_at: '2020-03-26 13:28:00',
								deleted_at: null,
							},
						],
					},
					{
						id: 24,
						title: '[IMAGEM] Primeira imagem ilustrativa',
						description:
							'Primeira imagem exposta ao lado do conteúdo da página',
						slug: 'imagem-primeira-imagem-ilustrativa',
						type: 'Imagem',
						page_id: 8,
						order: 0,
						created_at: '2020-03-17 14:33:15',
						updated_at: '2020-03-26 10:52:04',
						deleted_at: null,
						values: [
							{
								id: 19,
								content:
									'a-imobiliaria/IlWhi2KRYyyF9XX6sFBvb61IfwJGQ3RxGskNmIo4.png',
								order: 0,
								field_id: 24,
								created_at: '2020-03-24 12:43:23',
								updated_at: '2020-03-26 11:13:04',
								deleted_at: null,
							},
						],
					},
					{
						id: 23,
						title: '[IMAGEM] Segunda imagem ilustrativa',
						description: 'Segunda imagem exposta ao lado do conteúdo da página',
						slug: 'imagem-segunda-imagem-ilustrativa',
						type: 'Imagem',
						page_id: 8,
						order: 0,
						created_at: '2020-03-17 14:32:24',
						updated_at: '2020-03-26 10:49:13',
						deleted_at: null,
						values: [
							{
								id: 20,
								content:
									'a-imobiliaria/6iJ57EKKuP8D1OGrjyL9KT6DGC4XoyQSn2OjY8K6.png',
								order: 0,
								field_id: 23,
								created_at: '2020-03-24 12:43:23',
								updated_at: '2020-03-26 11:13:05',
								deleted_at: null,
							},
						],
					},
					{
						id: 27,
						title: '[MÉTRICA] Anos de história',
						description: 'Anos de atuação da empresa',
						slug: 'metrica-anos-de-historia',
						type: 'Texto simples',
						page_id: 8,
						order: 0,
						created_at: '2020-03-17 14:42:18',
						updated_at: '2020-03-26 11:00:48',
						deleted_at: null,
						values: [
							{
								id: 23,
								content: '17',
								order: 0,
								field_id: 27,
								created_at: '2020-03-24 12:43:23',
								updated_at: '2020-03-26 11:11:48',
								deleted_at: null,
							},
						],
					},
					{
						id: 29,
						title: '[MÉTRICA] Imóveis disponíveis',
						description: 'Quantidade de imóveis disponibilizados para empresa',
						slug: 'metrica-imoveis-disponiveis',
						type: 'Texto simples',
						page_id: 8,
						order: 0,
						created_at: '2020-03-17 14:44:20',
						updated_at: '2020-03-26 11:02:46',
						deleted_at: null,
						values: [
							{
								id: 25,
								content: '4000',
								order: 0,
								field_id: 29,
								created_at: '2020-03-24 12:43:23',
								updated_at: '2020-03-26 11:11:49',
								deleted_at: null,
							},
						],
					},
					{
						id: 30,
						title: '[MÉTRICA] Negócios fechados',
						description: 'Quantidade de negócios fechados pela empresa',
						slug: 'metrica-negocios-fechados',
						type: 'Texto simples',
						page_id: 8,
						order: 0,
						created_at: '2020-03-17 14:46:38',
						updated_at: '2020-03-26 11:03:37',
						deleted_at: null,
						values: [
							{
								id: 29,
								content: '1000',
								order: 0,
								field_id: 30,
								created_at: '2020-03-24 13:31:41',
								updated_at: '2020-03-26 11:11:49',
								deleted_at: null,
							},
						],
					},
					{
						id: 28,
						title: '[MÉTRICA] Unidades',
						description: 'Quantidade de unidades existentes da empresa',
						slug: 'metrica-unidades',
						type: 'Texto simples',
						page_id: 8,
						order: 0,
						created_at: '2020-03-17 14:42:48',
						updated_at: '2020-03-26 11:01:53',
						deleted_at: null,
						values: [
							{
								id: 26,
								content: '1',
								order: 0,
								field_id: 28,
								created_at: '2020-03-24 12:43:23',
								updated_at: '2020-03-26 11:11:49',
								deleted_at: null,
							},
						],
					},
					{
						id: 26,
						title: '[TEXTO] Conteúdo da página',
						description: 'Informações sobre a empresa',
						slug: 'texto-conteudo-da-pagina',
						type: 'Editor de texto',
						page_id: 8,
						order: 0,
						created_at: '2020-03-17 14:40:06',
						updated_at: '2020-03-26 10:58:21',
						deleted_at: null,
						values: [
							{
								id: 24,
								content:
									'<h2>Quem somos?</h2>\r\n\r\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>\r\n\r\n<h2>O que fazemos</h2>\r\n\r\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>\r\n\r\n<h2>Princ&iacute;pios</h2>\r\n\r\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>\r\n\r\n<h2>Miss&atilde;o</h2>\r\n\r\n<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>',
								order: 0,
								field_id: 26,
								created_at: '2020-03-24 12:43:23',
								updated_at: '2020-03-26 11:11:50',
								deleted_at: null,
							},
						],
					},
				],
			},
		},
	},
	comerciais: {
		informations: {
			page: {
				id: 3,
				title: 'Comerciais',
				slug: 'comerciais',
				icon: 'icon-factory',
				description: 'A imobiliária',
				keywords: 'Imobiliária',
				created_at: '2020-03-16 10:53:24',
				updated_at: '2020-03-27 10:02:52',
				deleted_at: null,
				fields: [],
			},
		},
	},
	residenciais: {
		informations: {
			page: {
				id: 2,
				title: 'Residenciais',
				slug: 'residenciais',
				icon: 'icon-garage',
				description: 'A imobiliária',
				keywords: 'Imobiliária',
				created_at: '2020-02-18 18:08:59',
				updated_at: '2020-03-27 10:33:34',
				deleted_at: null,
				fields: [],
			},
		},
	},
	comedic: {
		informations: {
			page: {
				id: 4,
				title: 'Comedic',
				slug: 'comedic',
				icon: 'icon-hospital-building',
				description: 'A imobiliária',
				keywords: 'Imobiliária',
				created_at: '2020-03-16 10:53:36',
				updated_at: '2020-03-27 17:15:31',
				deleted_at: null,
				fields: [],
			},
		},
	},
	medical: {
		informations: {
			page: {
				id: 11,
				title: 'Clinicas Médicas',
				slug: 'clinicas-medicas',
				icon: 'icon-hospital-marker',
				description: 'Clínicas Médicas',
				keywords: 'clínica, médicos',
				created_at: '2020-03-16 10:53:36',
				updated_at: '2020-03-27 17:15:31',
				deleted_at: null,
				fields: [],
			},
		},
	},
	detalhes: {
		informations: {
			id: 7,
			title: 'Detalhes de um imóvel',
			slug: 'detalhes-de-um-imovel',
			icon: 'icon-city',
			description: 'A imobiliária',
			keywords: 'Imobiliária',
			created_at: '2020-03-16 17:19:44',
			updated_at: '2020-03-27 10:33:14',
			deleted_at: null,
			fields: [
				{
					id: 21,
					title: '[Destaque de um Imóvel] Título do Bloco',
					description: 'Texto apresentado no título do Bloco',
					slug: 'destaque-de-um-imovel-titulo-do-bloco',
					type: 'Texto simples',
					page_id: 7,
					order: 0,
					created_at: '2020-03-17 09:40:41',
					updated_at: '2020-03-17 09:40:41',
					deleted_at: null,
					values: [
						{
							id: 13,
							content: 'Destaque do imóvel',
							order: 0,
							field_id: 21,
							created_at: '2020-03-17 09:41:12',
							updated_at: '2020-03-17 09:41:12',
							deleted_at: null,
						},
					],
				},
				{
					id: 20,
					title: '[Formulário de contato] Título da seção',
					description: 'Texto apresentado no início da seção',
					slug: 'formulario-de-contato-titulo-da-secao',
					type: 'Texto simples',
					page_id: 7,
					order: 0,
					created_at: '2020-03-16 17:20:51',
					updated_at: '2020-03-16 17:20:51',
					deleted_at: null,
					values: [
						{
							id: 12,
							content: 'Saiba mais sobre este imóvel',
							order: 0,
							field_id: 20,
							created_at: '2020-03-16 17:21:09',
							updated_at: '2020-03-16 17:21:09',
							deleted_at: null,
						},
					],
				},
			],
		},
	},
};

export default function pages(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_HOME_PAGE_INFORMATIONS:
			return {
				...state,
				home: {
					...state.home,
					informations: action.payload,
				},
			};
		case SET_HOME_PAGE_RANDOM_IMMOBILE:
			return {
				...state,
				home: {
					...state.home,
					randomImmobile: action.payload,
				},
			};
		case SET_INSTITUCIONAL_PAGE_INFORMATIONS:
			return {
				...state,
				institucional: {
					...state.institucional,
					informations: action.payload,
				},
			};
		case SET_COMERCIAIS_PAGE_INFORMATIONS:
			return {
				...state,
				comerciais: {
					...state.comerciais,
					informations: action.payload,
				},
			};
		case SET_RESIDENCIAIS_PAGE_INFORMATIONS:
			return {
				...state,
				residenciais: {
					...state.residenciais,
					informations: action.payload,
				},
			};
		case SET_COMEDIC_PAGE_INFORMATIONS:
			return {
				...state,
				comedic: {
					...state.comedic,
					informations: action.payload,
				},
			};
		case SET_MEDICAL_PAGE_INFORMATIONS:
			return {
				...state,
				medical: {
					...state.medical,
					informations: action.payload,
				},
			};
		case SET_CONSTRUCTION_DETAILS_PAGE_INFORMATIONS:
			return {
				...state,
				detalhes: {
					...state.detalhes,
					informations: action.payload,
				},
			};
		default:
			return state;
	}
}
