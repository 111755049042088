import styled from 'styled-components';
import device from '../../../utils/devices';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 7rem 0 7rem 0;
	background: #fff;
`;

export const ActionsTriggers = styled.div`
	.page-item.active .page-link {
		background-color: #fff;
		border-color: #fff;
		color: #585858;
		font-weight: bold;
	}
	.page-link {
		color: #585858;
		border: 1px solid #fff;
	}

	@media ${device.mobileS} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media ${device.tablet} {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	@media ${device.laptop} {
		flex-direction: row;
		justify-content: flex-end;
		margin-top: 40px;
	}

	@media ${device.laptopL} {
		flex-direction: row;
		justify-content: flex-end;
		margin-top: 40px;
	}
`;

export const TextWrapper = styled.div`
	@media ${device.mobileS} {
		padding-top: 2px;
		padding-bottom: 10px;
		text-align: center;
	}

	@media ${device.tablet} {
		padding-top: 2px;
		padding-bottom: 10px;
	}

	@media ${device.laptop} {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 5;
		margin-top: 10px;
	}

	@media ${device.laptopL} {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 5;
		margin-top: 10px;
	}
`;

export const Title = styled.h5`
	@media ${device.mobileS} {
		letter-spacing: 0.1em;
		font-size: 1.3em;
		font-weight: bold;
	}

	@media ${device.tablet} {
		letter-spacing: 0.1em;
		font-size: 1.3em;
		font-weight: bold;
	}

	@media ${device.laptop} {
		letter-spacing: 0.1em;
		font-size: 1.3em;
		font-weight: bold;
		text-align: start;
	}

	@media ${device.laptopL} {
		letter-spacing: 0.1em;
		font-size: 1.3em;
		font-weight: bold;
		text-align: start;
	}
`;

export const Subtitle = styled.span`
	@media ${device.mobileS} {
		letter-spacing: 0.1em;
		font-size: 1.3em;
		color: #585858;
	}

	@media ${device.tablet} {
		letter-spacing: 0.1em;
		font-size: 1.3em;
		color: #585858;
	}

	@media ${device.laptop} {
		letter-spacing: 0.1em;
		font-size: 1.3em;
		color: #585858;
		margin-left: 0.8rem;
	}

	@media ${device.laptopL} {
		letter-spacing: 0.1em;
		font-size: 1.3em;
		color: #585858;
	}
`;

export const Apartments = styled.div`
	@media ${device.mobileS} {
		display: grid;
		background: transparent;
		justify-content: center;
		align-items: center;
		grid-template-columns: repeat(1, 340px);
		grid-template-rows: repeat(3, 1fr);
		row-gap: 30px;
	}

	@media ${device.tablet} {
		display: grid;
		background: transparent;
		justify-content: center;
		align-items: center;
		grid-template-columns: repeat(1, 450px);
		grid-template-rows: repeat(3, 1frpx);
		column-gap: 30px;
		row-gap: 30px;
	}

	@media ${device.laptop} {
		display: grid;
		background: transparent;
		justify-content: center;
		align-items: center;
		grid-template-columns: repeat(3, 400px);
		grid-template-rows: repeat(1, 420px);
		column-gap: 10px;
	}

	@media ${device.laptopL} {
		display: grid;
		background: transparent;
		justify-content: center;
		align-items: center;
		grid-template-columns: repeat(3, 420px);
		grid-template-rows: repeat(1, 420px);
		column-gap: 30px;
	}
`;

export const SelectStyle = styled.select`
	@media ${device.mobileS} {
		border: 0;
		padding: 0.5rem;
		margin: 0.5rem;
		width: 80%;
		background: transparent;
		border: 1px solid #363636;
		color: #585858;
	}

	@media ${device.tablet} {
		border: 0;
		padding: 0.5rem;
		margin: 0.5rem;
		width: 80%;
		background: transparent;
		border: 1px solid #363636;
		color: #585858;
	}

	@media ${device.laptop} {
		border: 0;
		padding: 0.85rem;
		margin-bottom: 5px;
		width: 200px;
		background: transparent;
		border: 1px solid #363636;
		color: #585858;
	}

	@media ${device.laptopL} {
		border: 0;
		padding: 0.85rem;
		margin-bottom: 5px;
		width: 225px;
		background: transparent;
		border: 1px solid #363636;
		color: #585858;
	}
`;

export const SelectOption = styled.option`
	@media ${device.laptop} {
		margin-bottom: 5px;
	}

	@media ${device.laptopL} {
		margin-bottom: 5px;
	}
`;
