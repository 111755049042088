import styled from 'styled-components';
import Devices from '../../../utils/devices';

export const InputField = styled.input.attrs(props => ({
	height: props.height || 'auto',
	margin: props.margin || '1em',
	padding: props.padding || '1em',
	borderColor: props.borderColor || '#fff9',
	textColor: props.textColor || '#ddd',
}))`
	border: none;
	background-image: none;
	background-color: transparent;
	border: 1px solid ${props => props.borderColor};
	color: ${props => props.textColor};
	letter-spacing: 0.1rem;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	height: ${props => props.height};
	padding: ${props => props.padding};
	margin: ${props => props.margin};
	-webkit-transition: 0.5s;
	transition: 0.5s ease-out;
	outline: none;

	::placeholder {
		color: #ddd;
		letter-spacing: 0.1rem;
		text-transform: uppercase;
		/* Firefox */
	}

	:-ms-input-placeholder {
		/* Internet Explorer 10-11 */
		color: #fff;
		letter-spacing: 0.1rem;
	}

	::-ms-input-placeholder {
		/* Microsoft Edge */
		color: #fff;
		letter-spacing: 0.1rem;
	}

	:focus {
		border: 1px solid #fff;
		background-color: #ddd8;
	}

	@media ${Devices.mobileS} {
		width: 100%;
	}

	@media ${Devices.tablet} {
		width: 100%;
	}

	@media ${Devices.laptop} {
		width: 100%;
	}

	@media ${Devices.laptopL} {
		width: 100%;
	}
`;
