import React from 'react';

import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import 'bootstrap/dist/css/bootstrap.min.css';

import Router from './routes';
import { constantUtil } from './utils';

const history = createBrowserHistory();

ReactGA.initialize(constantUtil.keys.googleAnalytics);

history.listen(location => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

ReactDOM.render(<Router />, document.getElementById('root'));
