import React, { useEffect, useState, useCallback } from 'react';

import { Link, withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import whiteLogo from '../../../assets/images/logo-white.png';
import defaultLogo from '../../../assets/images/logo-normal.png';
import { FaBars, FaTimes } from 'react-icons/fa';
import * as S from './styles';

function Header(props) {
	const [showMenu, setShowMenu] = useState(false);

	const toggleMenu = useCallback(
		event => {
			if (document.documentElement.clientWidth <= 1199) {
				if (event) {
					event.preventDefault();
				}
				setShowMenu(!showMenu);

			}
		},
		[showMenu],
	);

	const animationMenu = useCallback(() => {
		let prevScrollpos = window.pageYOffset;

		window.onscroll = function () {
			let currentScrollPos = window.pageYOffset;

			if (currentScrollPos === 0) {
				if (props.history.location.pathname === '/') {
					document.getElementById('navbar').style.background = 'transparent';
				}
			} else {
				if (props.history.location.pathname === '/') {
					document.getElementById('navbar').style.background =
						'rgba(0,0,0,0.6)';
				}
			}

			if (prevScrollpos > currentScrollPos) {
				if (props.history.location.pathname === '/') {
					document.getElementById('navbar').style.top = '0';
				}
			} else {
				if (props.history.location.pathname === '/') {
					document.getElementById('navbar').style.top = '-250px';
				}
			}
			prevScrollpos = currentScrollPos;
		};
	}, [props.history.location.pathname]);

	return (
		<>
			<div id="menu-mobile" className={`${showMenu ? 'd-flex' : 'd-none'}`}>
				<a href="" onClick={e => toggleMenu(e)} className="close">
					<FaTimes color="#000" />
				</a>
				<ul className="m-0 d-flex flex-column w-100 justify-content-center align-items-center h-100">
					<li className="text-uppercase pb-5">
						<Link
							to="/residenciais"
							className={`${
								props.history.location.pathname.indexOf('/residenciais') > -1
									? 'active'
									: ''
							}`}
						>
							RESIDENCIAIS
						</Link>
					</li>
					<li className="text-uppercase pb-5">
						<Link
							to="/comerciais"
							className={`${
								props.history.location.pathname.indexOf('/comerciais') > -1
									? 'active'
									: ''
							}`}
						>
							COMERCIAIS
						</Link>
					</li>
					<li className="text-uppercase pb-5">
						<Link
							to="/coworkings"
							className={`${
								props.history.location.pathname.indexOf('/coworkings') > -1
									? 'active'
									: ''
							}`}
						>
							COWORKINGS
						</Link>
					</li>
					<li className="text-uppercase pb-5">
						<Link
							to="/clinicas-medicas"
							className={`${
								props.history.location.pathname.indexOf('/clinicas-medicas') >
								-1
									? 'active'
									: ''
							}`}
						>
							Clínicas Médicas
						</Link>
					</li>
					<li className="text-uppercase">
						<Link
							to="/institucional"
							className={`${
								props.history.location.pathname.indexOf('/institucional') > -1
									? 'active'
									: ''
							}`}
						>
							INSTITUCIONAL
						</Link>
					</li>
				</ul>
			</div>
			<header
				id="navbar"
				className={`container-fluid  adjust-header ${
					props.history.location.pathname === '/' ? 'position-fixed' : ''
				}`}
				style={{
					background: props.history.location.pathname === '/' ? 'rgba(75, 75, 77, 0.5)' : '#fff',
				}}
			>
				<Row className="p-lg-5 d-none d-sm-none d-md-none d-lg-none d-xl-flex">
					<Col
						className="d-none d-sm-none d-md-none d-lg-none d-xl-flex justify-content-center align-items-center"
						lg={3}
						md={12}
						sm={12}
					>
						<Link to="/">
							<img
								width="262px"
								className="img-fluid"
								src={
									props.history.location.pathname === '/'
										? whiteLogo
										: defaultLogo
								}
								alt="brand"
							/>
						</Link>
					</Col>
					<Col
						lg={9}
						md={12}
						sm={12}
						className="d-flex d-flex justify-content-around"
					>
						<ul
							className={`m-0 d-flex justify-content-around align-items-center h-100 ${
								props.history.location.pathname !== '/' ? 'internal-page' : ''
							}`}
						>
							<S.UnorderedItem>
								<Link
									to="/residenciais"
									className={`${
										props.history.location.pathname.indexOf('/residenciais') >
										-1
											? 'active'
											: ''
									}`}
								>
									RESIDENCIAIS
								</Link>
							</S.UnorderedItem>
							<S.UnorderedItem>
								<Link
									to="/comerciais"
									className={`${
										props.history.location.pathname.indexOf('/comerciais') > -1
											? 'active'
											: ''
									}`}
								>
									COMERCIAIS
								</Link>
							</S.UnorderedItem>
							<S.UnorderedItem>
								<Link
									to="/coworkings"
									className={`${
										props.history.location.pathname.indexOf('/coworkings') > -1
											? 'active'
											: ''
									}`}
								>
									Coworkings
								</Link>
							</S.UnorderedItem>
							<S.UnorderedItem>
								<Link
									to="/clinicas-medicas"
									className={`${
										props.history.location.pathname.indexOf(
											'/clinicas-medicas',
										) > -1
											? 'active'
											: ''
									}`}
								>
									Clínicas médicas
								</Link>
							</S.UnorderedItem>
							<S.UnorderedItem>
								<Link
									to="/institucional"
									className={`${
										props.history.location.pathname.indexOf('/institucional') >
										-1
											? 'active'
											: ''
									}`}
								>
									Institucional
								</Link>
							</S.UnorderedItem>
						</ul>
					</Col>
				</Row>
				<Row className="d-block d-sm-block d-md-block d-lg-block d-xl-none py-4 px-2">
					<Col
						className="d-flex justify-content-end align-items-center"
						sm={12}
						md={12}
					>
						<ul className="d-flex justify-content-between align-items-center w-100 m-0">
							<li>
								<Link to="/">
									<img
										width="160px"
										className="img-fluid"
										src={
											props.history.location.pathname === '/'
												? whiteLogo
												: defaultLogo
										}
										alt="brand"
									/>
								</Link>
							</li>
							<li>
								<a
									href="#"
									className="login-button p-3 shadow"
									rel="noopener noreferrer"
									onClick={e => toggleMenu(e)}
								>
									<FaBars color="#fff" />
								</a>
							</li>
						</ul>
					</Col>
				</Row>
			</header>
		</>
	);
}

export default withRouter(Header);
