import React, { useState } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';

import { store, persistor } from './store';
import GlobalStyle from './styles/global';
import Home from './pages/Home';
import PropertiesList from './pages/PropertiesList';
import Institucional from './pages/Institucional';
import PropertiDetails from './pages/PropertiDetails';
import ScrollToTop from './components/atoms/ScrollToTopButton/';
import WhatsAppFloatButton from './components/atoms/WhatsAppFloatButton/';
import HelpButton from './components/atoms/HelpButton/';

export default function Routes() {
	const [scrollVisible, setScrollVisible] = useState(false);

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter>
					<ScrollToTop state={scrollVisible} setState={setScrollVisible} />
					<HelpButton />
					<WhatsAppFloatButton />
					<Switch>
						<Route path="/" exact component={Home} />
						<Route path="/institucional" exact component={Institucional} />
						<Route path="/:type" exact component={PropertiesList} />
						<Route
							path="/:type/:id/detalhes"
							exact
							component={PropertiDetails}
						/>
						<Redirect from="*" to="/" />
					</Switch>
					<GlobalStyle />
				</BrowserRouter>
			</PersistGate>
		</Provider>
	);
}
