import React, { useEffect, useCallback, useState } from 'react';
import { FiArrowUp } from 'react-icons/fi';
import Media from 'react-media';

import * as S from './styles';

const ScrollButton = ({ state = false, setState }) => {
	const toogleVisibility = useCallback(() => {
		return window.pageYOffset > 300 ? setState(true) : setState(false);
	});

	useEffect(() => {
		document.addEventListener('scroll', function (event) {
			toogleVisibility();
		});
	}, [toogleVisibility]);

	const scrollToTop = useCallback(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	});

	return (
		<S.Wrapper>
			{state && (
				<Media
					query="(max-width: 768px)"
					render={() => (
						<S.Container onClick={() => scrollToTop()}>
							<FiArrowUp size={30} color="#ffffff" />
						</S.Container>
					)}
				/>
			)}
		</S.Wrapper>
	);
};

export default ScrollButton;
