import styled from 'styled-components';

export const AbsoluteInfo = styled.div`
	max-width: 100%;
	height: auto;
	background-color: #ececec;
	padding: 3rem;
`;

export const IconWrapperColor = styled.div`
	background: ${props => props.bg};
	border-radius: 20px 20px 20px 0;
	width: 59px;
	height: 59px;
	text-align: center;
	margin-right: 30px;
`;

export const ContactHeader = styled.h3`
	text-transform: uppercase;
	font-size: 1.5em;
	color: #afafaf;
	letter-spacing: 1px;
	font-weight: lighter;
	margin-top: 10px;
	padding-bottom: 5px;
	border-bottom: 1px solid #afafaf;
	width: 100%;
`;

export const ContactSubtitle = styled.h4`
	text-transform: uppercase;
	font-size: 1.25em;
	color: #afafaf;
	letter-spacing: 1px;
	line-height: 2rem;
`;

export const StyledIcons = styled.div`
	a {
		vertical-align: middle;
		margin-left: 10px;
	}
`;
