import Elevator from "../assets/svg/elevator.svg";
import Bike from "../assets/svg/bike-parking.svg";
import Balcony from "../assets/svg/balcony.svg";
import Drink from "../assets/svg/drink.svg";
import Dumbell from "../assets/svg/dumbell.svg";
import Joystick from "../assets/svg/joystick.svg";
import Playground from "../assets/svg/playground.svg";
import PlaygroundTwo from "../assets/svg/playground-two.svg";
import Pools from "../assets/svg/pool.svg";
import Rocking from "../assets/svg/rocking-horse.svg";
import Spa from "../assets/svg/spa.svg";
import Bbq from "../assets/svg/bbq.svg";
import Whatsapp from "../assets/svg/whatsapp.svg";
import Email from "../assets/svg/email.svg";
import Speak from "../assets/svg/speak.svg";
import businessWoman from "../assets/svg/businesswoman.svg";
import LivingRoom from "../assets/svg/living-room.svg";
import Bathroom from "../assets/svg/bathroom.svg";
import Architecture from "../assets/svg/architecture.svg";
import Bed from "../assets/svg/bed.svg";
import RedWrapper from "../assets/svg/red-wrapper.svg";
import Website from "../assets/svg/website.svg";
import Garage from "../assets/svg/garage.svg";
import Lock from "../assets/svg/lock.svg";
import Wifi from "../assets/svg/wifi.svg";
import Car from "../assets/svg/car.svg";
import Mail from "../assets/svg/mail.svg";
import Print from "../assets/svg/print.svg";
import Elipse from "../assets/svg/elipse.svg";
import Living from "../assets/svg/dinning-room.svg";
import Kitchen from "../assets/svg/kitchen.svg";
import Gas from "../assets/svg/gas-station.svg";
import Sauna from "../assets/svg/sauna.svg";
import Tea from "../assets/svg/tea.svg";
import Running from "../assets/svg/running.svg";
import Net from "../assets/svg/net.svg";
import Food from "../assets/svg/room-service.svg";
import Room from "../assets/svg/room.svg";
import Soccer from "../assets/svg/soccer-field.svg";
import Train from "../assets/svg/train-toy.svg";
import camera from "../assets/svg/security-camera.png";
import desk from "../assets/svg/working.png";
import phone from "../assets/svg/phone.png";
import phoneCall from "../assets/svg/phone-call.svg";
import circle from "../assets/svg/circle.png";

export default {
	phone,
	phoneWhite: phoneCall,
	desk,
	camera,
	circle,
	dinner: Kitchen,
	elevator: Elevator,
	lift: Elevator,
	bike: Bike,
	balcony: Balcony,
	drink: Drink,
	dumbell: Dumbell,
	joystick: Joystick,
	playground: Playground,
	square: PlaygroundTwo,
	pool: Pools,
	rocking: Rocking,
	spa: Spa,
	bbq: Bbq,
	whatsapp: Whatsapp,
	email: Email,
	speak: Speak,
	businessWoman,
	LivingRoom,
	Bathroom,
	Architecture,
	Bed,
	RedWrapper,
	website: Website,
	garage: Garage,
	car: Car,
	lock: Lock,
	wifi: Wifi,
	mail: Mail,
	print: Print,
	pattern: Elipse,
	living: Living,
	sofa: Living,
	kitchen: Kitchen,
	gas: Gas,
	sauna: Sauna,
	tea: Tea,
	running: Running,
	net: Net,
	service: Food,
	room: Room,
	soccer: Soccer,
	train: Train,
	food: Food,
};
