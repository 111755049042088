import React, { useEffect, useState, useCallback, Fragment } from "react";
import { Row, Col, Image, FormControl } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import { useDispatch, useSelector } from "react-redux";
import InputMask from "react-input-mask";
import ReactHtmlParser from "react-html-parser";
import { useForm } from "react-hook-form";
import Currency from "react-currency-formatter";
import Media from "react-media";
import Header from "../../components/template/Header";
import MetaTags from "../../components/atoms/Seo";
import Pin from "../../components/atoms/Pin";
import { constantUtil } from "../../utils/";
import * as S from "./styles";
import DefaultImage from "../../assets/images/static/dumb-bg.png";
import Footer from "../../components/template/FooterGeneral";
import { SET_CONSTRUCTION_DETAILS_PAGE_INFORMATIONS } from "../../store/actions/page";
import { SET_VARIABLES } from "../../store/actions/variables";
import getFieldValue from "../../helpers/fields";
import Svg from "../../utils/svg";
import { baseUrlOfGCS } from "../../constants/urls";
import { apiService, toastService } from "../../services";
import ComodityIcon from "../../assets/images/static/pattern.png";

function PropertiDetails(props) {
	const dispatch = useDispatch();
	const page = useSelector((state) => state.page.detalhes.informations);
	const [details, setDetails] = useState(null);
	const [contentLoading, setContentLoading] = useState(true);
	const [id, setId] = useState("");
	const [name, setNameValue] = useState("");
	const [email, setEmailValue] = useState("");
	const [phone, setPhoneValue] = useState("");
	const [, setFeaturesTitle] = useState("");
	const googleTrackingId = constantUtil.keys.googleAnalytics;
	const { register, handleSubmit, errors } = useForm();

	const onSubmit = useCallback(async (data) => {
		if (data === undefined) return null;
		await apiService
			.post("api/manager/contato", data)
			.then((response) => {
				toastService.showToast(
					constantUtil.toasts.success,
					response?.data?.message
				);
				setNameValue("");
				setEmailValue("");
				setPhoneValue("");
			})
			.catch((error) => {
				toastService.showToast(
					constantUtil.toasts.error,
					error.response?.data?.message
				);
			});
	}, []);

	const getHightLightImage = useCallback(() => {
		if (details?.images.length > 0) {
			const image_url = details?.images.filter(
				({ featured }) => featured === 1
			)[0];
			if (!image_url) return DefaultImage;

			return image_url?.image_uri.indexOf("http") > -1
				? image_url?.image_uri
				: baseUrlOfGCS + image_url?.image_uri;
		} else {
			return DefaultImage;
		}
	}, [details]);

	useEffect(() => {
		if (id) {
			setContentLoading(true);
			getConstructionDetails(id)
				.then((response) => {
					setDetails(response.data);
					window.gtag("event", "view_building_details", {
						type: response.data.type,
						name: response.data.name,
					});
				})
				.catch(() => {
					toastService.showToast(
						constantUtil.toasts.error,
						"Este imóvel não existe em nosso banco de dados. Entre em contato via chat informando o link que você desejou acessar."
					);

					props.history.push(`/${props.match.params.type}`);
				})
				.finally(() => {
					setContentLoading(false);
				});
		}
	}, [id, props.history, props.match.params.type]);

	useEffect(() => {
		window.gtag("config", googleTrackingId, {
			page_path: props.location.pathname,
		});

		getPageData("manager", "variables").then((response) => {
			if (response.status === 200) {
				dispatch({
					type: SET_VARIABLES,
					payload: response.data,
				});
			}
		});

		getPageData("website", "detalhes-de-um-imovel").then((response) => {
			if (response.status === 200) {
				dispatch({
					type: SET_CONSTRUCTION_DETAILS_PAGE_INFORMATIONS,
					payload: response.data.page,
				});
			}
		});

		setId(props.match.params.id);
	}, [
		dispatch,
		googleTrackingId,
		props.location.pathname,
		props.match.params.id,
	]);

	useEffect(() => {
		setFeaturesTitle(getFieldValue(page.fields, 21).content);
		window.gtag("config", googleTrackingId, { page_path: page.location });
	}, [page, details, googleTrackingId]);

	const flatInfo = [
		{
			header: "Área Construída",
			title: `${
				parseInt(details?.houses?.area) ? details?.houses?.area : ""
			} m²`,
			icon: Svg.Architecture,
			exist: parseInt(details?.houses?.area) !== null,
		},
		{
			header: "Dormitório(s)",
			title: `${
				parseInt(details?.houses?.bedrooms_amount)
					? details?.houses?.bedrooms_amount
					: ""
			}`,
			icon: Svg.Bed,
			exist: parseInt(details?.houses?.bedrooms_amount) !== 0,
		},
		{
			header: "Banheiro(s)",
			title: `${
				parseInt(details?.houses?.bathroom_amount)
					? details?.houses?.bathroom_amount
					: ""
			}`,
			icon: Svg.Bathroom,
			exist: parseInt(details?.houses?.bathroom_amount) !== 0,
		},
		{
			header: "Mobilidada",
			title: `${
				parseInt(details?.houses?.furnished) ? details?.houses?.furnished : ""
			}`,
			icon: parseInt(details?.houses?.furnished) !== 0 ? Svg.LivingRoom : "",
			exist: parseInt(details?.houses?.furnished) !== 0,
		},
		{
			header: "Vaga(s)",
			title: `${
				parseInt(details?.houses?.garages_amount)
					? details?.houses?.garages_amount
					: ""
			}`,
			icon: parseInt(details?.houses?.garages_amount) !== 0 ? Svg.garage : "",
			exist: parseInt(details?.houses?.garages_amount) !== 0,
		},
	];

	const detailsData = [
		{
			header: "Quarto(s)",
			title: `${
				parseInt(details?.apartments?.bedrooms_amount)
					? details?.apartments?.bedrooms_amount
					: null
			}`,
			icon:
				parseInt(details?.apartments?.bedrooms_amount) !== 0
					? Svg.Architecture
					: "",
			exist: details?.apartments?.bedrooms_amount !== 0,
		},
		{
			header: "Suíte(s)",
			title: `${
				parseInt(details?.apartments?.suites_amount)
					? details?.apartments?.suites_amount
					: ""
			}`,
			icon:
				parseInt(details?.apartments?.suites_amount) !== 0
					? Svg.Architecture
					: "",
			exist: details?.apartments?.suites_amount !== 0,
		},
		{
			header: "Garagem",
			title: `${
				parseInt(details?.apartments?.garages_amount)
					? details?.apartments?.garages_amount
					: ""
			}`,
			icon:
				parseInt(details?.apartments?.garages_amount) !== 0 ? Svg.garage : "",
			exist: details?.apartments?.garages_amount !== 0,
		},
		{
			header: "Banheiro(s)",
			title: `${
				parseInt(details?.apartments?.bathroom_amount)
					? details?.apartments?.bathroom_amount
					: ""
			}`,
			icon:
				parseInt(details?.apartments?.bathroom_amount) !== 0
					? Svg.Architecture
					: "",
			exist: details?.apartments?.bathroom_amount !== 0,
		},
	];

	const detailsInfraData = [
		{
			label: "Área Construída",
			header: "Área Construída",
			title: `${details?.apartments?.building_area} m²`,
			icon:
				parseInt(details?.apartments?.building_area) !== 0 || 0
					? Svg.Architecture
					: "",
			exist:
				details?.apartments !== null &&
				details?.apartments?.building_area !== null,
		},
	];

	// const detailsCommercialsTraits = [
	// 	{
	// 		header: "Quarto(s)",
	// 		title: `${
	// 			parseInt(details?.commercials?.bedrooms_amount)
	// 				? details?.commercials?.bedrooms_amount
	// 				: null
	// 		}`,
	// 		icon:
	// 			parseInt(details?.commercials?.bedrooms_amount) !== 0
	// 				? Svg.Architecture
	// 				: "",
	// 		exist: details?.commercials?.bedrooms_amount !== 0,
	// 	},
	// 	{
	// 		header: "Suíte(s)",
	// 		title: `${
	// 			parseInt(details?.commercials?.suites_amount)
	// 				? details?.commercials?.suites_amount
	// 				: ""
	// 		}`,
	// 		icon:
	// 			parseInt(details?.commercials?.suites_amount) !== 0
	// 				? Svg.Architecture
	// 				: "",
	// 		exist: details?.commercials?.suites_amount !== 0,
	// 	},
	// 	{
	// 		header: "Garagem",
	// 		title: `${
	// 			parseInt(details?.commercials?.garages_amount)
	// 				? details?.commercials?.garages_amount
	// 				: ""
	// 		}`,
	// 		icon:
	// 			parseInt(details?.commercials?.garages_amount) !== 0 ? Svg.garage : "",
	// 		exist: details?.commercials?.garages_amount !== 0,
	// 	},
	// 	{
	// 		header: "Banheiro(s)",
	// 		title: `${
	// 			parseInt(details?.commercials?.bathroom_amount)
	// 				? details?.commercials?.bathroom_amount
	// 				: ""
	// 		}`,
	// 		icon:
	// 			parseInt(details?.commercials?.bathroom_amount) !== 0
	// 				? Svg.Architecture
	// 				: "",
	// 		exist: details?.commercials?.bathroom_amount !== 0,
	// 	},
	// ];

	const apartmentInfo = [
		{
			header: "Cômodo(s)",
			title: `${
				parseInt(details?.apartments?.comfortable)
					? details?.apartments?.comfortable
					: ""
			}`,
			icon:
				parseInt(details?.apartments?.comfortable) !== 0
					? Svg.Architecture
					: "",
			exist: details?.apartments?.comfortable !== null,
		},
		{
			header: "Quarto(s)",
			title: `${
				parseInt(details?.apartments?.bedrooms_amount)
					? details?.apartments?.bedrooms_amount
					: null
			}`,
			icon:
				parseInt(details?.apartments?.bedrooms_amount) !== 0
					? Svg.Architecture
					: "",
			exist: details?.apartments?.bedrooms_amount !== 0,
		},
		{
			header: "Banheiro(s)",
			title: `${
				parseInt(details?.apartments?.bathroom_amount)
					? details?.apartments?.bathroom_amount
					: ""
			}`,
			icon:
				parseInt(details?.apartments?.bathroom_amount) !== 0
					? Svg.Architecture
					: "",
			exist: details?.apartments?.bathroom_amount !== 0,
		},
		{
			header: "Vaga(s)",
			title: `${
				parseInt(details?.apartments?.garages_amount)
					? details?.apartments?.garages_amount
					: ""
			}`,
			icon:
				parseInt(details?.apartments?.garages_amount) !== 0 ? Svg.garage : "",
			exist: details?.apartments?.garages_amount !== 0,
		},
		{
			header: "Suíte(s)",
			title: `${
				parseInt(details?.apartments?.suites_amount)
					? details?.apartments?.suites_amount
					: ""
			}`,
			icon:
				parseInt(details?.apartments?.suites_amount) !== 0
					? Svg.Architecture
					: "",
			exist: details?.apartments?.suites_amount !== 0,
		},
		{
			header: "Mobilidado",
			title: `${parseInt(details?.apartments?.furnished) ? "Sim" : "Não"}`,
			icon:
				parseInt(details?.apartments?.furnished) !== 0 ? Svg.Architecture : "",
			exist: false,
		},
	];

	const technicalInfo = [
		{
			label: "Construtora",
			title: details?.construction_company_name,
			exists: details?.construction_company_name,
		},
		// {
		// 	label: "Cômodo(s)",
		// 	title:
		// 		details?.houses?.comfortable || details?.apartments?.comfortable || "0",
		// 	exists:
		// 		(details?.houses?.comfortable !== 0 && details?.houses !== null) ||
		// 		(details?.apartments !== null &&
		// 			details?.apartments?.comfortable !== 0),
		// },
		{
			label: "Dormitório(s)",
			title:
				details?.houses?.bedrooms_amount ||
				details?.apartments?.bedrooms_amount ||
				"0",
			exists:
				(parseInt(details?.houses?.bedrooms_amount) !== 0 &&
					details?.houses !== null) ||
				details?.apartments !== null,
		},
		{
			label: "Suíte(s)",
			title:
				details?.houses?.suites_amount ||
				details?.apartments?.suites_amount ||
				"0",
			exists:
				(parseInt(details?.houses?.suites_amount) !== 0 &&
					details?.houses !== null) ||
				details?.apartments !== null,
		},
		{
			label: "Banheiro(s)",
			title:
				details?.houses?.bathroom_amount ||
				details?.apartments?.bathroom_amount ||
				"0",
			exists:
				(details?.houses?.bathroom_amount !== 0 && details?.houses !== null) ||
				(details?.apartments !== null &&
					details?.apartments?.bathroom_amount !== 0),
		},
		{
			label: "Área construída",
			title:
				parseInt(details?.houses?.building_area) || 0
					? details?.houses?.building_area + " m²"
					: "" || parseInt(details?.apartments?.building_area) || 0
					? details?.apartments?.building_area + " m²"
					: "",
			exists:
				(details?.houses?.building_area && details?.houses !== null) ||
				(details?.apartments !== null &&
					details?.apartments?.building_area !== null),
		},
		// {
		// 	label: "Área total",
		// 	title:
		// 		parseInt(details?.houses?.total_area) || 0
		// 			? details?.houses?.total_area + " m²"
		// 			: "" || parseInt(details?.apartments?.total_area) || 0
		// 				? details?.apartments?.total_area + " m²"
		// 				: "",
		// 	exists:
		// 		(details?.houses?.total_area !== null && details?.houses !== null) ||
		// 		(details?.apartments !== null &&
		// 			details?.apartments?.total_area !== null),
		// },
		{
			label: "Vaga(s)",
			title:
				details?.houses?.garages_amount || details?.apartments?.garages_amount,
			exists:
				(details?.houses?.garages_amount !== 0 &&
					details?.houses?.garages_amount !== null) ||
				details?.apartments?.garages_amount !== 0,
		},
		{
			label: "Mobiliado",
			title: details?.houses?.furnished ? "Sim" : "Não",
			exists: false,
			isTechnical: details?.houses?.furnished !== null,
		},
		// {
		// 	label: "Pé direito",
		// 	title:
		// 		parseInt(details?.houses?.right_foot) || 0
		// 			? details?.houses?.right_foot + " m²"
		// 			: "" || parseInt(details?.apartments?.right_foot) || 0
		// 				? details?.apartments?.right_foot + " m²"
		// 				: "",
		// 	exists:
		// 		(details?.houses?.right_foot !== null && details?.houses !== null) ||
		// 		(details?.apartments !== null &&
		// 			details?.apartments?.right_foot !== null),
		// },
	];

	const storesInfra = [
		{
			label: "Área Construída",
			header: "Área Construída",
			title: `${
				details?.commercials?.building_area !== null || 0
					? details?.commercials?.building_area + " m²"
					: ""
			}`,
			icon:
				parseInt(details?.commercials?.building_area) !== 0 || 0
					? Svg.Architecture
					: "",
			exist:
				(details?.houses?.building_area && details?.houses !== null) ||
				(details?.apartments !== null &&
					details?.apartments?.building_area !== null),
		},
	];

	const storesInfo = [
		{
			label: "Construtora",
			header: "Nome",
			title: details?.construction_company_name || "Nenhum",
			icon: details?.construction_company_name !== 0 ? Svg.LivingRoom : "",
			exist:
				details?.construction_company_name !== "" &&
				details?.commercials !== null,
		},
		{
			label: "Vaga(s)",
			header: "Vaga(s)",
			title:
				details?.commercials?.garage_amount !== 0
					? details?.commercials?.garage_amount
					: "",
			icon: details?.commercials?.garage_amount !== 0 ? Svg.garage : "",
			exist:
				details?.commercials?.garage_amount !== 0 &&
				details?.commercials !== null,
		},
		{
			label: "Cômodo(s)",
			header: "Cômodo(s)",
			title:
				details?.commercials?.comfortable !== 0
					? details?.commercials?.comfortable
					: "",
			icon: details?.commercials?.comfortable !== 0 ? Svg.living : "",
			exist:
				details?.commercials?.comfortable !== null &&
				details?.commercials !== null,
		},
		{
			label: "Comodo para escritório",
			header: "Escritório",
			title: details?.commercials?.office_room,
			icon: details?.commercials?.office_room !== null ? Svg.living : "",
			exist:
				details?.commercials?.office_room !== 0 &&
				details?.commercials !== null,
		},
		{
			label: "Comodo para refeitório",
			header: "Refeitório",
			title: details?.commercials?.dining_room,
			icon: details?.commercials?.dining_room !== null ? Svg.LivingRoom : "",
			exist:
				details?.commercials?.dining_room !== 0 &&
				details?.commercials !== null,
		},
		{
			label: "Vestiário",
			header: "Vestiário",
			title: details?.commercials?.clothing ? "Sim" : "Não",
			icon: parseInt(details?.commercials?.clothing)
				? Svg.LivingRoom
				: Svg.LivingRoom,
			exist: false,
			isTechnical: details?.commercials?.clothing !== null,
		},
		{
			label: "Mobiliado",
			header: "Mobiliado",
			title: details?.commercials?.furnished ? "Sim" : "Não",
			icon: details?.commercials?.furnished ? Svg.LivingRoom : Svg.LivingRoom,
			exist: false,
			isTechnical: details?.commercials?.furnished !== null,
		},
	];

	const coworkingsInfo = [
		{
			label: "Construtora",
			header: "Construtora",
			title: details?.construction_company_name,
			icon: details?.construction_company_name !== 0 ? Svg.LivingRoom : "",
			exist: details?.construction_company_name != null,
		},
		{
			label: "Vaga(s)",
			header: "Vaga(s)",
			title: details?.coworkings?.garage_amount,
			icon: details?.coworkings?.garage_amount !== 0 ? Svg.garage : "",
			exist:
				details?.coworkings?.garage_amount !== 0 &&
				details?.coworkings !== null,
		},
		{
			label: "Cômodo(s)",
			header: "Cômodo(s)",
			title: details?.coworkings?.comfortable,
			icon:
				details?.coworkings?.comfortable !== 0
					? Svg.LivingRoom
					: Svg.LivingRoom,
			exist:
				details?.coworkings?.comfortable !== null &&
				details?.coworkings !== null,
		},
		{
			label: "Comodo para escritório",
			header: "Escritório",
			title: details?.coworkings?.office_room || "",
			icon:
				details?.coworkings?.office_room !== null
					? Svg.LivingRoom
					: Svg.LivingRoom,
			exist:
				details?.coworkings?.office_room !== null &&
				details?.coworkings !== null,
		},
		{
			label: "Comodo para refeitório",
			header: "Refeitório",
			title: details?.coworkings?.dining_room,
			icon: Svg.LivingRoom,
			exist:
				details?.coworkings?.dining_room !== null &&
				details?.coworkings !== null,
		},
		{
			label: "Vestiário",
			header: "Vestiário",
			title: details?.coworkings?.clothing ? "Sim" : "Não",
			icon:
				parseInt(details?.coworkings?.clothing) !== 0
					? Svg.LivingRoom
					: Svg.LivingRoom,
			exist: false,
			isTechnical: details?.coworkings?.clothing !== 0,
		},
		{
			label: "Mobiliado",
			header: "Mobiliado",
			title: details?.coworkings?.furnished ? "Sim" : "Não",
			icon:
				parseInt(details?.coworkings?.furnished) !== 0
					? Svg.LivingRoom
					: Svg.LivingRoom,
			exist: false,
			isTechnical: details?.coworkings?.furnished !== null,
		},
		{
			label: "Área Total",
			header: "Área Total",
			title: `${
				details?.coworkings?.total_area !== null
					? details?.coworkings?.total_area + " m²"
					: ""
			}`,
			icon:
				parseInt(details?.coworkings?.total_area) !== 0 || 0
					? Svg.Architecture
					: "",
			exist:
				details?.coworkings?.total_area !== null &&
				details?.coworkings?.total_area,
		},
		{
			label: "Área Construída",
			header: "Área Construída",
			title: `${
				details?.coworkings?.building_area !== null
					? details?.coworkings?.building_area + " m²"
					: ""
			}`,
			icon:
				parseInt(details?.coworkings?.building_area) !== 0 || 0
					? Svg.Architecture
					: "",
			exist: details?.coworkings?.building_area,
		},
		{
			label: "Pé direito",
			header: "Pé direito",
			title:
				details?.coworkings?.right_foot !== null
					? details?.coworkings?.right_foot + " m²"
					: "",
			icon: details?.coworkings?.right_foot !== null ? Svg.Architecture : "",
			exist:
				parseInt(details?.coworkings?.right_foot) ||
				("" && details?.coworkings !== null),
		},
	];

	const coworkingNewSection = [
		{
			label: "Wi-Fi",
			icon: Svg.wifi,
			exist: details?.coworkings?.wifi,
		},
		{
			label: "Recepção",
			icon: Svg.living,
			exist: details?.coworkings?.dinning,
		},
		{
			label: "Copa",
			icon: Svg.living,
			exist: details?.coworkings?.kitchen,
		},
		{
			label: "Estacionamento",
			icon: Svg.car,
			exist: details?.coworkings?.car,
		},
		{
			label: "Impressão",
			icon: Svg.print,
			exist: details?.coworkings?.print,
		},
		{
			label: "Caixa Postal",
			icon: Svg.mail,
			exist: details?.coworkings?.mail,
		},
		{
			label: "Segurança",
			icon: Svg.lock,
			exist: details?.coworkings?.lock,
		},
	];

	const medicalClinicsInfo = [
		{
			label: "Construtora",
			header: "Construtora",
			title: details?.construction_company_name,
			icon: details?.construction_company_name !== 0 ? Svg.LivingRoom : "",
			exist: details?.construction_company_name != null,
		},
		{
			label: "Vaga(s)",
			header: "Vaga(s)",
			title: details?.medical_clinic?.garage_amount,
			icon: details?.medical_clinic?.garage_amount !== 0 ? Svg.garage : "",
			exist:
				details?.medical_clinic?.garage_amount !== 0 &&
				details?.medical_clinic !== null,
		},
		{
			label: "Cômodo(s)",
			header: "Cômodo(s)",
			title: details?.medical_clinic?.comfortable,
			icon: details?.medical_clinic?.comfortable !== 0 ? Svg.LivingRoom : "",
			exist:
				details?.medical_clinic?.comfortable !== null &&
				details?.medical_clinic !== null,
		},
		{
			label: "Área Total",
			header: "Total",
			title: `${
				details?.medical_clinic?.total_area !== null
					? details?.medical_clinic?.total_area + " m²"
					: ""
			}`,
			icon:
				parseInt(details?.medical_clinic?.total_area) !== 0
					? Svg.Architecture
					: "",
			exist:
				details?.medical_clinic?.building_area !== null &&
				details?.medical_clinic?.building_area,
		},
		{
			label: "Área Construída",
			header: "Construída",
			title: `${
				details?.medical_clinic?.building_area !== null
					? details?.medical_clinic?.building_area + " m²"
					: ""
			}`,
			icon:
				parseInt(details?.medical_clinic?.building_area) !== 0
					? Svg.Architecture
					: "",
			exist:
				details?.medical_clinic?.building_area !== null &&
				details?.medical_clinic?.building_area,
		},
		{
			label: "Mobiliado",
			header: "Mobiliado",
			title: details?.medical_clinic?.furnished ? "Sim" : "Não",
			icon: details?.medical_clinic?.furnished !== 0 ? Svg.LivingRoom : "",
			exist: false,
			isTechnical: details?.medical_clinic?.furnished !== null,
		},
		{
			label: "Pé direito",
			header: "Pé direito",
			title:
				details?.medical_clinic?.right_foot !== null
					? details?.medical_clinic?.right_foot + " m²"
					: "",
			icon:
				details?.medical_clinic?.right_foot !== null ? Svg.Architecture : "",
			exist:
				parseInt(details?.medical_clinic?.right_foot) ||
				("" && details?.medical_clinic !== null),
		},
		{
			label: "",
			header: "",
			title:
				details?.medical_clinic?.disponibility !== null
					? details?.medical_clinic?.disponibility
					: "",
			icon:
				details?.medical_clinic?.disponibility !== null ? Svg.LivingRoom : "",
			exist:
				details?.medical_clinic?.disponibility ||
				("" && details?.medical_clinic !== null),
		},
	];

	const numberFormat = (value) => {
		const formated = new Intl.NumberFormat("pt-BR", {
			style: "currency",
			currency: "BRL",
		}).format(value);
		return formated;
	};

	function getPageData(type, query) {
		return apiService.get(`api/${type}/${query}`);
	}

	function getConstructionDetails(id) {
		return apiService.get(`api/website/constructions/${id}`);
	}

	function renderRecreationAreas() {
		const areas = JSON.parse(JSON.parse(details?.common_areas));

		return (
			<>
				<S.UnorderedItemInfo className="pb-3">
					<S.LabelInfo>Áreas Comum/Lazer</S.LabelInfo>
					{areas.map((area, index) => (
						<div key={index}>
							<S.TextInfo>
								{area.label}
								{areas.length !== index + 1 ? "," : "."}
							</S.TextInfo>
						</div>
					))}
				</S.UnorderedItemInfo>
			</>
		);
	}

	function renderConveniences() {
		const conveniencesCondominium = JSON.parse(
			JSON.parse(details?.convenience)
		);

		return (
			<>
				{conveniencesCondominium.map((convenience, index) => (
					<Col
						key={index}
						xl={4}
						lg={4}
						md={6}
						sm={12}
						className="comodities-flex"
					>
						<div className="media mb-3 comodities-flex">
							<img
								src={Svg[convenience.icon] || ComodityIcon}
								className="mr-5 mb-2"
								alt={`icon${index}`}
								width={35}
							/>
							<div className="media-body">
								<S.MediaTitle className="mt-0" size={20}>
									{convenience.label}
								</S.MediaTitle>
							</div>
						</div>
					</Col>
				))}
			</>
		);
	}

	function renderNearbies() {
		const nearby = details?.nearby
			? details?.nearby
			: details?.coworkings && details?.coworkings?.nearby
			? details?.coworkings.nearby
			: details?.commercials && details?.commercials?.nearby
			? details?.commercials?.nearby
			: null;

		if (nearby) {
			let value =
				nearby.supermarket +
				nearby.drug_store +
				nearby.restaurant +
				nearby.hotel +
				nearby.bus_stop +
				nearby.academy +
				nearby.school +
				nearby.nursery +
				nearby.hospital +
				nearby.beach +
				nearby.airport +
				nearby.sports_academy +
				nearby.pub +
				nearby.bazaar +
				nearby.nightclub +
				nearby.office_building +
				nearby.market +
				nearby.municipal_market +
				nearby.bakeries;
			if (value > 0) {
				return (
					<>
						<S.TitleSection size={30} className="mb-4">
							Proximidades
						</S.TitleSection>
						<Row>
							{nearby.supermarket_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.supermarket_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.drug_store_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.drug_store_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.hotel_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.hotel_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.bakeries_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.bakeries_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.bus_stop_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.bus_stop_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.academy_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.academy_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.school_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.school_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.nursery_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.nursery_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.hospital_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.hospital_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.beach_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.beach_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.airport_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.airport_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.sports_academy_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.sports_academy_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.pub_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.pub_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.bazaar_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.bazaar_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.nightclub_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.nightclub_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.market_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.market_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.municipal_market_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.municipal_market_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.restaurant_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.restaurant_description}
									</S.MediaTitle>
								</Col>
							) : null}
							{nearby.office_building_description ? (
								<Col xl={4} lg={4} md={6} sm={12}>
									<S.MediaTitle className="mb-3">
										{nearby.office_building_description}
									</S.MediaTitle>
								</Col>
							) : null}
						</Row>
					</>
				);
			}
		}
	}

	// function renderIncluded() {
	// 	const included = JSON.parse(JSON.parse(details?.included));

	// 	return (
	// 		<>
	// 			{included.map((included, index) => (
	// 				<Col key={index} lg={3} md={12} sm={12} className="comodities-flex">
	// 					<div className="media mb-3 comodities-flex">
	// 						<div className="media-body">
	// 							<S.MediaTitle className="mt-0" size={20}>
	// 								{included.label}
	// 							</S.MediaTitle>
	// 						</div>
	// 					</div>
	// 				</Col>
	// 			))}
	// 		</>
	// 	);
	// }

	function renderConstructionData() {
		if (details?.type === "flat" || details?.type === "houses") {
			return (
				<S.TechnicalInfo size={details?.plant_image ? "240px" : ""}>
					<S.TechnicalTitle className="mb-3">Ficha Técnica</S.TechnicalTitle>
					<S.UnorderedList>
						{details?.plant_image &&
							technicalInfo.map((item, index) =>
								item.exists || item.isTechnical ? (
									<S.UnorderedItemInfo key={index} className="pb-3">
										<S.LabelInfo>{item.label}</S.LabelInfo>
										<S.TextInfo>{item.title}</S.TextInfo>
									</S.UnorderedItemInfo>
								) : null
							)}
						{!details?.plant_image && (
							<Row>
								{technicalInfo.map((item, index) =>
									item.exists || item.isTechnical ? (
										<Col md={4} lg={2} xl={2} sm={2} key={index}>
											<S.UnorderedItemInfo key={index} className="pb-3">
												<S.LabelInfo>{item.label}</S.LabelInfo>
												<S.TextInfo>{item.title}</S.TextInfo>
											</S.UnorderedItemInfo>
										</Col>
									) : null
								)}
							</Row>
						)}

						{details?.common_areas &&
						details?.common_areas !== '"[]"' &&
						details?.common_areas !== "null"
							? renderRecreationAreas()
							: null}
					</S.UnorderedList>
				</S.TechnicalInfo>
			);
		}
		if (details?.type === "apartment") {
			return (
				<S.TechnicalInfo size={details?.plant_image ? "240px" : ""}>
					<S.TechnicalTitle className="my-sm-2 my-md-2 my-lg-0 my-2">
						Ficha Técnica
					</S.TechnicalTitle>
					<S.UnorderedList>
						{/* COM PLANTA */}

						{details?.plant_image &&
							technicalInfo.map((item, index) =>
								item.exists || item.isTechnical ? (
									<S.UnorderedItemInfo key={index} className="pb-3">
										<S.LabelInfo>{item.label}</S.LabelInfo>
										<S.TextInfo>{item.title}</S.TextInfo>
									</S.UnorderedItemInfo>
								) : null
							)}

						{!details?.plant_image && (
							<Row>
								{technicalInfo.map((item, index) =>
									item.exists || item.isTechnical ? (
										<Col md={4} lg={2} xl={2} sm={2}>
											<S.UnorderedItemInfo key={index} className="pb-3">
												<S.LabelInfo>{item.label}</S.LabelInfo>
												<S.TextInfo>{item.title}</S.TextInfo>
											</S.UnorderedItemInfo>
										</Col>
									) : null
								)}
							</Row>
						)}

						{details?.common_areas &&
						details?.common_areas !== '"[]"' &&
						details?.common_areas !== "null"
							? renderRecreationAreas()
							: null}
					</S.UnorderedList>
				</S.TechnicalInfo>
			);
		}
	}

	function renderConstructionIconsData() {
		if (details?.type === "houses") {
			return apartmentInfo.map((item, index) =>
				item.exist ? (
					<Col
						xl={3}
						lg={3}
						md={3}
						sm={6}
						xs={6}
						key={index}
						className="my-sm-2 my-md-2 my-lg-0 my-2"
					>
						<div className="d-flex flex-row my-4">
							<Image src={item.icon} height={36} />
							<small
								className="ml-2 d-flex flex-column justify-content-end align-items-end"
								style={{ color: "#7F7F7F", fontWeight: "600" }}
							>
								{item.title}{" "}
								{item.header !== "Área Construída" ? item.header : null}
							</small>
						</div>
					</Col>
				) : null
			);
		}

		if (details?.type === "flat") {
			return flatInfo.map((item, index) =>
				item.exist || item.isTechnical ? (
					<Col xl={3} lg={3} md={3} sm={6} xs={6} key={index}>
						<div className="d-flex flex-row">
							<Image src={item.icon} height={36} />
							<small
								className="ml-2 d-flex flex-column justify-content-end align-items-end"
								style={{ color: "#7F7F7F", fontWeight: "600" }}
							>
								{item.title}{" "}
								{item.header !== "Área Construída" ? item.header : null}
							</small>
						</div>
					</Col>
				) : null
			);
		}
	}

	function renderAluguelName() {
		return details?.business_events?.map((item, index) => (
			<p className="h3">{item?.event === "RENT" ? "Aluguel" : "Venda"}</p>
		));
	}

	function renderImmobileValues() {
		const isResidentails =
			details?.type === "flat" ||
			details?.type === "apartment" ||
			details?.type === "houses";

		const taxesValues = [
			{
				header: "Condomínio ",
				title: details?.condominiumFee,
				exist: true,
			},
			{
				header: "IPTU ",
				title: details?.iptu,
				exist: true,
			},
			{
				header: "Marinha ",
				title: details?.navyFee,
				exist: isResidentails,
			},
		];

		return (
			<>
				<Col
					xl={8}
					lg={8}
					md={12}
					sm={12}
					xs={12}
					className="my-sm-2 my-md-2 my-lg-0 my-2 d-flex justify-content-center flex-column"
				>
					{renderAluguelName()}
					<h1 className="display-4">
						{"R$ " + numberFormat(details?.price).split("R$")[1].trim()}
						<small className="text-muted">/mês</small>
					</h1>
				</Col>
				<Col
					xl={4}
					lg={4}
					md={12}
					sm={12}
					xs={12}
					className="my-sm-2 my-md-2 my-lg-0 my-2"
				>
					<div className="d-flex flex-row">
						<ul className="list-unstyled">
							{taxesValues.map((item, index) =>
								item.exist ? (
									<li key={index}>
										<p className="mb-2">
											{item?.header}
											<strong>
												<Currency quantity={item?.title} currency="BRL" />
											</strong>
										</p>
									</li>
								) : null
							)}
						</ul>
					</div>
				</Col>
			</>
		);
	}

	function renderCommercialsData() {
		if (
			details?.type === "hangar" ||
			details?.type === "stores" ||
			details?.type === "commecial"
		) {
			return (
				<S.TechnicalInfo size={details?.plant_image ? "300px" : ""}>
					<S.TechnicalTitle>Ficha Técnica</S.TechnicalTitle>
					<S.UnorderedList>
						{details?.plant_image &&
							storesInfo.map((item, index) =>
								item.exist || item.isTechnical ? (
									<S.UnorderedItemInfo key={index} className="pb-3">
										<S.LabelInfo>{item.label}</S.LabelInfo>
										<S.TextInfo>{item.title}</S.TextInfo>
									</S.UnorderedItemInfo>
								) : null
							)}

						{!details?.plant_image && (
							<Row>
								{storesInfo.map((item, index) =>
									item.exist || item.isTechincal ? (
										<Col md={4} lg={2} xl={2} sm={2} key={index}>
											<S.UnorderedItemInfo key={index} className="pb-3">
												<S.LabelInfo>{item.label}</S.LabelInfo>
												<S.TextInfo>{item.title}</S.TextInfo>
											</S.UnorderedItemInfo>
										</Col>
									) : null
								)}
							</Row>
						)}

						{details?.common_areas &&
						details?.common_areas !== '"[]"' &&
						details?.common_areas !== "null"
							? renderRecreationAreas()
							: null}
					</S.UnorderedList>
				</S.TechnicalInfo>
			);
		}
	}

	function renderCommercialIconsData() {
		if (
			details?.type === "hangar" ||
			details?.type === "stores" ||
			details?.type === "commercial"
		) {
			return storesInfo.map((item, index) =>
				index < 4 && item.exist && details?.commercials ? (
					<Col
						xl={3}
						lg={3}
						md={3}
						sm={6}
						xs={6}
						key={index}
						className="my-sm-2 my-md-2 my-lg-0 my-2"
					>
						<div className="d-flex flex-row">
							<Image src={item.icon} height={36} />
							<small
								className="ml-2 d-flex flex-column justify-content-end align-items-end"
								style={{ color: "#7F7F7F", fontWeight: "600" }}
							>
								{item.title}{" "}
								{item.header !== "Área Construída" ? item.header : null}
							</small>
						</div>
					</Col>
				) : null
			);
		}
	}

	function renderConstrutionsTraits() {
		if (
			!details?.apartments?.condominium?.construction?.convenience ||
			details?.apartments?.condominium?.construction?.convenience === '"[]"' ||
			details?.apartments?.condominium?.construction?.convenience === "null"
		) {
			return null;
		}
		const conveniencesCondominium = JSON.parse(
			JSON.parse(details?.apartments?.condominium?.construction?.convenience)
		);
		const conveniencesApartament = JSON.parse(JSON.parse(details?.convenience));

		const detailsInfraestrureDataColumns = detailsData.map((item, index) =>
			item.exist ? (
				<Col
					xl={3}
					lg={3}
					md={3}
					sm={6}
					xs={6}
					key={index}
					className="my-sm-2 my-md-2 my-lg-0 my-2"
				>
					<div className="d-flex flex-row my-4">
						<Image src={item.icon} height={36} />
						<small
							className="ml-2 d-flex flex-column justify-content-end align-items-end"
							style={{ color: "#7F7F7F", fontWeight: "600" }}
						>
							{item.title} {item.header}
						</small>
					</div>
				</Col>
			) : null
		);

		const convencionalDataColumns = conveniencesCondominium?.map(
			(item, index) => (
				<Col
					xl={3}
					lg={3}
					md={3}
					sm={6}
					xs={6}
					key={index}
					className="my-sm-2 my-md-2 my-lg-0 my-2"
				>
					<div className="d-flex flex-row my-4">
						<Image src={Svg[item.icon]} height={36} />
						<small
							className="ml-2 d-flex flex-column justify-content-end align-items-end"
							style={{ color: "#7F7F7F", fontWeight: "600" }}
						>
							{item.label}
						</small>
					</div>
				</Col>
			)
		);

		const convencionalApartamentDataColumns = conveniencesApartament?.map(
			(item, index) => (
				<Col
					xl={3}
					lg={3}
					md={3}
					sm={6}
					xs={6}
					key={index}
					className="my-sm-2 my-md-2 my-lg-0 my-2"
				>
					<div className="d-flex flex-row my-4">
						<Image src={Svg[item.icon]} height={36} />
						<small
							className="ml-2 d-flex flex-column justify-content-end align-items-end"
							style={{ color: "#7F7F7F", fontWeight: "600" }}
						>
							{item.label}
						</small>
					</div>
				</Col>
			)
		);

		return [
			detailsInfraestrureDataColumns,
			convencionalDataColumns,
			convencionalApartamentDataColumns,
		];
	}

	// function renderCommercialTraits() {
	// 	if (
	// 		!details?.convenience ||
	// 		details?.convenience === '"[]"' ||
	// 		details?.convenience === "null"
	// 	) {
	// 		return null;
	// 	}

	// 	const conveniencesApartament = JSON.parse(JSON.parse(details?.convenience));

	// 	const convencionalCommercialDataColumns = conveniencesApartament?.map(
	// 		(item, index) => (
	// 			<Col
	// 				xl={3}
	// 				lg={3}
	// 				md={3}
	// 				sm={6}
	// 				xs={6}
	// 				key={index}
	// 				className="my-sm-2 my-md-2 my-lg-0 my-2"
	// 			>
	// 				<div className="d-flex flex-row my-4">
	// 					<Image src={Svg[item.icon]} height={36} />
	// 					<small
	// 						className="ml-2 d-flex flex-column justify-content-end align-items-end"
	// 						style={{ color: "#7F7F7F", fontWeight: "600" }}
	// 					>
	// 						{item.label}
	// 					</small>
	// 				</div>
	// 			</Col>
	// 		)
	// 	);

	// 	return [convencionalCommercialDataColumns];
	// }

	function renderInfraConstruction() {
		if (
			!details?.apartments?.condominium?.construction?.convenience ||
			details?.apartments?.condominium?.construction?.convenience === '"[]"' ||
			details?.apartments?.condominium?.construction?.convenience === "null"
		) {
			return null;
		}

		const conveniencesCondominium = JSON.parse(
			JSON.parse(details?.apartments?.condominium?.construction?.convenience)
		);

		const detailsInfraestrureDataColumns = detailsInfraData.map((item, index) =>
			item.exist ? (
				<Col
					xl={3}
					lg={3}
					md={3}
					sm={6}
					xs={6}
					key={index}
					className="my-sm-2 my-md-2 my-lg-0 my-2"
				>
					<div className="d-flex flex-row my-4">
						<Image src={item.icon} height={36} />
						<small
							className="ml-2 d-flex flex-column justify-content-end align-items-end"
							style={{ color: "#7F7F7F", fontWeight: "600" }}
						>
							{item.title} {item.header}
						</small>
					</div>
				</Col>
			) : null
		);

		const convencionalDataColumns = conveniencesCondominium?.map(
			(item, index) => (
				<Col
					xl={3}
					lg={3}
					md={3}
					sm={6}
					xs={6}
					key={index}
					className="my-sm-2 my-md-2 my-lg-0 my-2"
				>
					<div className="d-flex flex-row my-4">
						<Image src={Svg[item.icon]} height={36} />
						<small
							className="ml-2 d-flex flex-column justify-content-end align-items-end"
							style={{ color: "#7F7F7F", fontWeight: "600" }}
						>
							{item.label}
						</small>
					</div>
				</Col>
			)
		);

		return [detailsInfraestrureDataColumns, convencionalDataColumns];
	}

	// function renderInfraCommercial() {
	// 	if (
	// 		!details?.convenience ||
	// 		details?.convenience === '"[]"' ||
	// 		details?.convenience === "null"
	// 	) {
	// 		return null;
	// 	}
	// 	return storesInfra.map((item, index) =>
	// 		item.exist && details?.commercials ? (
	// 			<Col
	// 				xl={3}
	// 				lg={3}
	// 				md={3}
	// 				sm={6}
	// 				xs={6}
	// 				key={index}
	// 				className="my-sm-2 my-md-2 my-lg-0 my-2"
	// 			>
	// 				<div className="d-flex flex-row">
	// 					<Image src={item.icon} height={36} />
	// 					<small
	// 						className="ml-2 d-flex flex-column justify-content-end align-items-end"
	// 						style={{ color: "#7F7F7F", fontWeight: "600" }}
	// 					>
	// 						{item.title} {item.header}
	// 					</small>
	// 				</div>
	// 			</Col>
	// 		) : null
	// 	);
	// }

	function renderCoworkingData() {
		if (
			details?.type === "medicCoworking" ||
			details?.type === "coworking" ||
			details?.type === "medicBusiness"
		) {
			return (
				<S.TechnicalInfo size={details?.plant_image ? "300px" : ""}>
					<S.TechnicalTitle>Ficha Técnica</S.TechnicalTitle>
					<S.UnorderedList>
						{details?.plant_image && details?.coworkings
							? coworkingsInfo.map((item, index) =>
									item.exist || item.isTechnical ? (
										<S.UnorderedItemInfo key={index} className="pb-3">
											<S.LabelInfo>{item.label}</S.LabelInfo>
											<S.TextInfo>{item.title}</S.TextInfo>
										</S.UnorderedItemInfo>
									) : null
							  )
							: null}

						{!details?.plant_image && (
							<Row>
								{coworkingsInfo.map((item, index) =>
									item.exist || item.isTechnical ? (
										<Col md={4} lg={2} xl={2} sm={2}>
											<S.UnorderedItemInfo key={index} className="pb-3">
												<S.LabelInfo>{item.label}</S.LabelInfo>
												<S.TextInfo>{item.title}</S.TextInfo>
											</S.UnorderedItemInfo>
										</Col>
									) : null
								)}
							</Row>
						)}

						{details?.common_areas &&
						details?.common_areas !== '"[]"' &&
						details?.common_areas !== "null"
							? renderRecreationAreas()
							: null}
					</S.UnorderedList>
				</S.TechnicalInfo>
			);
		}
	}

	function renderCoworkingIconsData() {
		if (
			details?.type === "medicCoworking" ||
			details?.type === "coworking" ||
			details?.type === "medicBusiness"
		) {
			return coworkingsInfo.map((item, index) =>
				item.exist && details?.coworkings ? (
					<Col
						xl={3}
						lg={3}
						md={3}
						sm={6}
						xs={6}
						key={index}
						className="my-sm-2 my-md-2 my-lg-0 my-3"
					>
						<div className="d-flex flex-row align-items-center my-4">
							<Image src={item.icon} height={36} />
							<small
								className="ml-2 d-flex flex-column justify-content-end align-items-end"
								style={{ color: "#7F7F7F", fontWeight: "600" }}
							>
								{item.title}{" "}
								{item.header !== "Área Construída" ? item.header : null}
							</small>
						</div>
					</Col>
				) : null
			);
		}
	}

	function renderCoworkingNewIconsData() {
		if (
			details?.type === "medicCoworking" ||
			details?.type === "coworking" ||
			details?.type === "medicBusiness"
		) {
			return coworkingNewSection.map((item, index) =>
				item.exist && details?.coworkings ? (
					<Col
						xl={3}
						lg={3}
						md={3}
						sm={6}
						xs={6}
						key={index}
						className="my-sm-2 my-md-2 my-lg-0 my-3"
					>
						<div className="d-flex flex-row align-items-center my-4">
							<Image src={item.icon} height={36} />
							<small
								className="ml-2 d-flex flex-column justify-content-end align-items-end"
								style={{ color: "#7F7F7F", fontWeight: "600" }}
							>
								{item.title} {item.label}
							</small>
						</div>
					</Col>
				) : null
			);
		}
	}

	function renderMedicalData() {
		if (details?.type === "medicalCenter") {
			return (
				<S.TechnicalInfo size={details?.plant_image ? "300px" : ""}>
					<S.TechnicalTitle>Ficha Técnica</S.TechnicalTitle>
					<S.UnorderedList>
						{details?.plant_image &&
							medicalClinicsInfo.map((item, index) =>
								item.exist || item.isTechnical ? (
									<S.UnorderedItemInfo key={index} className="pb-3">
										<S.LabelInfo>{item.label}</S.LabelInfo>
										<S.TextInfo>{item.title}</S.TextInfo>
									</S.UnorderedItemInfo>
								) : null
							)}

						{!details?.plant_image && (
							<Row>
								{medicalClinicsInfo.map((item, index) =>
									item.exist || item.isTechnical ? (
										<Col md={4} lg={2} xl={2} sm={2}>
											<S.UnorderedItemInfo key={index} className="pb-3">
												<S.LabelInfo>{item.label}</S.LabelInfo>
												<S.TextInfo>{item.title}</S.TextInfo>
											</S.UnorderedItemInfo>
										</Col>
									) : null
								)}
							</Row>
						)}

						{details?.common_areas &&
						details?.common_areas !== '"[]"' &&
						details?.common_areas !== "null"
							? renderRecreationAreas()
							: null}
					</S.UnorderedList>
				</S.TechnicalInfo>
			);
		}
	}

	function renderMedicalIconsData() {
		if (details?.type === "medicalCenter") {
			return medicalClinicsInfo.map(
				(item, index) =>
					index > 4 &&
					item.exist &&
					details?.medical_clinic && (
						<Col
							xl={3}
							lg={3}
							md={3}
							sm={6}
							xs={6}
							key={index}
							className="my-sm-2 my-md-2 my-lg-0 my-2 d-flex align-items-center"
						>
							<div className="d-flex flex-row">
								<Image src={item.icon} height={36} />
								<small
									className="ml-2 d-flex flex-column justify-content-end align-items-end"
									style={{ color: "#7F7F7F", fontWeight: "600" }}
								>
									{item.title}{" "}
									{item.header !== "Área Construída" ? item.header : null}
								</small>
							</div>
						</Col>
					)
			);
		}
	}

	function renderMetaTags() {
		return (
			<MetaTags
				description={details?.description}
				keywords={details?.keywords}
			/>
		);
	}

	function renderTopTree() {
		return details?.construction_top3_images.length > 0 ? (
			<section id="top-3" className="pt-4 pb-4">
				<div className="container pb-5">
					<div className="row justify-content-center">
						{details?.construction_top3_images.map((item, index) => (
							<div
								className="text-center my-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12 top-3"
								key={index}
							>
								<div
									className="mb-4 rounded-circle"
									style={{
										backgroundImage: `url(${
											item.image_uri.indexOf("http") > -1
												? item.image_uri
												: baseUrlOfGCS + item.image_uri
										})`,
										width: "239px",
										height: "239px",
										marginLeft: "3.5rem",
										backgroundPosition: "center",
										backgroundSize: "cover",
									}}
								></div>
								<h4
									style={{
										color: "#7F7F7F",
										fontWeight: "bold",
										textTransform: "uppercase",
										fontSize: "14px",
									}}
								>
									{item.title}
								</h4>
								<p
									style={{
										fontSize: "12px",
										color: "#7F7F7F",
										lineHeight: "1.4",
										marginBottom: "0",
									}}
								>
									{item.description}
								</p>
							</div>
						))}
					</div>
				</div>
			</section>
		) : null;
	}

	function renderTopFour() {
		if (
			details?.type === "medicCoworking" ||
			details?.type === "coworking" ||
			details?.type === "medicBusiness" ||
			details?.type === "medicalCenter"
		) {
			return details?.construction_top4_images.length > 0 ? (
				<section id="top-4" className="pt-4 pb-5 sec-top-4">
					<div className="container pb-5">
						<div className="row justify-content-center">
							<div className="wrapper-top-4__four top-4">
								{details?.construction_top4_images.map((item, index) => (
									<div
										className="top-4__bg_container p-4 d-flex align-items-center flex-column justify-content-between"
										key={index}
									>
										<div
											className=""
											style={{
												backgroundImage: `url(${
													item.image_uri.indexOf("http") > -1
														? item.image_uri
														: baseUrlOfGCS + item.image_uri
												})`,
												width: "200px",
												height: "200px",
												backgroundPosition: "center",
												borderRadius: "50%",
												marginBottom: "1.5rem",
											}}
										></div>
										<h4
											style={{
												color: "#fff",
												fontWeight: "bold",
												textTransform: "uppercase",
												fontSize: "14px",
											}}
										>
											{item.title}
										</h4>
										<p
											style={{
												fontSize: "12px",
												color: "#fff",
												lineHeight: "1.4",
												marginBottom: "0",
											}}
										>
											{item.description}
										</p>
									</div>
								))}
							</div>
						</div>
					</div>
				</section>
			) : null;
		}
	}

	function renderDetails() {
		if (details?.type === "apartment") {
			return renderConstrutionsTraits() ? (
				<section id="property-icons" className="pt-3 pb-3">
					<div className="container">
						<div className="row mb-3">
							<Col xl={9} lg={9} md={12} sm={12}>
								<S.TitleSection>Características</S.TitleSection>
							</Col>
							<Col xl={3} lg={3} md={12} sm={12}></Col>
						</div>
						<div className="row">
							<Col xl={9} lg={9} md={12} sm={12}>
								<div className="row">{renderConstrutionsTraits()}</div>
							</Col>
							<Col xl={3} lg={3} md={12} sm={12}></Col>
						</div>
					</div>
				</section>
			) : null;
		}

		if (details?.type === "flat" || details?.type === "houses") {
			return null;
		}
	}

	function renderInfrastructure() {
		if (details?.type === "apartment") {
			return renderInfraConstruction() ? (
				<section id="property-icons" className="pt-5 pb-4">
					<div className="container">
						<div className="row mb-3">
							<Col xl={9} lg={9} md={12} sm={12}>
								<S.TitleSection>Infraestrutura</S.TitleSection>
							</Col>
							<Col xl={3} lg={3} md={12} sm={12}></Col>
						</div>
						<div className="row">
							<Col xl={9} lg={9} md={12} sm={12}>
								<div className="row">{renderInfraConstruction()}</div>
							</Col>
							<Col xl={3} lg={3} md={12} sm={12}></Col>
						</div>
					</div>
				</section>
			) : null;
		}

		if (details?.type === "flat" || details?.type === "houses") {
			return null;
		}
	}

	function renderConventionalCostComparison() {
		const cost_comparison = JSON.parse(JSON.parse(details?.cost_comparison));

		const replaceCurrencyValue = (value) => {
			const element = parseFloat(value.replace(".", ""));
			return element;
		};

		const parseConventionalToFloat = cost_comparison?.map((item) => {
			const conventionalReplaced = replaceCurrencyValue(item?.conventional);
			return conventionalReplaced;
		});

		const numberFormat = (value) => {
			const formated = new Intl.NumberFormat("pt-BR", {
				style: "currency",
				currency: "BRL",
			}).format(value);
			return formated;
		};

		const reduceTotal = (value) => {
			if (value) {
				const reduced = value.reduce((a, b) => a + b, 0);
				return reduced;
			} else {
				return 0;
			}
		};

		const conventionalNumberFormat = () => {
			const conventionalTotal = reduceTotal(parseConventionalToFloat);
			return numberFormat(conventionalTotal);
		};

		const parseCoworkingToFloat = cost_comparison?.map((item) => {
			const coworkingReplaced = replaceCurrencyValue(item?.coworking);
			return coworkingReplaced;
		});

		const coworkingNumberFormat = () => {
			const coworkingTotal = reduceTotal(parseCoworkingToFloat);
			return numberFormat(coworkingTotal);
		};

		if (
			details?.type === "medicCoworking" ||
			details?.type === "coworking" ||
			details?.type === "medicBusiness"
		) {
			return (
				<section className="container my-5">
					<div className="row mb-5">
						<Col xl={9} lg={9} md={12} sm={12}>
							<S.TitleSection>Comparação de Custos</S.TitleSection>
						</Col>
						<Col xl={3} lg={3} md={12} sm={12}></Col>
					</div>

					<div>
						<Media
							queries={{
								small: "(max-width: 599px)",
								large: "(min-width: 600px)",
							}}
						>
							{(matches) => (
								<Fragment>
									{matches.small && (
										<section className="table-responsive">
											<table className="table">
												<thead>
													<tr>
														<th
															scope="col"
															className="border-transparent font-weight-normal arial-font-size font-size-element"
														></th>
														<th
															scope="col"
															className="border-danger border border-wd2 border-bottom-0"
														>
															<p className="text-muted text-center mb-0 h4 font_size_sample_calc font-weight-light">
																Escritório <br /> convencional
															</p>
														</th>
													</tr>
												</thead>
												<tbody>
													{cost_comparison?.map((item, index) => (
														<tr key={index}>
															<th
																scope="row"
																style={{ color: "#7F7F7F" }}
																className="border custom_border-secondary border-right-0 border-wd2 font-weight-light arial-font-size font-size-element"
															>
																{item?.description}
															</th>
															<td
																className="text-center border-danger border border-bottom-0 font-weight-bold border-wd2 arial-font-size new-border-color "
																style={{ color: "#D92828" }}
															>
																{numberFormat(
																	replaceCurrencyValue(item?.conventional)
																)}
															</td>
														</tr>
													))}
													<tr>
														<th
															scope="row"
															className="border-transparent font-weight-normal arial-font-size font-size-element"
														></th>
														<td
															scope="row"
															className="text-center border-danger border border-wd2"
															style={{ width: "12.5rem" }}
														>
															<h4
																className="font-weight-bolder"
																style={{ color: "#D92828" }}
															>
																Custo mensal
															</h4>
															<h2 className="font-weight-bolder text-danger">
																{"R$ " +
																	conventionalNumberFormat()
																		.split("R$")[1]
																		.trim()}
															</h2>
														</td>
													</tr>
												</tbody>
											</table>
											<table className="table my-3">
												<thead>
													<tr>
														<th scope="col" className="border-transparent"></th>
														<th
															scope="col"
															className="border-success border border-bottom-0 border-wd2 text-center"
															style={{ backgroundColor: "#EAF8E6" }}
														>
															<Image
																src={Svg.Elipse}
																height={60}
																width={60}
																className="my-2"
																style={{
																	backgroundColor: "#FFF",
																	borderRadius: "50px",
																}}
															/>
															<p
																className="text-center mb-0 h4 font-weight-bold"
																style={{ color: "#7F7F7F", fontSize: "2.1rem" }}
															>
																Coworking Legal
															</p>
														</th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<th scope="row" className="border-transparent"></th>
														<td
															scope="row"
															className="text-center border-success border border-wd2 pb-0 text-white border-bottom-0 border-top-0"
															style={{ backgroundColor: "#EAF8E6" }}
														>
															&nbsp;
														</td>
													</tr>
													{cost_comparison?.map((item, index) => (
														<tr>
															<th
																scope="row"
																style={{ color: "#7F7F7F" }}
																className="width-cost_comparison border custom_border-secondary border-right-0 border-wd2 font-weight-light arial-font-size font-size-element"
															>
																{item?.description}
															</th>
															<td
																className="width-cost_comparison text-center border-success border border-bottom-0 border-top-0 font-weight-bold border-wd2 arial-font-size new-border-color "
																style={{
																	color: "#35A40E",
																	backgroundColor: "#EAF8E6",
																}}
															>
																{"R$ " +
																	numberFormat(
																		replaceCurrencyValue(item?.coworking)
																	)
																		.split("R$")[1]
																		.trim()}
															</td>
														</tr>
													))}

													<tr>
														<th scope="row" className="border-transparent"></th>
														<td
															scope="row"
															className="text-center border-success border border-wd2 pb-0"
															style={{ backgroundColor: "#EAF8E6" }}
														>
															<h1
																className="font-weight-bolder text-success"
																style={{
																	backgroundColor: "#EAF8E6",
																	fontSize: "1.95rem",
																}}
															>
																{"R$ " +
																	coworkingNumberFormat().split("R$")[1].trim()}
																<small className="text-success">/mês</small>
															</h1>
														</td>
													</tr>
												</tbody>
											</table>
										</section>
									)}
									{matches.large && (
										<section
											className="table-responsive"
											style={{ marginLeft: "7.5rem" }}
										>
											<table className="table">
												<tr>
													<td
														className="border-transparent"
														style={{ width: "25%" }}
													>
														&nbsp;
													</td>
													<td className="border-transparent">&nbsp;</td>
													<td
														scope="col"
														className="border-success border border-bottom-0 border-wd2 middle-size-pad-0 text-center"
														rowspan="3"
														style={{ backgroundColor: "#EAF8E6", width: "25%" }}
													>
														<Image
															src={Svg.Elipse}
															height={46}
															className="my-2"
															style={{
																backgroundColor: "#FFF",
																borderRadius: "50px",
															}}
														/>
														<p
															className="text-center mb-0 h4 font_size_sample_calc font-weight-bold"
															style={{ color: "#7F7F7F" }}
														>
															Coworking Legal
														</p>
													</td>
												</tr>
												<tr>
													<td className="border-transparent">&nbsp;</td>
													<td
														className="border-danger border border-wd2 border-bottom-0"
														rowspan="2"
														style={{ width: "25%" }}
													>
														<p className="text-muted text-center mb-0 h4 font_size_sample_calc font-weight-light">
															Escritório <br /> convencional
														</p>
													</td>
												</tr>
												<tr></tr>
												{cost_comparison?.map((item, index) => (
													<tr key={index}>
														<td
															scope="row"
															style={{ color: "#7F7F7F" }}
															className="width-cost_comparison border custom_border-secondary border-right-0 border-wd2 font-weight-light arial-font-size font-size-element"
														>
															{item?.description}
														</td>
														<td
															className="width-cost_comparison text-center border-danger border border-bottom-0 border-right-0 new-border-color font-weight-bold border-wd2"
															style={{ color: "#D92828" }}
														>
															{"R$ " +
																numberFormat(
																	replaceCurrencyValue(item?.conventional)
																)
																	.split("R$")[1]
																	.trim()}
														</td>
														<td
															className="text-center border-success border border-bottom-0 font-weight-bold border-wd2 new-border-color"
															style={{
																color: "#35A40E",
																backgroundColor: "#EAF8E6",
															}}
														>
															{"R$ " +
																numberFormat(
																	replaceCurrencyValue(item?.coworking)
																)
																	.split("R$")[1]
																	.trim()}
														</td>
													</tr>
												))}
												<tr>
													<td
														scope="row"
														className="border-transparent font-weight-normal arial-font-size font-size-element"
													></td>
													<td
														scope="row"
														className="text-center border-danger border border-wd2 border-right-0"
														rowSpan="2"
													>
														<h4
															className="font-weight-bolder"
															style={{ color: "#D92828" }}
														>
															Custo mensal
														</h4>
														<h2 className="font-weight-bolder text-danger">
															{"R$ " +
																conventionalNumberFormat()
																	.split("R$")[1]
																	.trim()}
														</h2>
													</td>
													<td
														scope="row"
														className="text-center border-success border border-wd2 pb-0 middle-size-pad-0"
														style={{ backgroundColor: "#EAF8E6" }}
														colSpan="2"
														rowSpan="4"
													>
														<h2 className="font-weight-bolder text-success">
															{"R$ " +
																coworkingNumberFormat().split("R$")[1].trim()}
															<small className="text-success">/mês</small>
														</h2>
													</td>
												</tr>
												<tr>
													<td className="border-transparent">&nbsp;</td>
													<td className="border-transparent">&nbsp;</td>
												</tr>
												<tr>
													<td className="border-transparent">&nbsp;</td>
													<td className="border-transparent">&nbsp;</td>
												</tr>
											</table>
										</section>
									)}
								</Fragment>
							)}
						</Media>
					</div>
				</section>
			);
		}
	}

	return (
		<S.Container>
			{renderMetaTags()}
			<Header />
			{contentLoading ? null : (
				<>
					<section
						id="property-banner"
						style={{
							width: "100%",
							height: "615px",
							backgroundImage: `url(${getHightLightImage()})`,
							backgroundPosition: "center",
							backgroundSize: "cover",
							backgroundRepeat: "no-repeat",

							filter: `${ details.is_available == false  ?  'grayscale(100)' : 'grayscale(0)'}`,
						}}
						
						className="d-flex flex-column justify-content-end justify-content-lg-around justify-content-sm-end justify-content-md-end"
					>
						{console.log(details)}
						<div></div>
						<div style={{ background: "rgba(0,0,0,0.7)" }}>
							<div className="container" style={{ position: "relative" }}>
								<div className="d-flex flex-row justify-content-between pt-2 pb-2">
									<div>
										<h1 className="text-white">{details?.name}</h1>
										<div className="d-flex justify-content-between">
											<small className="text-white">
												{details?.address.street} - {details?.address.city} {details.is_available == false ? ' - Alugado' : ''}
											</small>
											{details?.reference ? (
												<small className="text-white">
													{details?.reference}
												</small>
											) : null}
										</div>
									</div>
									{details?.spotlight ? (
										<div>
											<Media
												query="(min-width: 1200px)"
												render={() => (
													<div
														className="text-white p-4"
														style={{
															minWidth: "240px",
															maxWidth: "240px",
															position: "absolute",
															backgroundColor: "#CCB28B",
															right: "0",
															top: "-65px",
															zIndex: "10",
														}}
													>
														<h1 className="mb-5" style={{ fontSize: "30px" }}>
															Destaques
															<br />
															do imóvel
														</h1>
														{ReactHtmlParser(details?.spotlight)}
													</div>
												)}
											/>
										</div>
									) : null}
								</div>
							</div>
						</div>
					</section>
					<section id="property-icons" className="pt-5 pb-4">
						<div className="container">
							<div className="row">
								<Col xl={9} lg={9} md={12} sm={12}>
									<div className="row">
										{renderConstructionIconsData()}
										{renderCommercialIconsData()}
										{renderCoworkingIconsData()}
										{renderCoworkingNewIconsData()}
										{renderMedicalIconsData()}
									</div>
								</Col>
								<Col xl={3} lg={3} md={12} sm={12}></Col>
							</div>
						</div>
					</section>
					{renderDetails()}
					{renderInfrastructure()}
					<section id="property-icons" className="pt-5 pb-4">
						<div className="container">
							<div className="row my-3">
								<Col xl={9} lg={9} md={12} sm={12}>
									<S.TitleSection>Valores</S.TitleSection>
								</Col>
								<Col xl={3} lg={3} md={12} sm={12}></Col>
							</div>
							<div className="row">
								<Col xl={9} lg={9} md={12} sm={12}>
									<div className="row label-ammount">
										{renderImmobileValues()}
										{details?.rules ? (
											<div className="my-2 alert alert-primary w-100">
												<p className="lead">
													{ReactHtmlParser(details?.rules)}
												</p>
											</div>
										) : null}
									</div>
								</Col>
								<Col xl={3} lg={3} md={12} sm={12}></Col>
							</div>
						</div>
					</section>
					<section id="property-description" className="pt-4 pb-4">
						<div className="container">
							<div className="row">
								<Col xl={9} lg={9} md={12} sm={12}>
									<div className="row">
										<Col
											md={12}
											style={{ color: "#7F7F7F", lineHeight: "1.8" }}
										>
											{ReactHtmlParser(details?.description)}
										</Col>
									</div>
								</Col>
								<Col xl={3} lg={3} md={12} sm={12}></Col>
							</div>
						</div>
					</section>
					{renderTopTree()}
					{/* TOP4 HERE */}
					{renderTopFour()}
					{renderConventionalCostComparison()}
					<section>
						{details?.spotlight ? (
							<div>
								<Media
									query="(max-width: 1200px)"
									render={() => (
										<div
											className="text-white p-4 text-featured"
											style={{
												backgroundColor: "#CCB28B",
												textAlign: "center",
												marginTop: "-50px",
											}}
										>
											<h1
												className=""
												style={{ fontSize: "30px", marginBottom: "2rem" }}
											>
												Destaques do imóvel
											</h1>
											{ReactHtmlParser(details?.spotlight)}
										</div>
									)}
								/>
							</div>
						) : null}
					</section>
					<section id="property-location" style={{ height: "400px" }}>
						<GoogleMapReact
							bootstrapURLKeys={{ key: constantUtil.keys.googleMaps }}
							center={{
								lat: -34.397, lng: 150.644
							}}
							defaultZoom={18}
							yesIWantToUseGoogleMapApiInternals
							key={constantUtil.keys.googleMaps}
							options={{
								streetViewControl: true,
								disableDefaultUI: false,
								mapTypeControl: true,
								styles: [
									{
										featureType: "poi",
										elementType: "labels",
										stylers: [{ visibility: "on" }],
									},
								],
							}}
						>
							<Pin lat={59.95} lng={30.337844} />
						</GoogleMapReact>
					</section>
					{details?.images && details?.images.length > 0 ? (
						<section id="property-first-images" className="pt-5 pb-5">
							<div className="container pt-5 pb-5">
								<Row>
									<Col sm={12} xm={12} md={12} lg={12} xl={12}>
										<div className="wrapper">
											{details?.images.map((image, index) =>
												index < 4 ? (
													<div className="gallery-container w-3" key={index}>
														<div className="gallery-item">
															<div className="image">
																{image.type === "normal" ? (
																	<img
																		src={
																			image.image_uri.indexOf("http") > -1
																				? image.image_uri
																				: baseUrlOfGCS + image.image_uri
																		}
																		alt={`Galeria ${index}`}
																	/>
																) : (
																	<div className="panorama">
																		<iframe
																			title="Iframe principal"
																			allowFullScreen={true}
																			scrolling="no"
																			frameBorder="0"
																			width="100%"
																			height="358px"
																			src={`//storage.googleapis.com/vrview/2.0/index.html?image=${
																				image.image_uri.indexOf("http") > -1
																					? image.image_uri
																					: baseUrlOfGCS + image.image_uri
																			}&is_stereo=true`}
																		/>
																	</div>
																)}
															</div>
														</div>
													</div>
												) : null
											)}
										</div>
									</Col>
								</Row>
							</div>
						</section>
					) : null}
					<section
						id="property-primary-cta"
						className={`${
							details?.images && details?.images.length > 0 ? "" : "pt-5 mt-5"
						}`}
					>
						<S.DivBackground>
							<S.SvgBackground>
								<div className="container">
									<div className="row pt-4 pb-4">
										<Col xs={12} sm={12} md={12} lg={7} xl={7}>
											<S.FormTitle
												className="mb-4"
												style={{ letterSpacing: "2px", fontWeight: "400" }}
											>
												Saiba mais chamando a<br />
												gente e agende uma visita.
											</S.FormTitle>
											<form onSubmit={handleSubmit(onSubmit)}>
												<S.StyledFormControl className="mb-3" size="lg">
													<FormControl
														className={`${errors.name ? "input-error" : ""}`}
														ref={register({
															required: true,
															min: 3,
															max: 60,
															pattern:
																/^[A-Z][a-zA-Z]{2,}(?: [A-Z][a-zA-Z]*){0,6}$/i,
														})}
														aria-label="Default"
														aria-describedby="inputGroup-sizing-default"
														placeholder="Nome"
														name="name"
														min={3}
														max={60}
														value={name}
														onChange={(e) => setNameValue(e.target.value)}
													/>
												</S.StyledFormControl>
												{errors.name && (
													<S.ErrorParagraph>
														Esse campo é obrigatório
													</S.ErrorParagraph>
												)}
												<S.StyledFormControl className="mb-3" size="lg">
													<FormControl
														className={`${errors.email ? "input-error" : ""}`}
														ref={register({
															required: true,
															min: 3,
															max: 40,
															pattern:
																/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i,
														})}
														aria-label="Default"
														aria-describedby="inputGroup-sizing-default"
														placeholder="E-mail"
														name="email"
														min={3}
														max={40}
														value={email}
														onChange={(e) => setEmailValue(e.target.value)}
													/>
												</S.StyledFormControl>
												{errors.email && (
													<S.ErrorParagraph>
														Esse campo é obrigatório
													</S.ErrorParagraph>
												)}

												<S.StyledFormControl
													className="mb-3"
													size="lg"
													ref={register}
												>
													<InputMask
														mask="(99) 9 9999-9999"
														className={`${
															errors.phone
																? "form-control form-control-lg input-contact-building-form-white input-error"
																: "form-control form-control-lg input-contact-building-form-white"
														}`}
														alwaysShowMask={false}
														type="tel"
														name="phone"
														guide={false}
														placeholder="Telefone"
														value={phone}
														min={11}
														max={16}
														ref={register({
															min: 11,
															max: 16,
															pattern: /^\(\d{2,}\) \d{1} \d{4}-\d{4}$/i,
														})}
														onChange={(e) => setPhoneValue(e.target.value)}
													/>
												</S.StyledFormControl>
												{errors.phone && (
													<S.ErrorParagraph>
														Esse campo deve ter no mínimo 11 caracteres.
													</S.ErrorParagraph>
												)}
												<div className="pt-3 text-sm-center text-center text-md-center text-lg-left">
													<S.CustomButton type="submit">Enviar</S.CustomButton>
												</div>
											</form>
										</Col>
										<Col
											xs={12}
											sm={12}
											md={12}
											lg={5}
											xl={5}
											className="d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center mt-5 mt-sm-5 mt-md-5 mt-lg-0 mt-xl-0"
										>
											<S.GreenBackground className="pt-3 pb-3 pr-4 pl-4">
												<S.ParagraphProduct
													color="#393939"
													align="center"
													weight="bold"
												>
													Você também pode falar conosco direto pelo WhatsApp!
												</S.ParagraphProduct>
												<S.WhiteBackground className="pb-1">
													<Image
														src="https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fwa.me%2F5581993234017&chs=300x300&choe=UTF-8&chld=L|2"
														width="100%"
														height="auto"
													/>
													<p
														style={{
															color: "#7F7F7F",
															lineHeight: "18px",
															fontSize: "12px",
															fontWeight: "600",
														}}
														className="ml-2 mr-2"
													>
														Abra ou instale um leitor de Código QR e aponte para
														o desenho acima
													</p>
												</S.WhiteBackground>
												<div className="d-flex justify-content-center mt-3">
													<S.CustomButton
														color="#34882B"
														hover="#43af37"
														active="#34882B"
														onClick={() =>
															window.open("https://wa.me/5581993234017")
														}
													>
														Ou clique aqui
													</S.CustomButton>
												</div>
											</S.GreenBackground>
										</Col>
									</div>
								</div>
							</S.SvgBackground>
						</S.DivBackground>
					</section>
					<section id="property-plans" className="mt-5 mb-5">
						<div className="container pt-5 pb-5">
							<div className="row">
								{details?.plant_image && (
									<Col sm={12} xs={12} md={12} lg={8} xl={8}>
										<S.TitleSection>Plantas</S.TitleSection>
										{details?.plant_image && (
											<Image
												alt="Planta"
												src={
													details?.plant_image.indexOf("http") > -1
														? details?.plant_image
														: baseUrlOfGCS + details?.plant_image
												}
												className="py-3 w-100"
												fluid
											/>
										)}
									</Col>
								)}
								<Col
									sm={12}
									xs={12}
									md={12}
									lg={details?.plant_image ? 4 : 12}
									xl={details?.plant_image ? 4 : 12}
									className="d-flex justify-content-end"
								>
									{renderConstructionData()}
									{renderCommercialsData()}
									{renderCoworkingData()}
									{renderMedicalData()}
								</Col>
							</div>
						</div>
					</section>
					{details?.convenience &&
					details?.convenience !== '"[]"' &&
					details?.convenience !== "null" ? (
						<section id="property-comodities" className="mt-5 mb-5">
							<div className="container">
								{details?.type === "medicCoworking" ||
								details?.type === "coworking" ||
								details?.type === "medicBusiness" ? (
									<S.TitleSection size={30} className="my-4">
										Por que o nosso coworking?
									</S.TitleSection>
								) : (
									<S.TitleSection size={30} className="my-4">
										Comodidades do Imóvel
									</S.TitleSection>
								)}
								<div className="row">{renderConveniences()}</div>
							</div>
						</section>
					) : null}
					{details?.nearby ? (
						<section id="property-proximities" className="pb-5">
							<div className="container">{renderNearbies()}</div>
						</section>
					) : details?.coworkings && details?.coworkings?.nearby ? (
						<section id="property-proximities" className="pb-5">
							<div className="container">{renderNearbies()}</div>
						</section>
					) : details?.commercials && details?.commercials?.nearby ? (
						<section id="property-proximities" className="pb-5">
							<div className="container">{renderNearbies()}</div>
						</section>
					) : null}
					{details?.images && details?.images.length > 0 ? (
						<section id="property-all-images" className="pb-5">
							<div className="container">
								<S.TitleSection size={30} className="mb-4">
									Todas as Imagens
								</S.TitleSection>
								<Row>
									<Col sm={12} xm={12} md={12} lg={12} xl={12}>
										<div className="wrapper wrapper-three-columns">
											{details?.images.map((image, index) => (
												<div
													className="gallery-container fix-grid-three-columns"
													key={index}
												>
													<div className="gallery-item">
														<div className="image">
															{image.type === "normal" ? (
																<img
																	src={
																		image.image_uri.indexOf("http") > -1
																			? image.image_uri
																			: baseUrlOfGCS + image.image_uri
																	}
																	alt={`Galeria ${index}`}
																/>
															) : (
																<div className="panorama">
																	<iframe
																		title="Iframe principal"
																		allowFullScreen={true}
																		scrolling="no"
																		frameBorder="0"
																		width="100%"
																		height="auto"
																		src={`//storage.googleapis.com/vrview/2.0/index.html?image=${
																			image.image_uri.indexOf("http") > -1
																				? image.image_uri
																				: baseUrlOfGCS + image.image_uri
																		}&is_stereo=true`}
																	/>
																</div>
															)}
														</div>
													</div>
												</div>
											))}
										</div>
									</Col>
								</Row>
							</div>
						</section>
					) : null}
					<section id="property-m-mestre" className="mt-5 mb-5">
						<div className="container pt-5 pb-5">
							<div className="row">
								<Col
									xs={12}
									sm={12}
									md={6}
									lg={6}
									xl={6}
									className="d-flex flex-column justify-content-between"
								>
									<Media
										query="(max-width: 599px)"
										render={() => (
											<Image
												src={require("../../assets/images/static/photo-one.png")}
												fluid
												style={{ objectFit: "cover" }}
												className="w-100 my-4"
												alt="photo-one"
											/>
										)}
									/>
									<h1 className="mb-4" style={{ color: "#7F7F7F" }}>
										Por que confiar na
										<br />
										M. Mestre?
									</h1>
									<p className="mb-5" style={{ color: "#7F7F7F" }}>
										Lorem ipsum dolor sit amet, consectetur adipisicing elit,
										sed do eiusmod tempor incididunt ut ero labore et dolore
										magna aliqua. Ut enim ad minim veniam, quis nostrud
										exercitation ullamco poriti laboris nisi ut aliquip ex ea
										commodo consequat. Duis aute irure dolor in reprehenderit in
										uienply voluptate velit esse cillum dolore eu fugiat nulla
										pariatur. Excepteur sint occaecat cupidatat norin proident,
										sunt in culpa qui officia deserunt mollit anim id est
										laborum.
									</p>
									<Media
										query="(min-width: 600px)"
										render={() => (
											<Image
												src={require("../../assets/images/static/photo-one.png")}
												fluid
												style={{ objectFit: "cover" }}
												className="w-100"
												alt="photo-one"
											/>
										)}
									/>
								</Col>
								<Col xs={12} sm={12} md={6} lg={6} xl={6}>
									<Image
										src={require("../../assets/images/static/photo-one.png")}
										fluid
										style={{ objectFit: "cover" }}
										className="w-100 section_about__image"
										alt="photo-two"
									/>
									<div className="property-image-overlay-container">
										<Image
											src={require("../../assets/images/static/photo-one.png")}
											fluid
											style={{ objectFit: "cover" }}
											className="w-100"
											alt="photo-three"
										/>
										<div className="property-overlay p-5">
											<h3
												style={{ fontWeight: "bold", fontSize: "14px" }}
												className="mb-4"
											>
												Name Surname
											</h3>
											<p style={{ fontSize: "14px", lineHeight: "1.8" }}>
												A MMestre é a melhor do mundo! Amo de paixão, se pudesse
												me casaria com ela e lorem ipsum.
											</p>
										</div>
									</div>
								</Col>
							</div>
						</div>
					</section>
					<section id="property-secondary-cta">
						<S.DivBackground>
							<S.SvgBackground>
								<div className="container">
									<div className="row pt-4 pb-4">
										<Col xs={12} sm={12} md={12} lg={7} xl={7}>
											<S.FormTitle
												className="mb-4"
												style={{ letterSpacing: "2px", fontWeight: "400" }}
											>
												Saiba mais chamando a<br />
												gente e agende uma visita.
											</S.FormTitle>
											<form onSubmit={handleSubmit(onSubmit)}>
												<S.StyledFormControl className="mb-3" size="lg">
													<FormControl
														className={`${errors.name ? "input-error" : ""}`}
														ref={register({
															required: true,
															min: 3,
															max: 60,
															pattern:
																/^[A-Z][a-zA-Z]{2,}(?: [A-Z][a-zA-Z]*){0,6}$/i,
														})}
														aria-label="Default"
														aria-describedby="inputGroup-sizing-default"
														placeholder="Nome"
														name="name"
														min={3}
														max={60}
														value={name}
														onChange={(e) => setNameValue(e.target.value)}
													/>
												</S.StyledFormControl>
												{errors.name && (
													<S.ErrorParagraph>
														Esse campo é obrigatório
													</S.ErrorParagraph>
												)}
												<S.StyledFormControl className="mb-3" size="lg">
													<FormControl
														className={`${errors.email ? "input-error" : ""}`}
														ref={register({
															required: true,
															min: 3,
															max: 40,
															pattern:
																/^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i,
														})}
														aria-label="Default"
														aria-describedby="inputGroup-sizing-default"
														placeholder="E-mail"
														name="email"
														min={3}
														max={40}
														value={email}
														onChange={(e) => setEmailValue(e.target.value)}
													/>
												</S.StyledFormControl>
												{errors.email && (
													<S.ErrorParagraph>
														Esse campo é obrigatório
													</S.ErrorParagraph>
												)}

												<S.StyledFormControl
													className="mb-3"
													size="lg"
													ref={register}
												>
													<InputMask
														mask="(99) 9 9999-9999"
														className={`${
															errors.phone
																? "form-control form-control-lg input-contact-building-form-white input-error"
																: "form-control form-control-lg input-contact-building-form-white"
														}`}
														alwaysShowMask={false}
														type="tel"
														name="phone"
														guide={false}
														placeholder="Telefone"
														value={phone}
														min={11}
														max={16}
														ref={register({
															min: 11,
															max: 16,
															pattern: /^\(\d{2,}\) \d{1} \d{4}-\d{4}$/i,
														})}
														onChange={(e) => setPhoneValue(e.target.value)}
													/>
												</S.StyledFormControl>
												{errors.phone && (
													<S.ErrorParagraph>
														Esse campo deve ter no mínimo 11 caracteres.
													</S.ErrorParagraph>
												)}
												<div className="pt-3 text-sm-center text-center text-md-center text-lg-left">
													<S.CustomButton type="submit">Enviar</S.CustomButton>
												</div>
											</form>
										</Col>
										<Col
											xs={12}
											sm={12}
											md={12}
											lg={5}
											xl={5}
											className="d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center mt-5 mt-sm-5 mt-md-5 mt-lg-0 mt-xl-0"
										>
											<S.GreenBackground className="pt-3 pb-3 pr-4 pl-4">
												<S.ParagraphProduct
													color="#393939"
													align="center"
													weight="bold"
												>
													Você também pode falar conosco direto pelo WhatsApp!
												</S.ParagraphProduct>
												<S.WhiteBackground className="pb-1">
													<Image
														src="https://chart.googleapis.com/chart?cht=qr&chl=https%3A%2F%2Fwa.me%2F5581993234017&chs=300x300&choe=UTF-8&chld=L|2"
														width="100%"
														height="auto"
													/>
													<p
														style={{
															color: "#7F7F7F",
															lineHeight: "18px",
															fontSize: "12px",
															fontWeight: "600",
														}}
														className="ml-2 mr-2"
													>
														Abra ou instale um leitor de Código QR e aponte para
														o desenho acima
													</p>
												</S.WhiteBackground>
												<div className="d-flex justify-content-center mt-3">
													<S.CustomButton
														color="#34882B"
														hover="#43af37"
														active="#34882B"
														onClick={() =>
															window.open("https://wa.me/5581993234017")
														}
													>
														Ou clique aqui
													</S.CustomButton>
												</div>
											</S.GreenBackground>
										</Col>
									</div>
								</div>
							</S.SvgBackground>
						</S.DivBackground>
					</section>
				</>
			)}
			<Footer />
		</S.Container>
	);
}

export default PropertiDetails;
