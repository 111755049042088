import React from 'react';

import MetaTags from 'react-meta-tags';

export default function Seo({ description, keywords }) {
	return (
		<MetaTags>
			<meta name="description" content={description} />
			<meta name="keywords" content={keywords}></meta>
		</MetaTags>
	);
}
