import { combineReducers } from 'redux';

import variables from './variables';
import immobiles from './immobiles';
import page from './page';
import states from './states';
import cities from './cities';

export default combineReducers({
	variables,
	immobiles,
	page,
	states,
	cities,
});
