import React, { useState, useEffect, useCallback } from "react";

import { Row, Col } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import ContentLoader from "react-content-loader";

import BusinessWoman from "../../../assets/images/mulherempresaria.png";
import PhoneCall from "../../../assets/icons/phone-call.png";
import Speak from "../../../assets/icons/speak.png";
import Svg from "../../../utils/svg";
import getFieldValue from "../../../helpers/fields";
import { getVariableById } from "../../../helpers/variable";
import constants from "../../../utils/constants";

import * as S from "./styles";

export default function ConsultantContact() {
	const page = useSelector((state) => state.page.home.informations);
	const { variables } = useSelector((state) => state.variables);

	const [contactDescription, setContactDescription] = useState("");
	const [contactTitle, setContactTitle] = useState("");
	const [attendingTitle, setAttendingTitle] = useState("");
	const [attendingNumber, setAttendingNumber] = useState("");
	const [whatsAppTitle, setWhatsAppTitle] = useState("");
	const [whatsAppNumber, setWhatsAppNumber] = useState("");
	const [attendantOnlineTitle, setAttendantOnlineTitle] = useState("");
	const [loading, setLoading] = useState(false);

	const setInitialLoading = useCallback((bool) => {
		setLoading(bool);
	}, []);

	const onlineChatCallback = useCallback(() => {
		const smallChatIframeSelector = document.querySelector("#Smallchat iframe");
		const IframeContent =
			smallChatIframeSelector.contentWindow ||
			smallChatIframeSelector.contentDocument;
		const smallChatButtonSelector = IframeContent.document.querySelector(
			"#Smallchat .Launcher-button .Launcher-button"
		);

		if (smallChatButtonSelector) {
			smallChatButtonSelector.click();
		}
	}, []);

	useEffect(() => {
		try {
			setInitialLoading(true);
			setAttendantOnlineTitle(getVariableById(variables, 17));
			setWhatsAppTitle(getVariableById(variables, 16));
			setWhatsAppNumber(getVariableById(variables, 11));
			setAttendingTitle(getVariableById(variables, 18));
			setAttendingNumber(getVariableById(variables, 10));
		} catch (error) {
		} finally {
			setTimeout(() => setInitialLoading(false), 1500);
		}
	}, [variables, setInitialLoading]);

	useEffect(() => {
		try {
			setInitialLoading(true);
			setContactDescription(getFieldValue(page.fields, 8).content);
			setContactTitle(getFieldValue(page.fields, 7).content);
		} catch (error) {
		} finally {
			setInitialLoading(false);
		}
	}, [page, setInitialLoading]);

	return (
		<div className="adjust-consultant-section">
			<div className="container-fluid h-100">
				<Row className="px-xl-5 px-lg-0 ml-lg-5 mr-lg-5 py-5 py-sm-5 py-md-5 py-lg-5 py-xl-0 h-100 text-center text-sm-center text-md-center text-lg-left text-xl-left">
					<Col
						className="h-100 d-none d-sm-none d-md-none d-lg-none d-xl-flex flex-column justify-content-end"
						xl={3}
						lg={3}
						md={12}
						sm={12}
					>
						<img className="img-fluid" src={BusinessWoman} alt="mulher" />
					</Col>
					<Col
						className="py-xl-5 d-flex flex-column justify-content-between text-white"
						xl={5}
						lg={6}
						md={12}
						sm={12}
					>
						<h1 className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 mb-xl-0">
							{loading ? (
								<ContentLoader
									speed={4}
									width={400}
									height={200}
									viewBox="0 0 400 200"
									backgroundColor="#f3f3f3"
									foregroundColor="#ecebeb"
								>
									<rect x="10" y="1" rx="3" ry="3" width="400" height="6" />
									<rect x="10" y="33" rx="3" ry="3" width="200" height="6" />
									<rect x="10" y="64" rx="3" ry="3" width="200" height="2" />
								</ContentLoader>
							) : (
								ReactHtmlParser(contactTitle)
							)}
						</h1>
						<p className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 mb-xl-0 adjust-subtitle-consultant-section">
							{loading ? (
								<ContentLoader
									speed={4}
									width={400}
									height={200}
									viewBox="0 0 400 200"
									backgroundColor="#f3f3f3"
									foregroundColor="#ecebeb"
								>
									<rect x="6" y="14" rx="3" ry="3" width="410" height="6" />
									<rect x="6" y="30" rx="3" ry="3" width="380" height="6" />
									<rect x="6" y="46" rx="3" ry="3" width="300" height="6" />
									<rect x="6" y="66" rx="3" ry="3" width="200" height="6" />
								</ContentLoader>
							) : (
								ReactHtmlParser(contactDescription)
							)}
						</p>
					</Col>
					<Col
						className="py-xl-5 px-md-3 px-lg-0 px-xl-2 text-white d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-between w-100"
						xl={4}
						lg={6}
						md={12}
						sm={12}
					>
						<div className="mb-5 mb-sm-5 mb-md-4 mb-lg-0 mb-xl-0 text-center">
							<a
								className="d-flex flex-column justify-content-start align-items-center text-white"
								href={constants.urls.whatsAppApiLink}
								target="_blank"
								rel="noopener noreferrer"
							>
								<img
									className="adjust-icons"
									src={Svg.whatsapp}
									alt="whatsapp"
								/>
								<S.Paragraph className="mb-0 mt-4 text-uppercase">
									{ReactHtmlParser(whatsAppTitle)}
								</S.Paragraph>
							</a>
						</div>
						<div className="mb-5 mb-sm-5 mb-md-4 mb-lg-0 mb-xl-0 text-center">
							<a
								className="d-flex flex-column justify-content-start align-items-center text-white"
								onClick={(e) => {
									e.preventDefault();
									onlineChatCallback();
								}}
								href="#"
							>
								<img className="adjust-icons" src={Speak} alt="atendimento" />
								<S.Paragraph className="mb-0 mt-4 text-uppercase">
									{ReactHtmlParser(attendantOnlineTitle)}
								</S.Paragraph>
							</a>
						</div>
						<div className="mb-0 mb-sm-0 mb-md-4 mb-lg-0 mb-xl-0 text-center">
							<a
								className="d-flex flex-column justify-content-start align-items-center text-white"
								href={`tel:${attendingNumber}`}
							>
								<img className="adjust-icons" src={PhoneCall} alt="telefone" />
								<S.Paragraph className="mb-0 mt-4 text-uppercase">
									{ReactHtmlParser(attendingTitle)}
								</S.Paragraph>
							</a>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	);
}
