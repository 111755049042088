import React from 'react';

import { ButtonSubmit } from './styles';

import ReactLoading from 'react-loading';

export default function Button(props) {
	return (
		<>
			<ButtonSubmit
				size={props.size}
				height={props.height}
				marging={props.marging}
				padding={props.padding}
				bgColor={props.bgColor}
				bgActive={props.bgActive}
				bgHover={props.bgHover}
				radius={props.radius}
				fontSize={props.fontSize}
				onClick={props.onClick}
				loading={props.loading}
				style={props.style}
			>
				{props.loading && (
					<ReactLoading type={'spin'} color="#fff" height={20} width={20} />
				)}

				{!props.loading && (
					<>
						{props.children}
						{props.txt}
					</>
				)}
			</ButtonSubmit>
		</>
	);
}
