import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure({
	autoClose: 3000,
	draggable: true,
});

function showToast(type, message) {
	if (typeof message === 'string') {
		toast(message, {
			type,
		});
	} else {
		const keys = Object.keys(message);

		for (const key of keys) {
			toast(message[key][0], {
				type,
			});
		}
	}
}

export default {
	showToast,
};
