/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from 'react-content-loader';
import ReactHtmlParser from 'react-html-parser';
import ScrollToTopButton from '../../components/atoms/ScrollToTopButton/';
import Header from '../../components/template/Header';
import Immobile from '../../components/molecules/ImmobileCard';
import Search from '../../components/organisms/HomeSearchBar';
import Map from '../../components/molecules/Map';
import ConsultantContact from '../../components/organisms/ConsultantContact';
import CoworkingDescription from '../../components/organisms/CoworkingDescription';
import Footer from '../../components/template/FooterHome';
import { Container } from './styles';
import api from '../../services/api';
import { SET_VARIABLES } from '../../store/actions/variables';
import { SET_HOME_PAGE_INFORMATIONS } from '../../store/actions/page';
import { SET_HIGHTLIGHT } from '../../store/actions/hightlight';
import MetaTags from '../../components/atoms/Seo';
import { SET_STATES } from '../../store/actions/states';
import { Button, Col, Row } from 'react-bootstrap';
import ContactForm from '../../components/organisms/ContactForm';
import { Link, useParams } from 'react-router-dom';
import { constantUtil } from '../../utils';
import { baseUrlOfGCS } from '../../constants/urls';
import CurvedBackground from '../../assets/images/curved-city-architecture.jpg';
import { FaChevronRight } from 'react-icons/fa';
import { getVariableById } from '../../helpers/variable';
import format from '../../helpers/format';
import Svg from '../../utils/svg';
import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

export default function Home(props) {
	const dispatch = useDispatch();
	const page = useSelector(state => state.page.home.informations);
	const { variables } = useSelector(state => state.variables);
	const [attendantOnlineTitle, setAttendantOnlineTitle] = useState('');
	const [bannerAttendingNumber, setBannerAttendingNumber] = useState('');
	const [hightlight, setHighlight] = useState([]);
	const [loading, setLoading] = useState(false);
	const trackingId = constantUtil.keys.googleAnalytics;
	const [scrollVisible, setScrollVisible] = useState(false);
	const { section } = useParams();

	useEffect(() => {
		setBannerAttendingNumber(getVariableById(variables, 10));
		setAttendantOnlineTitle(getVariableById(variables, 17));
	}, [variables]);

	useEffect(() => {
		if (section === 'help') {
			window.scrollTo({
				top: 60,
				behavior: 'smooth',
			});
		}
	}, []);

	useEffect(() => {
		window.gtag('config', trackingId, { page_path: props.location.pathname });
		try {
			setLoading(true);
			getVariables().then(response => {
				if (response.status === 200) {
					dispatch({
						type: SET_VARIABLES,
						payload: response.data,
					});
				}
			});

			getStates().then(response => {
				if (response.status === 200) {
					dispatch({
						type: SET_STATES,
						payload: response.data,
					});
				}
			});

			getPageData().then(response => {
				if (response.status === 200) {
					dispatch({
						type: SET_HOME_PAGE_INFORMATIONS,
						payload: response.data.page,
					});
				}
			});

			getHighlightImages().then(response => {
				if (response.status === 200) {
					dispatch({
						type: SET_HIGHTLIGHT,
						payload: response.data,
					});
					setHighlight(response.data);
				}
			});
		} catch (error) {
		} finally {
			setTimeout(() => setLoading(false), 1500);
		}
	}, []);

	function getPageData() {
		return api.get(`api/manager/pagina-inicial`);
	}

	function getVariables() {
		return api.get('api/manager/variables');
	}

	function getStates() {
		return api.get('api/states');
	}

	function getHighlightImages() {
		return api.get('api/manager/destaquesimoveis');
	}

	function renderMetaTags() {
		return <MetaTags description={page.description} keywords={page.keywords} />;
	}

	function renderCards(type) {
		if (page[type]) {
			return page[type].map((item, index) => (
				<Col
					key={index}
					xl={4}
					lg={6}
					md={6}
					sm={12}
					className="d-flex justify-content-center align-items-center pb-5 pb-sm-5 pb-md-5"
				>
					<Immobile key={item.id} item={item} />
				</Col>
			));
		}
	}

	function getBannerImage(banner) {
		if (banner) {
			const img = banner.type === 'normal' ? banner.image_uri : '';
			const degrees = banner.type === '360' ? banner.image_uri : '';

			if (img) {
				return img.indexOf('http') > -1 ? img : baseUrlOfGCS + img;
			} else if (degrees) {
				return degrees.indexOf('http') > -1 ? degrees : baseUrlOfGCS + degrees;
			}
		} else {
			return CurvedBackground;
		}
	}

	function getBannerStatus(banner) {
		if (banner) {
			switch (banner.status) {
				case 'launch':
					return 'Lançamento';
				case 'building':
					return 'Em<br>construção';
				case 'ready_to_live':
					return 'Pronto<br>para morar';
				default:
					return '';
			}
		}
	}

	function getBannerAddress(banner) {
		return `${banner.address.city} (${banner.address.state.uf})<br><b>${banner.address.neighborhood}</b>`;
	}

	function getBannerURL(banner) {
		let url = '';

		if (
			banner.type === 'flat' ||
			banner.type === 'house' ||
			banner.type === 'apartment'
		) {
			url = url + '/residenciais';
		} else if (
			banner.type === 'medicBusiness' ||
			banner.type === 'medicCoworking'
		) {
			url = url + '/coworkings';
		} else if (banner.type === 'medicalCenter') {
			url = url + '/clinicas-medicas';
		} else {
			url = url + '/comerciais';
		}

		url = url + `/${banner.id}/detalhes`;

		return url;
	}

	function toggleChatOnline() {
		const chat = document.querySelector('#Smallchat iframe');
		const iframe = chat.contentWindow || chat.contentDocument;
		const button = iframe.document.querySelector(
			'#Smallchat .Launcher-button .Launcher-button',
		);

		if (button) {
			button.click();
		}
	}

	return (
		<Container>
			{renderMetaTags()}
			<Header />
			{!loading ? (
				<>
					<Carousel
						infinite
						slidesPerPage={1}
						className="slide-wrapper d-flex d-sm-flex d-md-flex d-lg-none d-xl-none"
					>
						{hightlight?.length > 0
							? hightlight[1].map((high, index) => (
									<div className="fix-slide-item-mobile" key={index}>
										<Link
											to={getBannerURL(high.banners)}
											className="slide-item"
											style={{
												background: `url(${getBannerImage(high.image)})`,
											}}
											key={index}
										>
											<div className="slide-content">
												<h2>
													{ReactHtmlParser(getBannerStatus(high.banners))}
												</h2>
												<div>
													<h1>{high.banners.name}</h1>
													<p>
														{ReactHtmlParser(getBannerAddress(high.banners))}
													</p>
												</div>
											</div>
											{/* TODO Nested <a> tags inside Link is invalid for HTML structure */}
											<div className="banner-contacts">
												<div>
													<a href={`tel:${bannerAttendingNumber}`}>
														<img alt="telefone" src={Svg.phone} />
														{format.phone(bannerAttendingNumber)}
													</a>
												</div>
												<div>
													<a
														onClick={e => {
															e.preventDefault();
															toggleChatOnline();
														}}
														href="#"
													>
														<img alt="conversa" src={Svg.speak} />
														<span>{ReactHtmlParser(attendantOnlineTitle)}</span>
													</a>
												</div>
											</div>
											<span className="go-to-item">
												<FaChevronRight color="#fff" />
											</span>
										</Link>
									</div>
							  ))
							: null}
					</Carousel>

					<section className="slide-wrapper d-none d-sm-none d-md-none d-lg-flex d-xl-flex">
						{hightlight?.length > 0
							? hightlight[1].map((high, index) => (
									<Link
										to={getBannerURL(high.banners)}
										className="slide-item"
										style={{ background: `url(${getBannerImage(high.image)})` }}
										key={index}
									>
										<div className="slide-content">
											<h2>{ReactHtmlParser(getBannerStatus(high.banners))}</h2>
											<div>
												<h1>{high.banners.name}</h1>
												<p>{ReactHtmlParser(getBannerAddress(high.banners))}</p>
											</div>
											<div className="banner-contacts">
											<a href={`tel:${bannerAttendingNumber}`}>
												<img alt="telefone" src={Svg.phone} />
												{format.phone(bannerAttendingNumber)}
											</a>
											<a
												onClick={e => {
													e.preventDefault();
													toggleChatOnline();
												}}
												href="#"
											>
												<img alt="conversa" src={Svg.speak} />
												<span>{ReactHtmlParser(attendantOnlineTitle)}</span>
											</a>
										</div>
										</div>
										{/* <div className="banner-contacts">
											<a href={`tel:${bannerAttendingNumber}`}>
												<img alt="telefone" src={Svg.phone} />
												{format.phone(bannerAttendingNumber)}
											</a>
											<a
												onClick={e => {
													e.preventDefault();
													toggleChatOnline();
												}}
												href="#"
											>
												<img alt="conversa" src={Svg.speak} />
												<span>{ReactHtmlParser(attendantOnlineTitle)}</span>
											</a>
										</div> */}
										<span className="go-to-item">
											<FaChevronRight color="#fff" />
										</span>
									</Link>
							  ))
							: null}
					</section>
				</>
			) : (
				<ContentLoader
					speed={2}
					title="Carregando..."
					width={100}
					height={100}
					style={{ width: '100%', height: '100vh' }}
					viewBox="0 0 10000 10000"
					backgroundColor="#f3f3f3"
					foregroundColor="#ecebeb"
				>
					<rect x="0" y="0" rx="0" ry="0" width="10000" height="10000" />
				</ContentLoader>
			)}
			<Search />
			<Map />
			<ConsultantContact />
			<section className={loading ? '' : 'adjust-buildings-section'}>
				<div className="container py-5 py-sm-5 py-md-5 py-lg-5 py-xl-5 mt-lg-5 mb-lg-5">
					<Row>
						<Col
							className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 mb-xl-0 d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row justify-content-between align-items-center"
							lg={12}
							md={12}
							sm={12}
						>
							<h1 className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 mb-xl-0 text-center text-sm-center text-md-center text-lg-left text-xl-left">
								Apartamentos
							</h1>
							<Link to="/residenciais?construction_type=apartment">
								<Button
									className="adjust-see-all-button text-uppercase"
									variant="outline-warning"
									size="lg"
								>
									Ver todos
								</Button>
							</Link>
						</Col>
					</Row>
					{loading ? (
						<ContentLoader
							speed={2}
							width="100%"
							height={720}
							viewBox="0 0 1280 720"
							backgroundColor="#f3f3f3"
							foregroundColor="#ecebeb"
						>
							<rect x="11" y="3" rx="0" ry="0" width="400" height="400" />
							<rect x="458" y="3" rx="0" ry="0" width="400" height="400" />
							<rect x="900" y="4" rx="0" ry="0" width="400" height="400" />
						</ContentLoader>
					) : (
						<Row className="pt-lg-5 mt-lg-5">{renderCards('apartments')}</Row>
					)}
				</div>
			</section>
			<section className={loading ? '' : 'adjust-buildings-section'}>
				<div className="container py-5 py-sm-5 py-md-5 py-lg-5 py-xl-5 mt-lg-5 mb-lg-5">
					<Row>
						<Col
							className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 mb-xl-0 d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row justify-content-between align-items-center"
							lg={12}
							md={12}
							sm={12}
						>
							<h1 className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 mb-xl-0 text-center text-sm-center text-md-center text-lg-left text-xl-left">
								Casas
							</h1>
							<Link to="/residenciais?construction_type=house">
								<Button
									className="adjust-see-all-button text-uppercase"
									variant="outline-warning"
									size="lg"
								>
									Ver todos
								</Button>
							</Link>
						</Col>
					</Row>
					{loading ? (
						<ContentLoader
							speed={2}
							width="100%"
							height={720}
							viewBox="0 0 1280 720"
							backgroundColor="#f3f3f3"
							foregroundColor="#ecebeb"
						>
							<rect x="11" y="3" rx="0" ry="0" width="400" height="400" />
							<rect x="458" y="3" rx="0" ry="0" width="400" height="400" />
							<rect x="900" y="4" rx="0" ry="0" width="400" height="400" />
						</ContentLoader>
					) : (
						<Row className="pt-lg-5 mt-lg-5">{renderCards('houses')}</Row>
					)}
				</div>
			</section>
			<section className={loading ? '' : 'adjust-buildings-section'}>
				<div className="container py-5 py-sm-5 py-md-5 py-lg-5 py-xl-5 mt-lg-5 mb-lg-5">
					<Row>
						<Col
							className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 mb-xl-0 d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row justify-content-between align-items-center"
							lg={12}
							md={12}
							sm={12}
						>
							<h1 className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 mb-xl-0 text-center text-sm-center text-md-center text-lg-left text-xl-left">
								Galpões
							</h1>
							<Link to="/comerciais?construction_type=hangar">
								<Button
									className="adjust-see-all-button text-uppercase"
									variant="outline-warning"
									size="lg"
								>
									Ver todos
								</Button>
							</Link>
						</Col>
					</Row>
					{loading ? (
						<ContentLoader
							speed={2}
							width="100%"
							height={720}
							viewBox="0 0 1280 720"
							backgroundColor="#f3f3f3"
							foregroundColor="#ecebeb"
						>
							<rect x="11" y="3" rx="0" ry="0" width="400" height="400" />
							<rect x="458" y="3" rx="0" ry="0" width="400" height="400" />
							<rect x="900" y="4" rx="0" ry="0" width="400" height="400" />
						</ContentLoader>
					) : (
						<Row className="pt-lg-5 mt-lg-5">{renderCards('hangars')}</Row>
					)}
				</div>
			</section>
			<section className={loading ? '' : 'adjust-buildings-section'}>
				<div className="container py-5 py-sm-5 py-md-5 py-lg-5 py-xl-5">
					<Row>
						<Col
							className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 mb-xl-0 d-flex flex-column flex-sm-column flex-md-column flex-lg-row flex-xl-row justify-content-between align-items-center"
							lg={12}
							md={12}
							sm={12}
						>
							<h1 className="mb-5 mb-sm-5 mb-md-5 mb-lg-0 mb-xl-0 text-center text-sm-center text-md-center text-lg-left text-xl-left">
								Lojas
							</h1>
							<Link to="/comerciais?construction_type=stores">
								<Button
									className="adjust-see-all-button text-uppercase"
									variant="outline-warning"
									size="lg"
								>
									Ver todos
								</Button>
							</Link>
						</Col>
					</Row>
					{loading ? (
						<ContentLoader
							speed={2}
							width="100%"
							height={720}
							viewBox="0 0 1280 720"
							backgroundColor="#f3f3f3"
							foregroundColor="#ecebeb"
						>
							<rect x="11" y="3" rx="0" ry="0" width="400" height="400" />
							<rect x="458" y="3" rx="0" ry="0" width="400" height="400" />
							<rect x="900" y="4" rx="0" ry="0" width="400" height="400" />
						</ContentLoader>
					) : (
						<Row className="pt-lg-5 mt-lg-5">{renderCards('stores')}</Row>
					)}
				</div>
			</section>
			<section>
				<div className="container-fluid h-100">
					<Row className="px-xl-5 mx-xl-5 h-100">
						<Col lg={12} sm={12} md={12}>
							<CoworkingDescription />
						</Col>
					</Row>
				</div>
			</section>
			<ContactForm />
			<Footer />
		</Container>
	);
}
