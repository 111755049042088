import React, { Fragment } from 'react';
import { Image } from 'react-bootstrap';
import Media from 'react-media';
import { IconWrapperColor } from '../../template/FooterHome/styles';
import constants from '../../../utils/constants';
import Svg from '../../../utils/svg';

import { WhatsAppFloatButton } from './style';

export default function Button() {
	return (
		<div>
			<Media
				queries={{
					small: '(max-width: 599px)',
					medium: '(min-width: 600px) and (max-width: 1199px)',
					large: '(min-width: 1200px)',
				}}
			>
				{matches => (
					<Fragment>
						{matches.medium ||
							(matches.large && (
								<WhatsAppFloatButton>
									<a
										href={constants.urls.whatsAppApiLink}
										target="_blank"
										rel="noopener noreferrer"
									>
										<IconWrapperColor
											className="d-flex justify-content-center align-items-center"
											bg="#4FBF3E"
										>
											<Image
												style={{ width: 'auto', height: '36px' }}
												src={Svg.whatsapp}
												fluid
											/>
										</IconWrapperColor>
									</a>
								</WhatsAppFloatButton>
							))}
					</Fragment>
				)}
			</Media>
		</div>
	);
}
