const phone = number => {
	const phoneCleaned = String(number).replace(/\D/g, '');
	const match = /^(\d{2}|)?(\d{2})(\d{4,5})(\d{4})$/.exec(phoneCleaned);
	if (match) {
		const countryCode = match[1] ? '+55' : '';
		return [countryCode, ' (', match[2], ') ', match[3], '-', match[4]].join(
			'',
		);
	} else {
		return false;
	}
};

const paramsToObject = queryString => {
	const queryObject = new URLSearchParams(queryString);
	const queryEntries = queryObject.entries();

	let result = {};

	for (let entry of queryEntries) {
		const [key, value] = entry;
		result[key] = value;
	}

	return result;
};

export default {
	phone,
	paramsToObject,
};
